
<!-- Modal HTML -->
<section class="thankyou-section" style="margin-top: 6rem;">
    <div class="main-thank" style="width: 111%;">
        <i *ngIf="awesome" class="fa fa-check tick-thank" aria-hidden="true"></i>
        <i *ngIf="!awesome" class="far fa-times-circle tick-thank" aria-hidden="true"></i>
        <p>{{awesomeText}}!</p>
        <p class="thank-text">{{textData}}</p>
    </div>
</section>
