import { ApplicationService } from 'src/app/@backend/application.service';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { CartServiceService } from '../../cart-service.service';
import { isPlatformBrowser } from '@angular/common'
import { CanonicalTitleService } from 'src/app/@service/canonical-title.service';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  user ="5bfec47ef651033d1c99fbca"
  galleryArray: any={
    service:[],
    detailing:[],
    customization:[],
    dentpaint:[]
  };
  isLoader: boolean = true

  constructor(private _applicationservice: ApplicationService,   private meta: Meta,
    private title: Title,
    @Inject(PLATFORM_ID) private platformId,
    private cartService: CartServiceService , private SeoTitle:CanonicalTitleService) { 
    this.setGallery(this.user);
    this.title.setTitle("Our Gallery | CarEager - World-Class Luxury Car Services Provider")
    this.meta.updateTag(
      {name: 'Description', content: "CarEager is Well Known for luxury Car Services like BMW, Audi, Mercedes, Jaguar, car painting, repair, and ceramic coating at a low price. Call Now!"})
  } 

  ngOnInit() {

    
    this.SeoTitle.createLinkForCanonicalURL() 

    // window.scroll(0,0);
    let user = undefined
    if (isPlatformBrowser(this.platformId)) {
      user = JSON.parse(localStorage.getItem("user"))

    }


    if (user) {
      this.cartService.isCart = true
    }
  }

  
  ngAfterViewInit() {
    //this.isLoader = false
    setTimeout(() => {
      this.isLoader = false
    }, 500);
  }
  setGallery(user:any){
    var obj={
      user:this.user
    }
  
    this._applicationservice.setGallery(obj).subscribe((res:any)=>{
  
   
      this.galleryArray.detailing= res.responseData && res.responseData.service_Gallery ? res.responseData.service_Gallery.filter((_res:any)=>{
      return  _res.category =='detailing'
      }): [];
      this.galleryArray.customization=res.responseData && res.responseData.service_Gallery ? res.responseData.service_Gallery.filter((_res:any)=>{
        return  _res.category =='customization'
        }): [];
        this.galleryArray.collision=res.responseData && res.responseData.service_Gallery ? res.responseData.service_Gallery.filter((_res:any)=>{
          return  _res.category =="collision"
          }): [];

          // this.offerArray.services= res.responseData && res.responseData.offer ? res.responseData.offer.filter((_res:any)=>{
          //   return  _res.offer_details.category =="services"
          //   }) : [];
          this.galleryArray.services= res.responseData && res.responseData.service_Gallery ? res.responseData.service_Gallery.filter((_res:any)=>{
            return  _res.category =="services"
            }): [];;
          

      
    });
  }
}
