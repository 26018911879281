import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatFooterCell, MAT_DIALOG_DATA } from '@angular/material';
import { CanonicalTitleService } from 'src/app/@service/canonical-title.service';



@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  textData: any;
  awesome: any;
  awesomeText = ""
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,private SeoTitle:CanonicalTitleService
  ) { }

  ngOnInit() {
    this.SeoTitle.createLinkForCanonicalURL() 
    this.textData = this.data.data
    this.awesome = this.data.awesome
    if (this.data.awesome) {
      this.awesomeText = "AWESOME"
    } else {
      this.awesomeText = "Oops"
    }
  }

}
