import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { Toaster } from 'ngx-toast-notifications';
import { ApplicationService } from 'src/app/@backend/application.service';
import { CanonicalTitleService } from 'src/app/@service/canonical-title.service';
import { FooterAComponent } from 'src/app/@shared/footer-a/footer-a.component';
declare var hljs: any;

@Component({
  selector: 'app-request-call-back',
  templateUrl: './request-call-back.component.html',
  styleUrls: ['./request-call-back.component.css'],
})
export class RequestCallBackComponent implements OnInit, AfterViewInit {
  @Input() currentTitle: string;
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaResponse?: string;
  public captchaIsReady = false;

  public badge = 'inline';
  public type = 'image';
  public theme = 'light';
  // public readonly siteKey = "6LcvoUgUAAAAAJJbhcXvLn3KgG-pyULLusaU4mL1"
  public readonly siteKey = '6Ld-Y4EaAAAAAK_zu6GZSIaXQWQLLO5PAwrrRxtk';

  public recaptcha: any = null;
  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;
  isName: boolean = false
  isContact: boolean = false
  isSubmit: boolean = false;
  isLoader: boolean = true
  appointmentForm : FormGroup
  

  constructor(
    private cdr: ChangeDetectorRef,private SeoTitle:CanonicalTitleService,
    private formBuilder : FormBuilder,
    private router: Router,
    private toaster: Toaster,
    private _applicationservice: ApplicationService,
  ){}
  
  ngOnInit(): void {
    this.SeoTitle.createLinkForCanonicalURL() 
    
    this.appointmentForm = this.formBuilder.group({
      // recaptcha: ['', Validators.required],
      name : [''],
      contact_no : ['',],
      message : [''],
      company : ['CarEager Xpress Gurugram'],
      source : ['website'],
    })
  }


  ngAfterViewInit() {
    //this.isLoader = false
    this.captchaIsLoaded = true;
    this.highlight();
    this.cdr.detectChanges();
  }


  // Captcha methods

  execute(): void {
    this.captchaElem.execute();
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.cdr.detectChanges();
  }

  handleReady(): void {
    this.captchaIsReady = true;
    this.cdr.detectChanges();
  }

  changeBadge(badge: 'bottomright' | 'bottomleft' | 'inline' = 'bottomright'): void {
    this.badge = badge;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert(`There is no response from grecaptcha script - try using 'getCurrentResponse' method or subscribe to 'success' event`);
    } else {
      alert(response);
    }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      alert('There is no current response - have you submitted captcha?');
    } else {
      alert(currentResponse);
    }
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }

  showToast(type,message,alert) {
    // const type = "success";
     this.toaster.open({
       text: message,
       caption: alert,
       type: type,
       position:'top-right',
       duration:6000
     });
   }


  onSubmit = () => {   
    this.isContact = false
    this.isName = false
    if (!this.appointmentForm.value.name) {
      this.isName = true
    } else if (!this.appointmentForm.value.contact_no) {
      this.isContact = true
    }
    if (this.appointmentForm.valid) {
      // return false;
      this.isSubmit = true;
      this._applicationservice.generateLead(this.appointmentForm.value).subscribe(
        (_res) => {
       
          if(_res.responseCode == 200){
            this.showToast('sucess','Thanku for Book an Appointment','Sucess')
          //  alert("thanku for Book an Appointment")
            this.router.navigate(['/successful']);
          }
        },
        (err) => {
         
        }
      );
    }    
  };

}
