import { ApplicationService } from './../../@backend/application.service';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SharedService } from 'src/app/@service/shared.service';
import { CartServiceService } from 'src/app/cart-service.service';
import { isPlatformBrowser } from '@angular/common'
import { MatDialog } from '@angular/material';
import { BookAppointmentComponent } from 'src/app/@shared/book-appointment/book-appointment.component';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalTitleService } from 'src/app/@service/canonical-title.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {
  user ="5bfec47ef651033d1c99fbca"
  // offerArray: any=[];
  showPopup = true
  newOfferImage: Array<any> = [];
  data: any;
  offerArray: any={
    service:[],
    detailing:[],
    customization:[],
    dentpaint:[]
  };
  isLoader: boolean = true
  
  constructor(private _applicationservice: ApplicationService,public _shared:SharedService,
    @Inject(PLATFORM_ID) private platformId,
    private meta: Meta,
    private title: Title,
    private cartService: CartServiceService,
    private dialog : MatDialog,
    private SeoTitle:CanonicalTitleService
 ) { 
    this.setOffer(this.user);
    this.title.setTitle("CarEager Offers & Discounts - Get Best Discount on Car Services")
    this.meta.updateTag({name: 'Description', content: "Avail Best Offers & Discounts from CarEager on Car Services, Denting-Painting, Detailing, Customization, and much More Services. Book Now!"})
  }


  ngOnInit() {
    this.SeoTitle.createLinkForCanonicalURL() 
 //  window.scroll(0, 0)
    this._applicationservice.getoffersSlider()
    .subscribe(res => {
      if (res.responseCode == "200") {
        this.newOfferImage = res.responseData
      }
    })

    let user = undefined
    if (isPlatformBrowser(this.platformId)) {
      user = JSON.parse(localStorage.getItem("user"))
    }

    if (user) {
      this.cartService.isCart = true
    }

    if (isPlatformBrowser(this.platformId)) {
      this.data = JSON.parse(localStorage?.getItem('user'));
  
    }
     
      
      // if(this.data?.responseData.status == 'Active'){
      //   this.showPopup = false
      // }
      // if(this.showPopup){
      //   if (window.innerWidth < 600){
      //     setTimeout(() =>{
          
      //       this.dialog.open(BookAppointmentComponent, {
      //         width : '80%',
      //       })
      //     }, 5000)
      //   }else {
      //     setTimeout(() =>{
          
      //       this.dialog.open(BookAppointmentComponent, {
      //         width : '30%',
      //       })
      //     }, 5000)
      //   }
     
      // }


  }

  ngAfterViewInit() {
    //this.isLoader = false
    setTimeout(() => {
      this.isLoader = false
    }, 500);
  }
  offers: OwlOptions = {
    loop:true,
    margin:10,
    nav:true,
    navSpeed: 1300,
    autoplay:true,
    smartSpeed:1300,
    autoplaySpeed:1000,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    responsive:{
        0:{
            items:1
        },
        800:{
            items:1
        },
        1025:{
            items:1
        }
    }
  }


  setOffer(user:any){
    var obj={
      user:this.user
    }
   
    this._applicationservice.setGallery(obj).subscribe((res:any)=>{
 

    let gallery = res.responseData.offer_Gallery

    for (let i=0;i<gallery.length; i++) {
      let length = gallery[i].features_address.split("/").length
    
      if (gallery[i].features_address.split("/")[length-1] != "undefined") {
      
        //this.offerImage.push(gallery[i].features_address)

      }
     
    }

      

      this.offerArray.detailing=res.responseData && res.responseData.offer_Gallery ? res.responseData.offer_Gallery.filter((_res:any)=>{
      
        
      return  _res.category =='detailing'
      }):[];
      this.offerArray.customization= res.responseData && res.responseData.offer_Gallery ? res.responseData.offer_Gallery.filter((_res:any)=>{
        return  _res.category =='customization'
        }) : [];

        this.offerArray.dentpaint= res.responseData && res.responseData.offer_Gallery ? res.responseData.offer_Gallery.filter((_res:any)=>{
          return  _res.category =="collision"
          }) : [];

          this.offerArray.services= res.responseData && res.responseData.offer_Gallery ? res.responseData.offer_Gallery.filter((_res:any)=>{
            return  _res.category =="service"
            }) : [];
          

      
    });
  }
  goToDetail=(data)=>{
  
    this._shared.changeofferDetail(data);
  }
}
