<!-- Check price -->
<div id="check-price" class="modal fade price-modal" role="dialog">
  <div class="modal-dialog modal-custom">
    <div class="modal-content modal-color">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <img src="assets/images/Close.webp" alt="" />
        </button>
        <h4 class="modal-title">Check Price instantly</h4>
      </div>
      <div class="modal-body body-color">
        <p class="modtal-text">
          If anyone with similar equipment & expertise offer better prices in
          India, we'll do it for free.
        </p>
        <!-- id="data" #data="ngForm" (ngSubmit)="sendOtp(data); data.reset()" -->
        <form class="form-main" >
          <!--<div class="form-div">
            <input (keyup)="searchModel($event)" [(ngModel)]="car.name"  required name="car_name"  type="text" class="banner-input" id="car_name" placeholder="Search your Car"/>
            <div class="suggestions" *ngIf="carList.length">
              <p *ngFor="let car of carList" (click)="onselectCar(car)">{{car.variant}}</p>
            </div>
          </div> -->
         <!-- <div class="form-div">
            <input  type="email"class="banner-input" placeholder="Enter Your Email"  [(ngModel)]="car.email" name="email"  />
          </div> -->
          <!--<div class="form-div">
            <input  type="text"class="banner-input" placeholder="Enter Your Car Reg No."  [(ngModel)]="car.registration" name="registration"  />
          </div> -->
          <div class="form-div">
            <input class="banner-input" pattern="[0-9]+"  
            placeholder="Enter Your Mobile Number"
            [(ngModel)]="car.contact_no" ngModel #contact="ngModel" 
            (keypress)="numberValidator(contact)"  name="contact_no"  />
            <div [hidden]="!contact.invalid" style="margin-top: 1%; margin-bottom: 1%;">
              Please Enter Correct Number.
            </div>
          </div>

          <div class="form-div" *ngIf="isOtp">
            <input
              required
              name="enter_otp"
              type="number"
              class="banner-input-password"
              [(ngModel)]="car.otp"
              id="enter_otp"
              pattern="^((\+91-?)|0)?[0-9]{5}$"
              placeholder="One time password"
              #otp="ngModel"
            />

            <span class="resend-otp"
             (click)="resendOtp(car)"
             style="cursor: pointer;">
             Resend OTP</span>

          </div>
          <div *ngIf="otp.errors?.pattern">
           Invalid Otp.Minimum 5 length allow.
       </div> 
          <!-- <button class="btn btn-submit" >
            Check Price
          </button> -->
          <button [disabled]="contact.invalid" *ngIf="!otpSent" class="btn btn-submit" (click)="senOtp(car)" style="border-radius: 25px;">
            Proceed
          </button>
          <button *ngIf="otpSent" (click)="otpverify(car)" style="border-radius: 25px;"
           [disabled]="otp.errors" class="btn btn-submit"
            > Verify OTP</button>

          <button id="closeprice" [hidden]="true" data-dismiss="modal">Close Price Modal</button>
          <button id= "selectCar" [hidden]="true" data-toggle="modal" data-target="#selectcar" data-dismiss="modal">selectCar</button>
          <button id= "addCar" [hidden]="true" data-toggle="modal" data-target="#addcar" data-dismiss="modal">selectCar</button>
          <button id="otpModal" data-toggle="modal" data-target="#otp" [hidden]="true" data-dismiss="modal">Open Otp Modal</button>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Select cars  -->
<div id="selectcar" class="modal fade price-modal" role="dialog">
  <div class="modal-dialog modal-custom">
    <div class="modal-content modal-color">
      <div class="modal-header">
        <button type="button"  id="selectCarClose" class="close" data-dismiss="modal">
          <img src="assets/images/Close.webp" alt="" />
        </button>
        <h4 class="modal-title">Select Car</h4>
      </div>
      <div class="modal-body body-color">
        
        <form class="form-main" id="" #appointment="ngForm">
          <div class="cars-column">
          <div class="form-div" *ngFor="let car of userCars" style="margin-bottom: 1%;">
            <input class="form-check-input car-selector" (click)="carSelected(car)" type="checkbox" value="" id="flexCheckDefault">
            <span class="car-name">{{car.title}}</span>
           </div>
          </div>
          <div class="form-div">
            <input #inpValue (keyup)="searchModel($event)" [(ngModel)]="car.name"  required name="car_name"  type="text" class="banner-input" id="car_name" placeholder="Search your Car"/>
            <div class="suggestions" *ngIf="carList.length">
              <p *ngFor="let car of carList" (click)="onselectCar(car)">{{car.variant}}</p>
            </div>
          </div> 
          <!-- <button (click)="otpverify(car)" class="btn btn-submit" data-toggle="modal"data-target="#cart"> Verify OTP</button> -->
          <button [disabled]="inpValue.value == '' || carName != inpValue.value" style="border-radius: 25px;" (click)="proceedToCar()"   class="btn btn-submit"> Proceed </button>
        </form>
      </div>
    </div>
  </div>
</div>



<!-- Add new car -->
<div id="addcar" class="modal fade price-modal" role="dialog">
  <div class="modal-dialog modal-custom">
    <div class="modal-content modal-color">
      <div class="modal-header">
        <button type="button"  id="addcarClose" class="close" data-dismiss="modal">
          <img src="assets/images/Close.webp" alt="" />
        </button>
        <h4 class="modal-title">Add Car</h4>
      </div>
      <div class="modal-body body-color">
        
        <form class="form-main" id="" #selectcar="ngForm">
          
          <div class="form-div" >
            <input [(ngModel)]="carName"   type="text" name="CarName" class="banner-input" id="car_name"/>
            <!--<span class="banner-input" [(ngModel)]="car.name" name="CarName"> {{carName}} </span>-->
          </div> 
          <div class="form-div">
            <input [(ngModel)]="car.registration" name="registration" required  type="text" class="banner-input" id="registration-no" placeholder="Registration Number"/>
          </div> 
          <!-- <button (click)="otpverify(car)" class="btn btn-submit" data-toggle="modal"data-target="#cart"> Verify OTP</button> -->
          <button  style="border-radius: 25px;" (click)="checkPrice()"  class="btn btn-submit" > Check Price</button>
        </form>
      </div>
    </div>
  </div>
</div>






<!-- OTP -->
<div id="otp" class="modal fade price-modal" role="dialog">
  <div class="modal-dialog modal-custom">
    <div class="modal-content modal-color">
      <div class="modal-header">
        <button type="button" id="otpclose" class="close" data-dismiss="modal">
          <img src="assets/images/Close.webp" alt="" />
        </button>
        <h4 class="modal-title">Account</h4>
      </div>
      <div class="modal-body body-color">
        <p class="modtal-text">
          To place on order, log in to your existing account or sign up.
        </p>
        <form class="form-main" id="" #appointment="ngForm">
          <div class="form-div">
            <input
              required
              name="car_name"
              type="text"
              class="banner-input"
              id="car_name"
              [(ngModel)]="car.name"
                readonly
              placeholder="Search your Car"
            />
          </div>
          <div class="form-div">
            <input
              required
              name="enter_otp"
              type="number"
              class="banner-input"
              [(ngModel)]="car.otp"
              id="enter_otp"
              pattern="^((\+91-?)|0)?[0-9]{5}$"
              placeholder="One time password"
              #otp="ngModel"
            />

            <span class="resend-otp"
             (click)="resendOtp(car)"
             style="cursor: pointer;">
             Resend OTP</span>

          </div>
          <div *ngIf="otp.errors?.pattern">
           Invalid Otp.Minimum 5 length allow.
       </div> 
       
          <!-- <button (click)="otpverify(car)" class="btn btn-submit" data-toggle="modal"data-target="#cart"> Verify OTP</button> -->
          <button (click)="otpverify(car)" style="border-radius: 25px;" [disabled]="otp.errors" class="btn btn-submit" > Verify OTP</button>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Cart -->
<div id="cart" class="modal fade price-modal" role="dialog">
  <div class="modal-dialog modal-custom">
    <div class="modal-content modal-color">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <img src="assets/images/Close.webp" alt="" />
        </button>
        <h4 class="modal-title" *ngIf="selectedCar">{{selectedCar.title}}<img class="edit-cart-name" src="assets/images/edit-cart-name.webp" alt="" /></h4>
        <!-- <p class="modtal-text">Petrol</p> -->
      </div>
      <div class="modal-body body-color">
        <div class="empty-cart" style="display: none;">
          <img src="assets/images/cart.webp" alt="" />
          <p class="modtal-text">
            Please select
            <a href="#" [routerLink]="['./service']" data-dismiss="modal">services</a>
            for your car
          </p>
        </div>
        <div class="added-cart">
          
          
            <div class="addServices" *ngFor="let item of cartData; let i = index">
              <button type="button" class="close" (click)="removeCartData(item)">
                <img src="assets/images/Close.webp" alt="" />
              </button>
                <div class="serviceName">
                    {{item.service}}
                    <div class="qty">
                        Qty: 1
                    </div>
                </div>
                <div class="sevicePrice">
                    ₹{{item.cost}}
                </div>
            </div>
            

            <div class="addServices subtotal">
                <div class="serviceName">
                    Subtotal
                </div>
                <div class="sevicePrice">
                    ₹ {{ getcartTotal() }}
                    <div class="qty">
                       GST INCLUDED
                    </div>
                </div>
            </div>
         
        <div class="row">
          <div class="col-md-6 ">
            <a href="javascript:void(0)"
               data-dismiss="modal"
               [routerLink]="['/service/checkout']"
                class="cart-checkout">CHECKOUT</a>
        </div>



          <div class="col-md-6 ">
            <a href="javascript:void(0)"  data-dismiss="modal" class="cart-checkout">ADD MORE</a>
          </div>

        </div>
         
          </div>
      </div>
    </div>
  </div>
</div>
