import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { CartServiceService } from 'src/app/cart-service.service';
import { isPlatformBrowser } from '@angular/common'
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalTitleService } from 'src/app/@service/canonical-title.service';


@Component({
  selector: 'app-our-story',
  templateUrl: './our-story.component.html',
  styleUrls: ['./our-story.component.css']
})
export class OurStoryComponent implements OnInit {
  isLoader: boolean = true
  constructor(private cartService: CartServiceService,private SeoTitle:CanonicalTitleService,
    @Inject(PLATFORM_ID) private platformId,
    private meta: Meta,
    private title: Title) {
      this.title.setTitle("Our Story | CarEager - World-Class Luxury Car Services Provider")
      this.meta.updateTag({name: 'Description', content: "CarEager is Well Known for luxury Car Services like BMW, Audi, Mercedes, Jaguar, car painting, repair, and ceramic coating at a low price. Call Now!"})
    }

  ngOnInit() {
    this.SeoTitle.createLinkForCanonicalURL() 
    let user = undefined
    if (isPlatformBrowser(this.platformId)) {
      user = JSON.parse(localStorage.getItem("user"))
    }


    if (user) {
      this.cartService.isCart = true
    }
  }
  ngAfterViewInit() {
    //this.isLoader = false
    setTimeout(() => {
      this.isLoader = false
    }, 500);
  }

}
