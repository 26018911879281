import { ThankuComponent } from './@shared/thanku/thanku.component';
import { ServiceComponent } from './@component/service/service.component';
import { HomeComponent } from './@component/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OurStoryComponent } from './@component/our-story/our-story.component';
import { OffersComponent } from './@component/offers/offers.component';
import { OfferDetailComponent } from './@component/offer-detail/offer-detail.component';
import { GalleryComponent } from './@component/gallery/gallery.component';
import { BmwserviceComponent } from './@component/service/bmwservice/bmwservice.component';
import {SuccessComponent} from './@shared/success/success.component'
import { PreloadAllModules } from '@angular/router';
import { PrivacypolicyComponent } from './@component/privacypolicy/privacypolicy.component';
import { NotfoundComponent } from './@component/notfound/notfound.component';



const routes: Routes = [
  {path:'',component:HomeComponent,pathMatch:'full'},
  {path:'home/:param',component:HomeComponent,},

  {path:'our-story',component:OurStoryComponent,},
  {path:'our-story/:id',component:OurStoryComponent,},

  {path:'offers/:id',component:OffersComponent,},
  {path:'offers',component:OffersComponent,},
  {path:'offer-detail',component:OfferDetailComponent,},
  {path : 'successful', component : SuccessComponent, },

  {path:'gallery',component:GalleryComponent,},
  {path:'gallery/:id',component:GalleryComponent,},
  
  {path: 'car/bmw', component: BmwserviceComponent, },

   { path: 'thanku', component: ThankuComponent,},
   
   {path:'service',component:ServiceComponent, loadChildren: () =>
   import('./@component/service/service.module').then((m) => m.ServiceModule),  
   },
   {path:'privacy-policy',component:PrivacypolicyComponent,},
  //  {path:'service/:car',component:ServiceComponent, loadChildren: () =>
  //  import('./@component/service/service.module').then((m) => m.ServiceModule),  
  //  },
  //  {path:'service/:car/:id',component:ServiceComponent, loadChildren: () =>
  //  import('./@component/service/service.module').then((m) => m.ServiceModule),  
  //  },

   { path: '**', component: NotfoundComponent }
];

@NgModule({
  
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
