import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  expression = true;
  isFooterDisabled = true;
  isLoader: boolean = true

  constructor(private router: Router,
   private gtag: Gtag) { }

  ngOnInit(): void {
    this.gtag.event('conversion', {'send_to': 'AW-623905788/RREeCJLs5fgBEPyXwKkC'});
  }

  ngAfterViewInit() {
    //this.isLoader = false
    setTimeout(() => {
      this.isLoader = false
    }, 500);
  }
  backToHome(){
    this.router.navigate(['']);
  }
}
