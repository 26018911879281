import { AfterViewInit, Component, OnInit, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BookAppointmentComponent } from './../../@shared/book-appointment/book-appointment.component';
import { isPlatformBrowser } from '@angular/common'
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { CanonicalTitleService } from 'src/app/@service/canonical-title.service';


@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {



  showPopup = true
  data 
  constructor(private dialog : MatDialog,
    @Inject(PLATFORM_ID) private platformId,private SeoTitle:CanonicalTitleService,
    private metaTagService: Meta,
    private titleService: Title,) { 
     
    this.titleService.setTitle("Car Services | Luxury Car Repair Services - CarEager")
    this.metaTagService.updateTag({name: 'Description', content: "CarEager is providing one of the excellent quality of premium car services like BMW, Audi car service and auto repair services at the lowest price. Get in Touch with Us!"})


    }


    
  ngOnInit() {
 
    this.SeoTitle.createLinkForCanonicalURL() 
  //   if (!localStorage.getItem('foo')) {
  //     localStorage.setItem('foo', 'no reload')
  //     location.reload()
  //   } else {
  //     localStorage.removeItem('foo')
  //   }
  if (isPlatformBrowser(this.platformId)) {
    this.data = JSON.parse(localStorage?.getItem('user'));

  }
    
    
    // if(this.data?.responseData.status == 'Active'){
    //   this.showPopup = false
    // }
    // if(this.showPopup){
    //   if (window.innerWidth < 600){
    //     setTimeout(() =>{
        
    //       this.dialog.open(BookAppointmentComponent, {
    //         width : '80%',
    //       })
    //     }, 12000)
    //   }else {
    //     setTimeout(() =>{
        
    //       this.dialog.open(BookAppointmentComponent, {
    //         width : '30%',
    //       })
    //     }, 12000)
    //   }
   
    // }

   }

  

}
