
export const environment = {
  production: false,
//hostURL:  'http://localhost:3000/',
 //baseURL:  'http://localhost:3000/api/v2.1/',
 //userBaseURL:  'http://localhost:3000/api/v3.4/',
 //businessURL: 'http://localhost:3000/api/v2.1/business',
 //verificationUrl:'http://localhost:3000/api/v2.0/'
  


  //******Online Url */

      //hostURL:  'http://13.234.81.188:4000/',
	    //baseURL:  'http://13.234.81.188:4000/api/v2.1/',
     // userBaseURL:  'http://13.234.81.188:4000/api/v3.4/',
     // verificationUrl:'http://13.234.81.188:4000/api/v2.0/',
   //   businessURL: 'http://13.234.81.188:4000/api/v2.1/business' ,


  //  productions
    hostURL:  'https://3.7.158.213:443/',
	  baseURL:  'https://3.7.158.213:443/api/v2.1/',
    userBaseURL:  'https://3.7.158.213:443/api/v3.4/',
    businessURL: 'https://3.7.158.213:443/api/v2.1/business',
    verificationUrl:'https://3.7.158.213:443/api/v2.0/'

    // stagging

    // hostURL:  'https://43.205.81.35:443/',
	  // baseURL:  'https://43.205.81.35:443/api/v2.1/',
    // userBaseURL:  'https://43.205.81.35:443/api/v3.4/',
    // businessURL: 'https://43.205.81.35:443/api/v2.1/business',
    // verificationUrl:'https://43.205.81.35:443/api/v2.0/'
};
