import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

 
@Injectable({ providedIn: "root"})
export class SharedService {


res={
    responseCode:0
}
_carModel={
  
}
_offer={}
_car={}

  private messageSource = new BehaviorSubject<any>(this.res);
  currentMessage = this.messageSource.asObservable();
  private carModel = new BehaviorSubject<any>(this._carModel);
  currentcarModel = this.carModel.asObservable();

  private offerDetail = new BehaviorSubject<any>(this._offer);
  currentofferDetail = this.offerDetail.asObservable();

  private carDetail = new BehaviorSubject<any>(this._car);
  currentcarDetail = this.carDetail.asObservable();

  setcarUrl = new BehaviorSubject<any>('');
  getcarUrl = this.setcarUrl.asObservable();  


  constructor() { }

  changeMessages(message: any) {
    this.messageSource.next(message)
  }
  changeCarModel(Model:any){
    this.carModel.next(Model);
  }
  changeofferDetail(Model:any){
    this.offerDetail.next(Model)
  }
  changecarDetail(Model:any){
    this.carDetail.next(Model)
  }

  setAllCarUrl(url:any){
    this.setcarUrl.next(url)
  }


}