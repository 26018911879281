<app-footer-text [_carType]="_carType"></app-footer-text>


<div class="footer">
  <div class="outer-30">
    <img src="assets/images/logo.webp" alt="" />
    <p><br> CarEager NH8, Hero Honda Chowk, near BBT, Sector 37, Gurugram, Haryana 122004</p>
      <p class="footer-email">care@CarEager.com</p>
      <p class="footer-number">1800-843-4300</p>
  </div>
  
  <div class="outer-70">
    <div class="inner-25">
        <h4 class="footer-heading">Our Services</h4>
        <ul class="inner-ul">
        <li><a [routerLink]="['/service/car-detailing']">Detailing</a></li>
        <li><a [routerLink]="['/service/customise-your-car']">Customization</a></li>
        <li><a  [routerLink]="['/service']">Services</a></li>
        <li><a [routerLink]="['/service/car-denting-painting']">Collision Repair</a></li>
        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
        </ul>
    </div>
    <div class="inner-25">
      <h4 class="footer-heading">Popular Locations</h4>
      <ul class="inner-ul">
      <li><a routerLink="/service"> Sohna Road</a></li>
      <li><a routerLink="/service">Cyber Hub</a></li>
      <li><a routerLink="/service">Sushant Lok</a></li>
      <li><a routerLink="/service">DLF Phase 2 </a></li>
      <li><a routerLink="/service">Golf Course road</a></li>
      <li><a routerLink="/service">Udyog Vihar</a></li>
      </ul>
  </div>
  <div class="inner-25">
    <h4 class="footer-heading">Luxury Brands</h4>
    <ul class="inner-ul">
      <!-- <li><a [routerLink]="['/service/car-detailing']">Detailing</a></li> -->
    <li (click)="setcarUrl('bmw')"><a routerLink="/service/bmw" style="cursor: pointer !important;">BMW</a></li>
    <li (click)="setcarUrl('jaguar')"><a routerLink="/service/jaguar" style="cursor: pointer !important;">Jaguar</a></li>
    <li (click)="setcarUrl('porsche')"><a  routerLink="/service/porsche" style="cursor: pointer !important;">Porsche</a></li>
    <li (click)="setcarUrl('mercedes')"><a  routerLink="/service/mercedes" style="cursor: pointer !important;" >Mercedes Benz</a></li>
    <li (click)="setcarUrl('audi')"><a routerLink="/service/audi" style="cursor: pointer !important;"  >Audi</a></li>
    <li (click)="setcarUrl('land-rover')"><a routerLink="/service/land-rover" style="cursor: pointer !important;">LandRover</a></li>
    <li (click)="setcarUrl('volvo')"><a routerLink="/service/volvo" style="cursor: pointer !important;" >Volvo </a></li>

    </ul>
</div>
<div class="inner-25">

  <h4 class="footer-heading">Popular Brands</h4>
  <ul class="inner-ul">
  <li><a  routerLink="/service">Maruti Suzuki</a></li>
  <li><a routerLink="/service">Hyundai</a></li>
  <li><a routerLink="/service">Volkswagen</a></li>
  <li><a routerLink="/service">Ford </a></li>
  <li><a routerLink="/service">Kia</a></li>
  <li><a routerLink="/service">Morris Garages (MG)</a></li>
  <li><a routerLink="/service">Tata</a></li>
  <li><a routerLink="/service">Honda</a></li>
  </ul>
</div>

  </div>
  <div class="copyright">
    <div class="copy-50">
      <p>Copyright © 1991-2020 | Victorious Managers Group (P) Limited</p>
    </div>
    <div class="copy-50">
      <div class="copy-icons">
        <div class="appstore">
          <a
                href="https://goo.gl/fU5Upb  "
                target="_blank"
                ><img src="assets/images/google-store.webp" alt=""
              /></a>
              <!-- <a
                href="https://apps.apple.com/us/app/careager-xpress/id1445538212?ls=1"
                target="_blank"
                ><img src="assets/images/apple-store.webp" alt=""
              /></a> -->
        </div>
        <div class="social-icons">
          <a  target="_blank" href="https://www.facebook.com/CarEagerWorld/">
            <img src="assets/images/facebook.webp" alt="">
          </a>
          <!-- <a target="_blank" href="#">
            <img src="assets/images/whatsapp.webp" alt="">
          </a> -->

          <a  target="_blank" href="https://www.youtube.com/user/CarEager">
            <img src="assets/images/youtube.png" alt="">
          </a>

          <a  target="_blank" href="https://twitter.com/careagerworld">
            <img src="assets/images/twitter.png" alt="">
          </a>

          <a target="_blank" href="https://www.instagram.com/careager/">
            <img src="assets/images/instagram.webp" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
</div>