import { NgForm } from '@angular/forms';
import { ApplicationService } from 'src/app/@backend/application.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.css']
})
export class DownloadAppComponent implements OnInit {
  isSubmit: boolean = false;
  appointmentForm:any
  constructor(private _applicationservice: ApplicationService) { }

  ngOnInit() {
  }

  onSubmit = (formData: NgForm) => {
    this.isSubmit = true;
  
    if (formData.invalid) {
      return false;
    }
    // "contact_no":"7380980982",
    // "company":"CarEager Xpress Gurugram",
    // "source":"App Link"
    formData.value.company="CarEager Xpress Gurugram";
    formData.value.source="App Link";
    this._applicationservice.generateLead(formData.value).subscribe(
      (_res) => {
     
        // if(_res.responseCode == 200){
        //  alert("")
        // }
      },
      (err) => {
      
      }
    );
  };

}
