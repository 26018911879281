import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CartServiceService {
  cartData: Array<any> = []
  serviceArray: any = [];
  isCart: boolean;
  cartTotal: any;
  removedCartData = []
  constructor() { }

  removeElement = () => {
    this.cartData.splice(0, 1)
  } 

s


}
