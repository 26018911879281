<div *ngIf="isLoader" class="cg-loader">
  <div><img style="width: 24rem;" src="../../../assets/images/careager-loader.gif" ></div>
</div>



<section class="gallery-main">
  <div class="container-fluid gallery-container">
    <div class="row">
      <div class="col-xs-12 text-center gallery-heading">
        <h1>The <span style="color: #f00;">Ultimate</span> Car Services</h1>
      </div>
    </div>
    <div class="row">
      <ul class="nav nav-tabs offers-tab">
        <li class="active"><a data-toggle="tab" class="gallery-tabs" href="#home">Services</a></li>
        <li><a data-toggle="tab" class="gallery-tabs" href="#menu1">Denting-Painting</a></li>
        <li><a data-toggle="tab" class="gallery-tabs" href="#menu2">Detailing</a></li>
        <!-- <li><a data-toggle="tab" class="gallery-tabs" href="#menu3">Customization</a></li> -->
      </ul>

      <div class="tab-content" >
        <div id="home" class="tab-pane first in active">
          <!-- Photo Grid -->
          <div class="row-gallery">
            
            <div class="row full-with">
              <div class="col-xs-4">
                <img draggable="false" style="width: 100%;padding: 10px;" src="assets/images/service/services-img/2.jpg" class="img-responsive" />
              </div>
              <div class="col-xs-4">
                <img draggable="false" style="width: 100%;padding: 10px;" src="assets/images/service/services-img/1.jpg" class="img-responsive" />
              </div>
              <div class="col-xs-4">
                <img draggable="false" style="width: 100%;padding: 10px;" src="assets/images/service/services-img/3.jpg" class="img-responsive" />
              </div>
              <div class="col-xs-4">
                <img draggable="false" style="width: 100%;padding: 10px;" src="assets/images/service/services-img/5.jpg" class="img-responsive" />
              </div>
              <div class="col-xs-4">
                <img draggable="false" style="width: 100%;padding: 10px;" src="assets/images/service/services-img/4.jpg" class="img-responsive" />
              </div>
              <div class="col-xs-4">
                <img draggable="false" style="width: 100%;padding: 10px;" src="assets/images/service/services-img/6.jpg" class="img-responsive" />
              </div>
              <div class="col-xs-4">
                <img draggable="false" style="width: 100%;padding: 10px;" src="assets/images/service/services-img/7.jpg" class="img-responsive" />
              </div>
              <div class="col-xs-4">
                <img draggable="false" style="width: 100%;padding: 10px;" src="assets/images/service/services-img/8.jpg" class="img-responsive" />
              </div>
              <div class="col-xs-4">
                <img draggable="false" style="width: 100%;padding: 10px;" src="assets/images/service/services-img/9.jpg" class="img-responsive" />
              </div>
              </div>
            <!-- <div class="row">
              <div class="col-md-8 form-group">
                  <div class="row gallery-images-alignment">
                    <div class="col-md-12 form-group">
                      <img src="assets/images/service/Gallery/services/2.webp" class="img-responsive img2" style="width:100%; height:400px" />
                    </div>
                  </div>
                  <div class="row gallery-images-alignment">
                    <div class="col-xs-6 form-group">
                      <div class="row">
                        <div class="col-xs-12 form-group">
                          <img src="assets/images/service/Gallery/services/1.webp" class="img-responsive" style="width:100%; height:416px" />
                        </div>
                    </div>
                    </div>
                      <div class="col-xs-6 form-group">
                        <div class="row">
                          <div class="col-xs-12 form-group">
                            <img src="assets/images/service/Gallery/services/5.webp" class="img-responsive" style="width:100%; height:200px" />
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-xs-12 form-group">
                          <img src="assets/images/service/Gallery/services/6.webp" class="img-responsive" style="width:100%; height:200px" />
                        </div>
                      </div>                          
                    </div>
                  </div>
                </div>        
    
              <div class="col-md-4 form-group">
                <div class="col-md-12 form-group">
                  <img src="assets/images/service/Gallery/services/3.webp" class="img-responsive" style="width:100%; height:200px" />
                </div>
                <div class="col-md-12 form-group">
                  <img src="assets/images/service/Gallery/services/4.webp" class="img-responsive" style="width:100%; height:400px" />
                </div>
                <div class="col-md-12 form-group">
                  <img src="assets/images/service/Gallery/services/7.webp" class="img-responsive" style="width:100%; height:200px" />
                </div>
              </div>
    
            </div> -->
          </div>


        </div>

        <!--  -->
        <div id="menu1" class="tab-pane fade">
            <div class="row-gallery">
              <div class="row full-with">
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/dentingPainting/2.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/dentingPainting/11.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/dentingPainting/3.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/dentingPainting/4.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/dentingPainting/5.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/dentingPainting/6.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/dentingPainting/7.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/dentingPainting/8.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/dentingPainting/9.jpg" class="img-responsive" />
                </div>
                </div>
               
              </div>
        </div>
        <!--  -->
        <div id="menu2" class="tab-pane fade">
             <div class="row-gallery">
              <div class="row full-with">
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/detailing/1.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/detailing/2.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/detailing/3.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/detailing/4.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/detailing/5.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/detailing/6.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/detailing/7.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/detailing/8.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false"  style="width: 100%;padding: 10px;" src="assets/images/service/detailing/9.jpg" class="img-responsive" />
                </div>
                
                  </div>
              </div>
          </div>
          <!-- <div id="menu3" class="tab-pane fade">
             <div class="row-gallery">

              <div class="row full-with">
                <div class="col-xs-4">
                  <img draggable="false" src="assets/images/service/customization/2.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false" src="assets/images/service/customization/1.jpg" class="img-responsive" />
                </div>
                <div class="col-xs-4">
                  <img draggable="false" src="assets/images/service/customization/3.jpg" class="img-responsive" />
                </div>
                </div>
              
               
              </div>
          </div> -->
      </div>
    </div>
  </div>
<!-- <div class="gallery-video">
    <img src="assets/images/Group290.png" alt="">
    <a href="javascript:void(0);" class="gallery-btn">
        <img src="assets/images/Polygon1.png" alt="">
    </a>
</div> -->
  <!-- <app-book-appointment></app-book-appointment> -->
  <app-download-app></app-download-app>

</section>
