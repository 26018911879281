import { Security } from "./security";
// import { environment } from './../../environments/environment.prod';
import { environment } from "./../../environments/environment";
import { Injectable, Inject, PLATFORM_ID } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalStorage, SessionStorage } from "ngx-store";
import { isPlatformBrowser } from '@angular/common'


@Injectable({
  providedIn: "root",
})
export class ApplicationService {
   deviceid: any;
   devicetype: any;
   token: any;
 // @LocalStorage("token") token: any;
  registrationNumber: any;

  businessId = "5bfec47ef651033d1c99fbca";

  // userBaseURL:  'http://13.234.81.188:3000/api/v3.4/'
  constructor(private _security: Security, private _http: HttpClient,
    @Inject(PLATFORM_ID) private platformId) {
      if (isPlatformBrowser(this.platformId)) {
        this.token = localStorage.getItem('token');
        this.deviceid = localStorage.getItem("deviceid")
        this.devicetype = localStorage.getItem("devicetype")
        this.registrationNumber =  "12345"//localStorage.getItem("registration")
      }
    }

  _getheaderForBooking = () => {
    const _headerdict: any = {
      business: "5bfec47ef651033d1c99fbca",
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "x-access-token": this.token,
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };
    return _headerdict;
  };

  getBookingCars(contact)  {

    this.deviceid = localStorage.getItem("deviceid")
    this.devicetype = localStorage.getItem("devicetype")

    this.token = localStorage.getItem('token');

      var headers = {
        business: "5bfec47ef651033d1c99fbca",
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        "x-access-token": this.token,
        "content-type": "application/json",
        deviceid: this.deviceid,
        devicetype: this.devicetype,
      };
    
    return this._http.get<any>("http://13.234.81.188:4000/api/v3.4/user/get/booking/cars?contact=" + contact, 
    {
      headers: new HttpHeaders(headers),
    })
  }

  getoffersSlider = () => {
  
    var headers = {
      business: "5bfec47ef651033d1c99fbca",
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      //"x-access-token": token,
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };
    return this._http.get<any>("https://13.234.81.188:443/api/v3.4/xpress/offers/banner",
    {
      headers: new HttpHeaders(headers),
    })
  }

  convenience(business) {
    var headers = {
      business: this.businessId,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWJmZWM0N2VmNjUxMDMzZDFjOTlmYmNhIiwiaWF0IjoxNjAxMjI4NjQzfQ.e_btDJaXqe_DFCAunhOGKWLDTdPw4C7SPdbpOqb1fYQ",
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };
    return this._http.get<any>(
      environment.userBaseURL + "user/booking/convenience?business=" + business,
      {
        headers: new HttpHeaders(headers),
      }
    );
  }

  getAddress = () => {
    var headers = {
      business: "5bfec47ef651033d1c99fbca",
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "x-access-token": this.token,
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };
    return this._http.get<any>(
      environment.userBaseURL + "user/address/get",
      {
        headers: new HttpHeaders(headers),
      }
    );
  }

  saveAddress = (address) => {
    var headers = {
      business: "5bfec47ef651033d1c99fbca",
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "x-access-token": this.token,
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };
    return this._http.post<any>(
      environment.userBaseURL + "user/address/add",
      address,
      {
        headers: new HttpHeaders(headers),
      }
    );
  }
  newBooking(data) {
    
    if (this.registrationNumber) {
      data["registration_no"] = "12345"
    } else {
      data['registration_no'] = "12345"
    }
    data.business = this.businessId
    
    var headers = {
      business: "5bfec47ef651033d1c99fbca",
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "x-access-token": this.token,
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };
    return this._http.post<any>(
      environment.userBaseURL + "user/web/booking/add/",
      data,
      {
        headers: new HttpHeaders(headers),
      }
    );
  }

  timeSlot(data) {

    var headers = {
      business: this.businessId,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "x-access-token": this.token,
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };
    return this._http.post<any>(
      environment.userBaseURL + "user/bookings/time-slot",
      data,
      {
        headers: new HttpHeaders(headers),
      }
    );
  }

  payLater(data) {
    var headers = {
      business: this.businessId,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "x-access-token": this.token,
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };

    return this._http.post<any>(
      environment.userBaseURL + "user/pay/later",
      data,
      {
        // headers:  this._getheaderForBooking()
        headers: new HttpHeaders(headers),
      }
    );
  }
  payNow(data) {
    var headers = {
      business: this.businessId,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "x-access-token": this.token,
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };


    console.table(headers);

    return this._http.get<any>(
      environment.userBaseURL + `user/payment/checksum/generate?id=${data.id}&pay=${data.pay}`,     
      {
        // headers:  this._getheaderForBooking()
        headers: new HttpHeaders(headers),
      }
    );
  }

  applyCoupon(data) {
    
    var headers = {
      business: this.businessId,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "x-access-token": this.token,
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };
    return this._http.post<any>(
      environment.userBaseURL + "user/booking/coupon/add",
      data,
      {
        headers: new HttpHeaders(headers),
      }
    );
  }

  coupon(business){

    var headers = {
      business: this.businessId,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "x-access-token": this.token,
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };
    return this._http.get<any>(
      environment.userBaseURL + "user/booking/coupon/list?business=" + business,
      {
        headers: new HttpHeaders(headers),
      }
    );
	}

  // createBooking(data){
  //   var headers = {
  //     'business': this.businessId,
  //     'tz': Intl.DateTimeFormat().resolvedOptions().timeZone,
  //     'x-access-token': this.token,
  //     'content-type': "application/json",
  //     'deviceid': this.deviceid,
  //     'devicetype': this.devicetype
  //   };
  // 	return this._http.post<any>(environment.userBaseURL+"user/booking/add/",data,{
  // 		headers:  new HttpHeaders(headers)
  // 	})
  // }

  // Postal Code 

  postalCode = (zip: any) => {
    var headers = {
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };
    return this._http.get<any>(
      environment.businessURL + "/postal/get?zip=" + zip, {headers: new HttpHeaders(headers)}
    )
  }

  sendOtp = (formData: any) => {
    return this._http.post<any>(
      environment.userBaseURL + "xpress/service/price/otp",
      formData,
      {}
    );
  };

  otpVerification(data) {

    this.deviceid = localStorage.getItem("deviceid")
    this.devicetype = localStorage.getItem("devicetype")
    var headers = {
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };
    return this._http.post<any>(
      // reset/password/otp/verification
      // environment.verificationUrl + "reset/password/otp/verification",
      environment.verificationUrl + "login-with-otp",

      data,
      {
        headers: new HttpHeaders(headers),
      }
    );
  }

  resentOtp(data) {
    return this._http.post<any>(
      environment.userBaseURL + "resend/otp",
      data,
      {}
    );
  }

  getCoupanList = () => {
    return this._http.post<any>(
      environment.userBaseURL + "user/booking/coupon/list",
      {}
    );
  };
  generateLead = (formData: any) => {
    return this._http.post<any>(
      environment.userBaseURL + "xpress/lead/add",
      formData,
      {}
    );
  };

  setGallery = (user) => {
    return this._http.post<any>(environment.baseURL + "gallery/get", user, {});
  };

  getServices(type) {
    var headers = {
      business: this.businessId,
    };
    // http://13.234.81.188:3000/api/v3.4/xpress/services/description/get?type=customization

    return this._http.get<any>(
      environment.userBaseURL + "xpress/services/description/get?type=" + type,
      {
        headers: new HttpHeaders(headers),
      }
    );
  }


  removeCartData(id) {
    var headers = {
      business: this.businessId,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "x-access-token": this.token,
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };
    return this._http.put<any>(environment.hostURL + "api/v3.4/xpress/remove/cart/data",{serviceId: id}, {
      headers: new HttpHeaders(headers)
    })
  }

  getCartData() {
    var headers = {
      business: this.businessId,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "x-access-token": this.token,
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };
    return this._http.get<any>(environment.hostURL + "api/v3.4/xpress/get/cart/data", {
      headers: new HttpHeaders(headers)
    })
  }

  saveDataInCart(cartData) {
    var headers = {
      business: this.businessId,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "x-access-token": this.token,
      "content-type": "application/json",
      deviceid: this.deviceid,
      devicetype: this.devicetype,
    };
    return this._http.post<any>(
      environment.hostURL + "api/v3.4/xpress/cart/add/services",
      {services: cartData},
      {
        headers: new HttpHeaders(headers),
      }
    );
  }

  searchModel(data) {
    return this._http.get<any>(
      environment.userBaseURL + "makers/get?query=" + data,
      {
        // headers: new HttpHeaders( environment.userBaseURL.headers)
      }
    );
  }

  servicesRate(car, title) {
    var headers = {
      business: this.businessId,
    };

    // http://13.234.81.188:3000/api/v2.1/business/services/rate/xpr?type=services&car=5b290623e9367455c048efb3
    return this._http.post<any>(
      environment.baseURL +
        "business/services/rate/xpr?type=" +
        title +
        "&car=" +
        car,
      {},
      {
        headers: new HttpHeaders(headers),
      }
    );
  }
}
