import { Injectable, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
   providedIn: 'root'
})
export class CanonicalTitleService { 
   link:any;
   constructor(private title: Title, @Inject(DOCUMENT) private doc) {
   }
   setPageTitle(title: string) {
      this.title.setTitle(title);
   }   
   getPageTitle() {
      return this.title.getTitle();
   }
   createLinkForCanonicalURL() {
      if (this.link === undefined) {
         this.link = this.doc.createElement('link');
         this.link.setAttribute('rel', 'canonical');
         this.doc.head.appendChild(this.link);
       }
       this.link.setAttribute('href', this.doc.URL.split('?')[0]);
     }

   }


