import { isPlatformBrowser } from '@angular/common';
import { Inject } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { ApplicationService } from 'src/app/@backend/application.service';
import { CanonicalTitleService } from 'src/app/@service/canonical-title.service';
import { SharedService } from 'src/app/@service/shared.service';
import { CartServiceService } from 'src/app/cart-service.service';
import { ModalService } from '../modalpopup/modal.service';

@Component({
  selector: 'app-bmwservice',
  templateUrl: './bmwservice.component.html',
  styleUrls: ['./bmwservice.component.css']
})
export class BmwserviceComponent implements OnInit {
  videos = ['9KFm946y2PI', 'UfLL80krQ1c', 'RWFvIqJ8isU']

  // @SessionStorage("carData") carData: any;
  // @LocalStorage("selectedcar") selectedcar: any;
  //@SessionStorage("cartData") cartData: Array<any>;
  //@SessionStorage("carfrom") carfrom: any;
  //@SessionStorage("servicesArray") servicesArray: Array<any>;
  carfrom: any;

  isLoader: boolean = true


  @ViewChild("structure", { static: false }) public structure;

  CarImages: any =
    {
      mercedes: {
        servicetab1:
          "assets/images/service/Cars/mercedes/Services-Periodicservice.jpg",
        servicetab2:
          "assets/images/service/Cars/mercedes/Services-ACService.jpg",
        servicetab3:
          "assets/images/service/Cars/mercedes/Services-ComputerisedScanning.jpg",
        servicetab4:
          "assets/images/service/Cars/mercedes/Services-Suspension.jpg",
        Dentpainttab1:
          "assets/images/service/Cars/mercedes/Denting-Paintingbumperrepairpainting_.jpg",
        Dentpainttab2:
          "assets/images/service/Cars/mercedes/Denting-Painting-fullbodypainting.jpg",
        Dentpainttab3:
          "assets/images/service/Cars/mercedes/Denting-Painting- World_smostadvancedcollisionrepairsystem.jpg",
        Dentpainttab4:
          "assets/images/service/Cars/mercedes/ComputerisedBodyRepair.jpg",
        Detailingtab1:
          "assets/images/service/Cars/mercedes/Detailing-Rubbing _Polishing.jpg",
        Detailingtab2:
          "assets/images/service/Cars/mercedes/Detailing-Wash-N-Vacuum.jpg",
        Detailingtab3:
          "assets/images/service/Cars/mercedes/Detailing-AlloysRestoration.jpg",
        Detailingtab4:
          "assets/images/service/Cars/mercedes/Detailing-InteriorBeautification.jpg",
        Customztab1:
          "assets/images/service/Cars/mercedes/CustomExhaustSystem.jpg",
        Customztab12: "assets/images/service/Cars/mercedes/AlloyPainting.jpg",
        Customztab2: "assets/images/service/Cars/mercedes/bodyGraphics.jpg",
        Customztab4: "assets/images/service/Cars/mercedes/customization1.jpg",
      },
    }
    ;
  getCarModel: any = {};
  @Input() tabId: any;
  descriptionArray: any;
  //servicesArray = [];
  appointmentForm: any;
  servicesArray: Array<any> = []
  cartData: Array<any> = []
  isSubmit: boolean = false;
  slideIndex: any = 1;
  showDyanmicContent: boolean = true;
  type = "";

  m = "0px"
  car: any;
  changeserviceText = "";
  changeservicePrice: number;
  isService: boolean = true;
  isdenting: boolean = true;
  isdetailing: boolean = true;
  iscustomization: boolean = true;
  img: string;
  latestImage: string;
  isFooter: boolean = false;
  shorttabtitle: any[];
  urlCar: string;
  deviceWidth: number;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private titleService: Title,
    private data: SharedService,
    private _applicationservice: ApplicationService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private metaTagService: Meta, private SeoTitle: CanonicalTitleService,
    private cartService: CartServiceService
  ) {
    

    this.titleService.setTitle("Best BMW Car Services & Repair in Gurgaon | Periodic Services - CarEager")

    this.metaTagService.updateTag(
      {
        name: 'Description',
        content: "Book World-class BMW Car Services & Repair at Best Service Centers & Garages in Gurgaon, Delhi, Noida with Free Pickup and Drop by Trained Mechanics."
      })

  }

  ngOnInit() {
    setTimeout(() => {
      this.isLoader = false
    }, 2500);
    this.SeoTitle.createLinkForCanonicalURL()
    this.deviceWidth = window.innerWidth;
    this.tabId = "services"

    if (window.innerWidth < 500) {
      this.m = "0px"
    } else {
      this.m = "455px"
    }



    let user = undefined;
    let userCar = undefined;

    if (isPlatformBrowser(this.platformId)) {
      user = JSON.parse(localStorage.getItem("user"))
      userCar = JSON.parse(localStorage.getItem("userCar"))
      this.carfrom = JSON.parse(localStorage.getItem("carfrom"))
    }





    if (user) {


      this.type = '1';
      if (userCar.variant.split(" ").length > 1) {
        this.getCaraRate(userCar._id, this.type);
      } else {
        this.getCaraRate(userCar.variant, this.type);
      }

      this._applicationservice.getCartData()
        .subscribe(cartdata => {
          if (cartdata.responseCode == '200') {
            this.cartData = cartdata.cartData.services
            this.cartService.cartTotal = cartdata.total
            this.cartService.cartData = this.cartData

            this.checkItemInCart()
          }
        })
      this.cartService.isCart = true
    } else {

      this.getServices(this.tabId);
    }



    this.data.currentcarDetail.subscribe((_res: any) => {


      this.isFooter = this.carfrom.type == "footer" ? true : false;

    });

    let url = window.location.href
    let length = url.split("/").length
    let urlArray = url.split("/")

    for (let i = 0; i < length; i++) {
      if (urlArray[i] == 'bmw') {


        this.urlCar = "bmw"
        this.latestImage = "assets/images/bmw-service/Periodic service.jpg"
      } else if (urlArray[i] == 'mercedes-benz') {
        this.urlCar = 'mercedes-benz'
        this.latestImage = "assets/images/mercedes/Periodic service.jpg"

      } else if (urlArray[i] == "audi") {

        this.urlCar = 'audi'
        this.latestImage = "assets/images/audi/Periodic services audi.jpg"
      } else {
        this.urlCar = 'service'
        this.latestImage = "assets/images/service/periodicservice.jpg"
      }
    }
    if (url.split("/").length > 5) {
      this.urlCar = url.split("/")[url.split("/").length - 2]

    } else {
      this.urlCar = url.split("/")[url.split("/").length - 1]

    }

    this.getTabProperty();
  }


  ngDoCheck() {

    this.servicesArray = this.cartService.serviceArray

  }

  navigateToSection() {

    this.structure.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }

  getCaraRate(carID, tabType) {
    this._applicationservice.servicesRate(carID, tabType).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.servicesArray = res.responseData;



          this.servicesArray.forEach((element) => {
            element.services.forEach((element) => {
              element.isCart = false;
            });
          });
          this.showDyanmicContent = false

          this.cartService.serviceArray = this.servicesArray
          if (this.cartData !== null && this.cartData.length > 0) {
            this.checkItemInCart();
          }
          console.table(res.responseData);
        }
      },
      (error) => {

      }
    );
  }

  showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("demo");
    // var captionText = document.getElementById("caption");
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      const xyz = <HTMLElement>slides[i];
      xyz.style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }

    const abc = <HTMLElement>slides[this.slideIndex - 1];
    abc.style.display = "block";
    dots[this.slideIndex - 1].className += " active";
  }
  open = (ModalId: string, data: any) => {

    this.descriptionArray = data;
    this.modalService.open(ModalId);
  };
  closeModal(id: string) {
    this.modalService.close(id);
  }
  ngAfterViewInit() {
    //this.getServices(this.tabId);


  }
  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }
  getServices(tabId) {
    console.log('get', tabId)
    this._applicationservice.getServices(tabId).subscribe((res: any) => {
      this.cartService.serviceArray = res.responseData
      this.servicesArray = this.cartService.serviceArray;


    });
  }
  onSubmit = (formData: NgForm) => {
    this.isSubmit = true;

    if (formData.invalid) {
      return false;
    }
    formData.value.company = "CarEager Xpress Gurugram";
    formData.value.source = "Website";
    this._applicationservice.generateLead(formData).subscribe(
      (_res) => {

      },
      (err) => {

      }
    );
  };
  getTabProperty() {
    this.changeserviceText = "Periodic Maintenance";
    this.changeservicePrice = 2999;
    this.isdetailing = false;
    this.latestImage = "assets/images/bmw-service/Periodic service.jpg";
    this.shorttabtitle = [
      "Periodic Service",
      "AC Service",
      "Computerised Scanning",
      "Suspension",
    ];
  }



  getShortTabProperty(shortTabId: number) {
    console.log('sdsdsds', shortTabId)
    if (shortTabId) {
      this.shorttabtitle = [
        "Periodic Service",
        "AC Service",
        "Computerised Scanning",
        "Suspension",
      ];
      if (shortTabId == 1) {
        this.changeserviceText = "Periodic Maintenance";
        this.changeservicePrice = 2999;
        this.latestImage = "assets/images/service/periodicservice.jpg";
      }
      if (shortTabId == 4) {
        this.changeserviceText = "Suspension / Shocker Mount";
        this.changeservicePrice = 6760;
        this.latestImage = "assets/images/service/tyresandsuspension.jpg";
      }
      if (shortTabId == 2) {
        this.changeserviceText = "AC Service";
        this.changeservicePrice = 676;
        this.latestImage = "assets/images/service/acservice.jpg";
      }
      if (shortTabId == 3) {
        this.changeserviceText = "Computerised Scanning";
        this.changeservicePrice = 384;
        this.latestImage = "assets/images/service/computrizedbodyrepair.jpg";
      }
    }

  };

  addTocart = (data: any) => {


    if (this.cartService.cartData && this.cartService.cartData.length) {

      this.cartData = this.cartService.cartData
      this.cartData.push(data);

      //this.cartService.cartData.push(data)
    } else if (this.cartService.cartData && !this.cartService.cartData.length) {

      this.cartData = []
      this.cartData.push(data);
      this.cartService.cartData.push(data)
    } else {
      this.cartData.push(data);
      this.cartService.cartData = this.cartData
    }

    this._applicationservice.saveDataInCart(this.cartData)
      .subscribe(res => {

        if (res.responseCode == "200") {
          this.cartService.cartTotal = res.total
          // this.cartService.cartData = res.cart.services

        }
      })
    this.checkItemInCart();
    document.getElementById("addToCart").click();
  };

  checkItemInCart = () => {

    this.cartData.forEach((element) => {
      this.servicesArray.forEach((ser: any) => {
        ser.services.forEach((elements) => {
          if (elements && element) {
            if (elements.id == element.id) {

              elements.isCart = true;
            }
          }

        });
      });
    });
    this.cartService.serviceArray = this.servicesArray

  };





}
