<div *ngIf="isLoader" class="cg-loader">
  <div><img style="width: 24rem;" src="../../../assets/images/careager-loader.gif" ></div>
</div>
<!-- <div class="service-tab">
  <div class="container-service-page container-fluid">
    <div *ngIf="!isFooter" class="mySlides" style="display: block">
      <div class="numbertext">
          <h2 class="service-heading unselectable"> {{changeserviceText}}</h2>
          <p class="service-price unselectable">Starts at just ₹{{changeservicePrice}}</p>
          <a (click)="navigateToSection()"  class="check-now">Check Now</a>          
      </div>
     <app-request-call-back [currentTitle]="'Get 50% OFF On First Car Service'"></app-request-call-back>
     <img [src]="latestImage" style="width: 100%" /> 

      
    </div>
    <div *ngIf="isFooter" class="mySlides" style="display: block">
      <div class="numbertext">
        
      </div>
      <img [src]="latestImage" style="width: 100%" /> 
    

      
    </div>
   

    <div   class="row-1 " style="margin-top: -50px;">  
    
      <div class="column cursor  service_tab unselectable" 
      *ngFor="let item of shorttabtitle ; let i =index"
       (click)="getShortTabProperty(i+1)">
       
          <div class="tab-caption">
         {{item}}
        </div>
      </div>
   
    
    </div>
  </div> -->
  
   <!-- For Mobile width -->
   <!-- <div class="container" style="z-index: 100;" *ngIf="deviceWidth <= 600">
    <div class="row">
      <div class="col-xl-12">
       <app-request-call-back [currentTitle]="'Get 50% OFF On First Car Service'"></app-request-call-back>
      </div>
    </div>
  </div> -->
  <div class="service-tab">
    <div class="container-service-page container-fluid">
      <div  class="mySlides" style="display: block">
        <div class="numbertext">
          <h2
            class="service-heading unselectable"
            
          >
          <span class="bannercolor3">Why choose CarEager for car</span> <br /> <span class="bannercolor">Service & Repairs</span><span class="bannercolor2">?</span>
        </h2>
        </div>
        <app-request-call-back
          [currentTitle]="'<span class='+'welcomoffer'+'>Welcome Offer!</span> <br /> <span class='+'abc'+'>Flat ₹1000 OFF On Your First CAR Service</span>'"
        ></app-request-call-back>
        <img src="assets/images/bmw-service/Periodic service.jpg" style="width: 100%" />
      </div>
  
    </div>
  </div>
  <div class="servicemobilebanner">
    <div>
      <img src="../../../assets/images/service/sbmobile1.jpg" alt="" srcset="">
      <div class="servicemobilecaption">
        <span style="color: #fff; font-size: 26px;">Why choose CarEager for car</span><br> <span style="color: #ff0000; font-size: 40px;">Service & Repairs</span><span style="color: #fff; font-size: 30px;">?</span>
      </div>
    </div>
  </div>
  <!-- For Mobile width -->
  <div class="container" style="z-index: 100" *ngIf="deviceWidth <= 600">
    <div class="row">
      <div class="col-xl-12">
        <app-request-call-back
        [currentTitle]="'<span class='+'welcomoffer'+'>Welcome Offer!</span> <br /> <span class='+'abc'+'>Flat ₹1000 OFF On Your First CAR Service</span>'"
        ></app-request-call-back>
      </div>
    </div>
  </div>
  
  <div class="ceramic-main">
    <div class="ceramic-coating-bg">
      <div class="container">
        <div class="row ceramic-coating-midel">
          <div class="col-sm-6 col-sm-push-6 left-section">
            <h3>Made In Germany Oil</h3>
            <p class="poweredimage">
              Approved by
              <img
                style="width: 240px ;margin-left: -10px;"
                src="assets/images/service/log_oof_car.png"
                alt=""
              />
            </p>
            <br />
            <p class="powermobiletext">
              <span class="servicessecond2">Why CarEager uses only</span><Br />
              <span class="servicessecond3">synthetic oil?</span>
            </p>
            <p style="color: #fff">• Improved Engine Protection</p>
            <p style="color: #fff">• Better Fuel Economy</p>
            <p style="color: #fff">• Extended Oil Change Intervals</p>
            <p style="color: #fff">• High Performance in Extreme Temperatures</p>
          </div>
          <div class="col-sm-6 col-sm-pull-6 right-section">
            <img
              draggable="false"
              class="hidden-xs"
              src="assets/images/service/serviceSection2.png"
            />
            <img class="hidden-sm hidden-md hidden-lg" src="assets/images/service/eserviceSection21.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="chemical-protection servicemobilemargin">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-push-6 left-section ">
            <div class="mobile-content">
              <h3>Genuine Parts</h3>
              <p>
                Using only genuine OEM/OES parts for your car ensures
                high-quality, compatibility, warranty coverage, and longevity.
                These parts are designed and manufactured to the exact
                specifications of the vehicle, ensuring that they fit and function
                properly with the other components of the vehicle.<br /><br /> Duplicate parts
                need to be replaced more frequently, resulting in more expenses in
                the long run. Therefore, choosing to use only genuine OEM/OES
                parts can help ensure the safety, reliability, and longevity of
                your car.
              </p>
            </div>
            <!-- <img draggable="false" src="assets/images/cermic/uncemical.png"> -->
          </div>
          <div class="col-md-6 col-sm-pull-6 right-section">
            <div class="desktop-content">
              <h3>Genuine Parts</h3>
              <p>
                Using only genuine OEM/OES parts for your car ensures
                high-quality, compatibility, warranty coverage, and longevity.
                These parts are designed and manufactured to the exact
                specifications of the vehicle, ensuring that they fit and function
                properly with the other components of the vehicle. <br /><br /> Duplicate parts
                need to be replaced more frequently, resulting in more expenses in
                the long run. Therefore, choosing to use only genuine OEM/OES
                parts can help ensure the safety, reliability, and longevity of
                your car.
              </p>
            </div>
          </div>
          <img
            class="bootm"
            draggable="false"
            src="assets/images/service/servicesectio3.jpg"
          />
        </div>
      </div>
    </div>
    <div class="chemical-protection chemical-protection2 httcar">
      <img style="width: 100%;" class="hidden-sm hidden-md hidden-lg" src="assets/images/service/servicesection4mob.jpg" alt="" srcset="">
      <div class="container">
        <div class="row">
  
          <div class="col-md-6 col-sm-push-6 left-section">
            <div class="mobile-content">
              <h3>Highly Trained Technicians</h3>
              <p>
                Having highly trained technicians with severals years of
                experience work on your car provide several benefits, including
                <span style="color: #ff0000"
                  >accurate diagnoses, proper repairs</span
                >, preventative maintenance, and safety. <br /><br />
                Repairing systems like
                <span style="color: #ff0000"
                  >automatic transmissions, W or V engines, electronic
                  circuits</span
                >
                & modules in cars such as Audi, Mercedes, and BMW requires
                specialized knowledge, equipment and expertise due to the
                complexity.
              </p>
            </div>
            <!-- <img draggable="false" src="assets/images/cermic/uncemical.png"> -->
          </div>
          <div class="col-md-6 col-sm-pull-6 right-section">
            <div class="desktop-content">
              <h3>Highly Trained Technicians</h3>
              <p>
                Having highly trained technicians with severals years of
                experience work on your car provide several benefits, including
                <span style="color: #ff0000"
                  >accurate diagnoses, proper repairs</span
                >, preventative maintenance, and safety. <br /><br />
                Repairing systems like
                <span style="color: #ff0000"
                  >automatic transmissions, W or V engines, electronic
                  circuits</span
                >
                & modules in cars such as Audi, Mercedes, and BMW requires
                specialized knowledge, equipment and expertise due to the
                complexity.
              </p>
            </div>
          </div>
          <!-- <img class="bootm" draggable="false" src="assets/images/service/servicesection4.jpg"> -->
        </div>
      </div>
    </div>
    <div class="container">
      <div class="serviceachiv">
        <div>
          <img src="assets/images/service/carborundum-1.png" alt="" srcset="" />
        </div>
        <div class="why-title row ceramic-coating-midel">
          <div class="col-md-12">
            <h3><span>30+</span> Years of Experience</h3>
            <p>
              Our decades of industry experience provides knowledge, expertise,
              efficient and effective repairs,
              <span style="color: #ff0000"
                >preventative maintenance, strong relationships</span
              >
              with parts suppliers, and quality car service. We have a proven
              track record of providing excellent services.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="why-title row ceramic-coating-midel">
        <div class="col-md-12">
          <h3>State-of-the-art <span>workshops</span></h3>
          <p>
            With advanced Diagnostic Equipment, Skilled Technicians, Specialised
            Tools and Equipment, Efficient Workflow, and Customer Amenities.
          </p>
          <img draggable="false" src="assets/images/service/carborundum-1.jpg" />
        </div>
      </div>
    </div>
  
    <section class="main-home">
      <div _ngcontent-serverapp-c6="" class="home-rating home-container">
        <div _ngcontent-serverapp-c6="" class="ratingcenter">
          
          <div
            _ngcontent-serverapp-c6=""
            class="rating wow fadeInUp"
            data="0.9s"
            data-wow-duration="1s"
          >
            <i _ngcontent-serverapp-c6="" class="glyphicon glyphicon-star"></i
            ><i _ngcontent-serverapp-c6="" class="glyphicon glyphicon-star"></i
            ><i _ngcontent-serverapp-c6="" class="glyphicon glyphicon-star"></i
            ><i _ngcontent-serverapp-c6="" class="glyphicon glyphicon-star"></i
            ><i
              _ngcontent-serverapp-c6=""
              class="glyphicon glyphicon-star half"
            ></i
            ><i _ngcontent-serverapp-c6="" class="rating-point">4.9/5</i>
          </div>
          <div _ngcontent-serverapp-c6="" class="rting-platform">
            <a
              _ngcontent-serverapp-c6=""
              class="wow fadeInUp"
              data="1s"
              data-wow-duration="1s"
              href=" https://g.page/CarEager/review?gm"
              target="_blank"
              ><img
                _ngcontent-serverapp-c6=""
                alt=""
                class="google"
                src="assets/images/rating-1.webp" /></a
            ><a
              _ngcontent-serverapp-c6=""
              class="wow fadeInUp"
              data="1s"
              data-wow-duration="1s"
              href="https://www.justdial.com/Delhi/CarEager-Gurgaon-Sector-35/011PXX11-XX11-170928171818-N4W5_BZDET"
              target="_blank"
              ><img
                _ngcontent-serverapp-c6=""
                alt=""
                class="justdial"
                src="assets/images/rating-3.webp" /></a
            ><a
              _ngcontent-serverapp-c6=""
              class="wow fadeInUp"
              data="1s"
              data-wow-duration="1s"
              href="https://goo.gl/fU5Upb  "
              target="_blank"
              ><img
                _ngcontent-serverapp-c6=""
                alt=""
                class="playstore"
                src="assets/images/rating-2.webp" /></a
            >
          </div>
          <p
            _ngcontent-serverapp-c6=""
            class="para-heading wow fadeInUp hidden-xs"
            data="0.5s"
            data-wow-duration="1s"
          >
          Our exceptional customer service, high-quality work, and a <br />
          strong reputation are evident across the auto industry
          </p>
          <p
            _ngcontent-serverapp-c6=""
            class="para-heading wow fadeInUp hidden-lg hidden-md hidden-sm"
            data="0.5s"
            data-wow-duration="1s"
          >
          Our exceptional customer service,<br /> high-quality work, and a 
          strong  reputation<br /> are evident across the auto industry
          </p>
        </div>
      </div>
    </section>
  </div>
  
<div  *ngIf="showDyanmicContent" class="mob-service-tab" >
      <div class="breaks sevice-container" *ngFor="let serviceData of servicesArray; let i= index">
     
        <h2 class="breaks-heading unselectable" >{{serviceData.package}}</h2>

      <div class="break-outer wow fadeInUp" data-wow-duration="1s" *ngFor="let servicepart of serviceData.services;let i=index;" >
          <div class="break-inner">
            <div class="break-text-div">
            <div class="">
              <p class="break-inner-heading unselectable" >{{servicepart.service}}</p>
              <p class="tick-text unselectable" *ngFor="let des of servicepart.description.split(',') | slice:0:3; let i=index"> <img src="assets/images/break-img.webp" alt="">{{des}}</p>
              
             
            </div>
            <div style="position: relative;"> <p class="tick-text cursor unselectable"  *ngIf="servicepart.description.split(',').length > 3" (click)="open('viweMore',servicepart)"> + {{servicepart.description.split(',').length - 3}} more items</p></div>
          </div>
              <div class="break-price">
                  <span style="display:none" class="offered-price unselectable">₹6499</span>
                  <span style="display:none" class="original-price unselectable">₹7000</span>
                  <br>
                  <a href="#" style="display:none" class="add-to-cart ">add to cart</a>
                  <a href="#" style="display:none"  class="added-cart">added</a>
                  <a href="javascript:void(0)"  data-toggle="modal" data-target="#check-price"  class="added-cart"> Check Price</a>
                
              </div>
              <!-- <div class="break-img">
                  <img [src]= "servicepart.profile" alt="">
              </div> -->
          </div>
          
      </div>

  </div>
</div>


<div #structure></div>
<div  *ngIf="!showDyanmicContent" >
  <div  class="breaks sevice-container" *ngFor="let serviceData of servicesArray; let i= index">
     <section   id="service_nav">

    <h2 class="breaks-heading unselectable" >{{serviceData.package}}</h2>

  <div class="break-outer wow fadeInUp" data-wow-duration="1s" *ngFor="let servicepart of serviceData.services;let i=index;">
      <div class="break-inner">
        <div class="break-text-div">
        <div class="">
          <p class="break-inner-heading unselectable" >{{servicepart.service}}</p>
          <p class="tick-text unselectable" *ngFor="let des of servicepart.description.split(',') | slice:0:3; let i=index"> <img src="assets/images/break-img.webp" alt="">{{des}}</p>
          
         
        </div>
        <div style="position: relative;"> <p class="tick-text cursor unselectable"  *ngIf="servicepart.description.split(',').length > 3" (click)="open('viweMore',servicepart)"> + {{servicepart.description.split(',').length - 3}} more items</p></div>
      </div>
          <div class="break-price">
              <span class="offered-price unselectable">₹{{servicepart.cost}}</span>
              <span  class="original-price unselectable">₹{{servicepart.mrp}}</span>
              <br>
              <a class="add-to-cart" *ngIf="!servicepart.isCart" (click)="addTocart(servicepart)">add to cart</a>
            
              <a href="javascript:void(0)"  *ngIf="servicepart.isCart" class="added-cart">added</a>
             
            
          </div>
      
          <!-- <div class="break-img">
              <img [src]="servicepart.profile" alt="">
          </div> -->
      </div>
      
  </div>

  <a id="addToCart" data-toggle="modal" data-target="#cart" [hidden]="true" data-dismiss="modal">add to cart</a>
   </section>
</div>
</div>

<!-- Cart -->

<!-- <jw-modal id="viweMore">
<div  class="price-modal moreItems" >
  <div class="modal-dialog modal-custom">
    <div class="modal-content modal-color">
      <div class="modal-header">
        <button type="button" class="close" (click)="closeModal('viweMore');">
          <img src="assets/images/Close.webp" alt="" />
        </button>
        <h4 class="modal-title unselectable">{{descriptionArray?.service}}</h4>
      </div>
      <div class="modal-body body-color unselectable">
        <p class="tick-text unselectable" *ngFor="let des of descriptionArray?.description.split(',');let i=index;"><img alt="" src="assets/images/break-img.webp" class="unselectable">{{des}}</p>
      
      </div>
    </div>
  </div>
  </div>
</jw-modal> -->

<app-bmwcommon-slider></app-bmwcommon-slider>

<div class="home-faq home-container">
  <div class="heading-block topmargin center">
    <h3 class="home-heading wow fadeInUp" data-wow-duration="1s" >Frequently Asked Questions</h3>
  </div>
  <div class="faq-main">
    <div class="panel-group" id="accordion">
      <div class="panel">
        <div class="panel-heading">
          <h4 class="panel-title">
            <a data-toggle="collapse" data-parent="#accordion" href="#collapse1" class="accordion wow fadeInUp" data-wow-duration="1s" ><span>Why should I choose Careager over other authorized service stations for BMW?</span></a>
          </h4>
        </div>
        <div id="collapse1" class="panel-collapse collapse">
          <div class="panel-body">We provide fixed prices (packages) upfront for the type of service required for your vehicle. An authorized service station will only provide a rough estimate of the costs and generate the invoice post service. The final expenses tend to be much higher than the estimate that can get quite expensive. </div>
          <div class="panel-body">We pick up and deliver your vehicle at your convenience.</div>
          <div class="panel-body">A full vehicle health check is provided on every service. Our workshops have state-of-the-art equipment and infrastructure that allows our experienced team of qualified technicians and mechanics to diagnose any problem efficiently.</div>
          <div class="panel-body">All our services can be availed online- from the process of booking to the delivery of the vehicle at your doorstep. </div>

        </div>
      </div>
      <div class="panel">
        <div class="panel-heading">
          <h4 class="panel-title">
            <a data-toggle="collapse" data-parent="#accordion" href="#collapse2" class="accordion wow fadeInUp" data-wow-duration="1s" ><span>2.	Is it possible to avail of service for BMW from Careager without physically going to the service center? </span></a>
          </h4>
        </div>
        <div id="collapse2" class="panel-collapse collapse">
          <div class="panel-body">Yes, you can avail of any service through our website. Following that, our team will pick up your BMW from the desired location and deliver it at your convenience. In the meantime, we provide regular updates regarding the progress of the repair. All online modes of payment are accepted. </div>
        </div>
      </div>
      <div class="panel">
        <div class="panel-heading">
          <h4 class="panel-title">
            <a data-toggle="collapse" data-parent="#accordion" href="#collapse3" class="accordion wow fadeInUp" data-wow-duration="1s" ><span>3.	Is there any warranty provided on services for BMW?</span></a>
          </h4>
        </div>
        <div id="collapse3" class="panel-collapse collapse">
          <div class="panel-body">For every service that we provide for your BMW, we issue a 12 months/10,000KM warranty whether you choose genuine or aftermarket parts. The cost of labor is covered under this warranty as well. Hence, you can opt for a hassle-free warranty policy from Careager. </div>
        </div>
      </div>
    </div>
    </div>
    <div class="faq-main">
      <div class="panel-group" id="accordion1">
        <div class="panel">
          <div class="panel-heading">
            <h4 class="panel-title">
              <a data-toggle="collapse" data-parent="#accordion1" href="#collapse4" class="accordion wow fadeInUp" data-wow-duration="1s" ><span>4.	It gets difficult for me to commute for work when my BMW is out for repairs. Will you help?</span></a>
            </h4>
          </div>
          <div id="collapse4" class="panel-collapse collapse">
            <div class="panel-body">Yes. At Careager, we place the utmost importance on customer experience. While our team is working on your BMW, we provide a courtesy car for you to commute.</div>
          </div>
        </div>
        <div class="panel">
          <div class="panel-heading">
            <h4 class="panel-title">
              <a data-toggle="collapse" data-parent="#accordion1" href="#collapse5" class="accordion wow fadeInUp" data-wow-duration="1s" ><span>Do you provide service to all models of BMW? </span></a>
            </h4>
          </div>
          <div id="collapse5" class="panel-collapse collapse">
            <div class="panel-body">We provide services to the following models of BMW:</div>
            <ul>
              <li>BMW X1</li>
              <li>BMW 3 series</li>
              <li>BMW 3 series GT</li>
              <li>BMW X3</li>
              <li>BMW 5 series</li>
              <li>BMW 6 series GT</li>
              <li>BMW X5</li>
              <li>BMW 6 series Gran Coupe</li>
              <li>BMW X6</li>
              <li>BMW 7 series</li>
              <li>BMW M5</li>
            </ul>
          </div>
        </div>
        <div class="panel">
          <div class="panel-heading">
            <h4 class="panel-title">
              <a data-toggle="collapse" data-parent="#accordion1" href="#collapse6" class="accordion wow fadeInUp" data-wow-duration="1s" ><span>Do you provide regular maintenance and roadside assistance for BMW?</span></a>
            </h4>
          </div>
          <div id="collapse6" class="panel-collapse collapse">
            <div class="panel-body">We have various fixed packages (according to budget) that include 25 point car diagnostics, fluid change/replacement, washing & cleaning, etc. In addition, we provide roadside assistance such as towing, jumpstart, and battery replacement when you are stuck in the middle of nowhere with your BMW. </div>
          </div>
        </div>
      </div>
      </div>
      <app-you-tube [videos]="videos"></app-you-tube>
</div>

<app-download-app></app-download-app>
<!-- this section used in Service page -->

<div class="container bottom-section-content">
  <div class="row">
      <div class="col-md-6 wow fadeInUp" data-wow-duration="1s">
          <h3>Save up to 40% on BMW service in Gurgaon with Complete Quality Assurance.</h3>
          <div class="content" style="text-align : justify">

              <p>Welcome to the Premium car service workshop in Gurgaon. CarEager is the best workshop for your BMW
                  and service without letting you leave your home or work. Our recovery driver will pick up your car
                  at your chosen time and location, service or repair it & deliver it back to you with 12 months parts
                  and labor warranty on any work carried out.
                  Service & repair any BMW with complete peace of mind.
                  We Collect - We Service - We Deliver.
              </p>
              <h3>BMW</h3>
              <p>At CarEager, our number one priority is customer safety, convenience and satisfaction. We Provide 12
                  Months/10,000 KM Warranty with every service for BMW whether you choose to buy genuine or
                  aftermarket parts from us, both come with a hassle-free warranty policy. The labor is also covered
                  under the warranty.

                  Every BMW service comes with a full vehicle health check. Our approved garages have the latest
                  equipment, allowing our qualified technicians and mechanics to diagnose any problems quickly and
                  efficiently.

                  BMW service and BMW repair can be very expensive with the main agencies that's why we are here to
                  make the service more affordable as well as making it hassle-free for you. Any BMW service Gurgaon,
                  Delhi NCR, comes with a free collection and delivery as well as parts and labour warranty.
              </p>
              <!-- Services of cars -->
              <h3 class="header">The Services Offered for BMW</h3>
              <p>We offer a wide variety of BMW services and repairs in the best authorised workshops and trained BMW
                  mechanics.</p>

              <!-- 1.) -->
              <h4 class="header">BMW General Service</h4>
              <p>Every car needs periodic maintenance for great health, so is your BMW. We have fixed packages for
                  regular car services that includes 25 point car diagnostics, engine oil change, filters replacement,
                  wash n clean and much more.</p>

              <!-- 2.) -->
              <h4 class="header">Car AC Service</h4>
              <p>We have customized BMW AC repair packages including AC gas top up, Cooling coil, Condenser, AC
                  Compressor, Blower Cleaning with disinfection.</p>

              <!-- 3.) -->
              <h4 class="header">BMW Repairs</h4>
              <p>Having issues with Brakes, Suspension, Clutch or Engine? We repair and use only OEMs at a price,
                  value and quality as compared to any other authorized car service station.</p>

              <!-- 4.) -->
              <h4 class="header">BMW Denting & Painting</h4>
              <p>Your BMW hates scratches and dents. Get your car denting painting done with US and German based
                  paints. We assure you 100% colour match as we have one of a kind repair system that makes sure that
                  the accuracy is perfect.</p>

          </div>
      </div>
      <div class="col-md-6 wow fadeInUp" data-wow-duration="1s">
          <div class="content" style="text-align : justify; margin-top: 20px;">
              <!-- 5.) -->
              <h4 class="header">Cashless Insurance Claims for BMW</h4>
              <p>Met with an accident? Don’t worry. CarEager is there to fix your BMW as it was before. We have
                  tie-ups with major insurance companies which will help in cashless and zero dep car insurance
                  claims.</p>

              <!-- 6.) -->
              <h4 class="header">BMW Rubbing & Polishing</h4>
              <p>Give your car a sparkling look with our car detailing package which includes wash and vacuum,
                  interior beautification and sanitisation, premium wax coating, anti-rust paint and Ceramic Shield (3
                  and 5 year warranty)</p>

              <!-- 7.) -->
              <h4 class="header">BMW Dry Cleaning</h4>
              <p>Refresh your car interiors by car dry cleaning and polishing of all interior carpets, roof, boot,
                  doors and dashboard.</p>

              <!-- 8.) -->
              <h4 class="header">BMW Complete Roadside Assistance</h4>
              <p>We provide Roadside Assistance services like car towing, jumpstart, battery replacement if you are
                  stuck in the middle of your journey.</p>

              <div class="modal-serve">
                  <h3 class="header">Models we Serve</h3>
                  <ul>
                      <li>BMW X1</li>
                      <li>BMW 3 Series</li>
                      <li>BMW 3 Series GT</li>
                      <li>BMW X3</li>
                      <li>BMW 5 Series</li>
                      <li>BMW 6 Series GT</li>
                      <li>BMW X5</li>
                      <li>BMW 6 Series Gran Coupe</li>
                      <li>BMW X6</li>
                      <li>BMW 7 Series</li>
                      <li>BMW M5</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>
