import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-call-action',
  templateUrl: './call-action.component.html',
  styleUrls: ['./call-action.component.css']
})
export class CallActionComponent implements OnInit {
  whatsappUrl = 'https://api.whatsapp.com/send?phone=+918710006161';
  callUrl = "tel:18008434300";

  constructor() { }

  ngOnInit(): void {
  }

}
