<div *ngIf="isLoader" class="cg-loader">
    <div><img style="width: 24rem;" src="../../../assets/images/careager-loader.gif"></div>
</div>




<section class="stretched ourStory">
    <div id="wrapper" class="clearfix">



        <section id="content" class="slider-element noMarginTop clearfix">
            <div class="slider-parallax-inner">
                <div class="swiper-container swiper-parent">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide dark">
                            <img class="heroImg" src="assets/images/storyMain.webp" style="width:100%" />
                            <div class="breadcrum wow fadeInUp" data-wow-duration="1s" data="0.5s">
                                <span>Home</span><span class="slash">/</span><span class="active">Story</span>
                            </div>
                            <div style="z-index:999;position:absolute;text-align:center;" class="storyHero">
                                <h2 class="wow fadeInUp" data-wow-duration="1s" data="0.6s">CarEager Story</h2>
                                <p class="d-none d-sm-block wow fadeInUp" data-wow-duration="1s" data="0.7s">30+ Years
                                    in the Automotive Industry</p>
                            </div>
                            <div class="overlay"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="storyBorders">
                <div class="circle"></div>
                <div class="borderRight"></div>
            </div>
        </section>

        <section id="content">
            <div class="container clearfix" style="text-align: -webkit-center;">
                <div class="borderRight"></div>
            </div>




            <div class="content-wrap" style="padding:0">
                <!-- write service code here -->
                <div class="container clearfix" style="margin-bottom:20px;padding: 0px 55px;">
                    <h2 class="storyHeading wow fadeInUp" data-wow-duration="1s" data="0.5s">It's all about passion &
                        vision</h2>
                    <div class="container clearfix" style="text-align: -webkit-center;">
                        <div class="borderRight" style="height:40px"></div>
                    </div>
                    
                    <!-- <div class="heading-block topmargin center">
                        <h3 style="margin-top: 0px!important" class="wow fadeInUp" data-wow-duration="1s" data="0.6s">
                            The Foundation</h3>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 foundation">
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.8s">
                                The story takes us back to the early 1980s when a young Vivek aspired to venture into
                                the automotive business. At that time, the roads were dominated by Ambassadors and Fiat
                                1100s. When Vivek approached his family for financial assistance, one of his brothers
                                suggested that he purchase some tools and sit on a street outside KMV College in
                                Jalandhar to earn money for his car workshop.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.9s">
                                Despite facing numerous obstacles and receiving no support from his family, Vivek worked
                                tirelessly and persisted in pursuing his dreams. He even sold taps and faucets to raise
                                funds for his startup during its early days. He epitomizes the perfect combination of
                                determination and perseverance.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="1s">
                                The business has come a long way since its humble beginnings. The company now owns
                                several large car workshops and has established a decades-long relationship with India's
                                largest car manufacturers. The name CarEager has become synonymous with high-quality car
                                services and has received numerous accolades.
                            </p>
                        </div>

                    </div> -->
                </div>
                <div class="container-fluid clearfix" style="margin-bottom:20px;">
                    <div class="row">
                       
                        <div class="col-lg-7 story">
                            <div class="heading-block topmargin" style="margin:0 0 30px !important">
                                <h3 style="margin-top: 0px!important" class="wow fadeInUp" data-wow-duration="1s"
                                    data="0.6s">The Foundation</h3>
                            </div>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.8s">
                                The story takes us back to the early 1980s when a young Vivek aspired to venture into
                                the automotive business. At that time, the roads were dominated by Ambassadors and Fiat
                                1100s. When Vivek approached his family for financial assistance, one of his brothers
                                suggested that he purchase some tools and sit on a street outside KMV College in
                                Jalandhar to earn money for his car workshop.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.9s">
                                Despite facing numerous obstacles and receiving no support from his family, Vivek worked
                                tirelessly and persisted in pursuing his dreams. He even sold taps and faucets to raise
                                funds for his startup during its early days. He epitomizes the perfect combination of
                                determination and perseverance.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="1s">
                                The business has come a long way since its humble beginnings. The company now owns
                                several large car workshops and has established a decades-long relationship with India's
                                largest car manufacturers. The name CarEager has become synonymous with high-quality car
                                services and has received numerous accolades.
                            </p>
                        </div>
                        <div class="col-lg-5" style="padding-right:0px; padding-left:0px;display: flex;">
                            <img src="assets/images/story/Foundation.jpeg" alt="" class="wow zoomIn"
                                data-wow-duration="1s" data="0.5s">
                        </div>
                    </div>
                </div>
                <div class="container clearfix wow fadeInUp" data-wow-duration="1s" data="0.5s"
                    style="padding-bottom:30px;text-align: -webkit-center;">
                    <div class="borderRight"></div>
                    <div class="circleStory"></div>
                    <div class="borderRight"></div>
                </div>
                <div class="container-fluid clearfix" style="margin-bottom:20px;">
                    <div class="row">
                        <div class="col-lg-5" style="padding-right:0px; padding-left:0px">
                            <img src="assets/images/story/2ndGen.png" alt="" class="wow zoomIn"
                                data-wow-duration="1s" data="0.5s">
                        </div>
                        <div class="col-lg-7 story">
                            <div class="heading-block topmargin" style="margin:0 0 30px !important">
                                <h3 style="margin-top: 0px!important" class="wow fadeInUp" data-wow-duration="1s"
                                    data="0.6s">Second Generation</h3>
                            </div>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.7s">
                                Born in 1991 in Amritsar amidst the violent and tumultuous era of the Punjab insurgency,
                                Avinay's family relocated to the peaceful state of Himachal Pradesh. From a young age,
                                Avinay closely observed his father's car repair business and recognised that there was a
                                lack of progress in the automotive sector. He noticed that car breakdowns left people
                                stranded in remote locations, and many repair shops took advantage of uninformed car
                                owners by charging exorbitant prices, often for services unrelated to the actual
                                problem.

                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.8s">
                                Determined to find a solution, Avinay envisioned a platform that would promote
                                transparency and fairness in the auto industry. He dreamed of a place where car owners
                                could access quality car services without being duped. However, his vision was ahead of
                                its time. Smartphones were yet to become popular, and internet connectivity was
                                intermittent and expensive.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container clearfix wow fadeInUp" data-wow-duration="1s" data="0.5s"
                    style="padding-bottom:30px;text-align: -webkit-center;">
                    <div class="borderRight"></div>
                    <div class="circleStory"></div>
                    <div class="borderRight"></div>
                </div>
                <div class="container-fluid clearfix" style="margin-bottom:20px;">
                    <div class="row">
                        <div class="col-lg-7 story">
                            <div class="heading-block topmargin" style="margin:0 0 30px !important">
                                <h3 style="margin-top: 0px!important" class="wow fadeInUp" data-wow-duration="1s"
                                    data="0.5s">The Name ‘CarEager’</h3>
                            </div>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.6s">
                                During his college years, Avinay devoted much of his time to studying entrepreneurship,
                                startups, and businesses. He attended business-related seminars across the country and
                                won numerous awards for his business modelling and entrepreneurial skills. He began
                                laying the groundwork for his future venture.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.7s">
                                In March 2014, he started brainstorming for the perfect name for his company. One day,
                                while returning from college, he came up with the name "CarEager," which beautifully
                                captured the essence of his business. "CarEager" embodied the company's DNA, his passion
                                for cars, and was easy to pronounce and understand globally. Additionally, the name had
                                meaning in Hindi. Without wasting any time, he purchased the domains and applied for
                                trademark registration.
                            </p>

                        </div>
                        <div class="col-lg-5" style="padding-right:0px; padding-left:0px">
                            <img class="wow zoomIn" data-wow-duration="1s" data="0.8s" src="assets/images/story/TheNameCarEager.jpeg"
                                alt="">
                        </div>
                    </div>
                </div>
                <div class="container clearfix wow fadeInUp" data-wow-duration="1s" data="0.5s"
                    style="padding-bottom:30px;text-align: -webkit-center;">
                    <div class="borderRight"></div>
                    <div class="circleStory"></div>
                    <div class="borderRight"></div>
                </div>
                <!-- <div class="container clearfix" style="margin-bottom:20px;padding: 0px 36px;">
                    <div class="heading-block topmargin center">
                        <h3 style="margin-top: 0px!important" class="wow fadeInUp" data-wow-duration="1s" data="0.5s">
                            The Co-Founders</h3>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 foundation">
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.6s">
                                After completing his bachelor's degree in Mechanical Engineering from Manipal
                                University, Avinay came to TCS Delhi for a Management Course.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.7s">
                                Avinay knew that in order to make an impact in the automotive service sector, an O2O
                                (online-to-offline) strategy would be the most scalable and successful approach. With
                                this in mind, he began actively seeking out tech-savvy co-founders who could help bring
                                his vision to life.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.8s">
                                On October 17, 2015, Avinay posted on the Delhi Startup Network’s Facebook Group seeking
                                co-founders for his venture. To his delight, a programmer brother-duo responded to his
                                post the very same day. From that point on, they started working remotely on developing
                                CarEager.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.9s">
                                In mid-2016, After completing his post-graduation in Project Management from New
                                Zealand's Unitec Institute of Technology, Avinay decided to gain some hands-on
                                management experience by joining a Gurugram-based startup. Even though he was working
                                there, he continued the development of CarEager remotely with his team.
                            </p>
                        </div>
                    </div>
                </div> -->
                <div class="container-fluid clearfix" style="margin-bottom:20px;">
                    <div class="row">
                        <div class="col-lg-5" style="padding-right:0px; padding-left:0px;display: flex;">
                            <img src="assets/images/story/TheCoFounders.jpeg" alt="">
                        </div>
                        <div class="col-lg-7 story">
                            <div class="heading-block topmargin" style="margin:0 0 30px !important">
                                <h3 style="margin-top: 0px!important" class="wow fadeInUp" data-wow-duration="1s"
                                    data="0.5s">The Co-Founders</h3>
                            </div>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.6s">
                                After completing his bachelor's degree in Mechanical Engineering from Manipal
                                University, Avinay came to TCS Delhi for a Management Course.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.7s">
                                Avinay knew that in order to make an impact in the automotive service sector, an O2O
                                (online-to-offline) strategy would be the most scalable and successful approach. With
                                this in mind, he began actively seeking out tech-savvy co-founders who could help bring
                                his vision to life.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.8s">
                                On October 17, 2015, Avinay posted on the Delhi Startup Network’s Facebook Group seeking
                                co-founders for his venture. To his delight, a programmer brother-duo responded to his
                                post the very same day. From that point on, they started working remotely on developing
                                CarEager.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.9s">
                                In mid-2016, After completing his post-graduation in Project Management from New
                                Zealand's Unitec Institute of Technology, Avinay decided to gain some hands-on
                                management experience by joining a Gurugram-based startup. Even though he was working
                                there, he continued the development of CarEager remotely with his team.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container clearfix wow fadeInUp" data-wow-duration="1s" data="0.5s"
                    style="padding-bottom:30px;text-align: -webkit-center;">
                    <div class="borderRight"></div>
                    <div class="circleStory"></div>
                    <div class="borderRight"></div>
                </div>
                <div class="container-fluid clearfix" style="margin-bottom:20px;">
                    <div class="row">
                        
                        <div class="col-lg-7 story">
                            <div class="heading-block topmargin" style="margin:0 0 30px !important">
                                <h3 style="margin-top: 0px!important" class="wow fadeInUp" data-wow-duration="1s"
                                    data="0.5s">The Road Trip</h3>
                            </div>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.6s">
                                On February 23, 2017, an incident occurred during Avinay's road trip with his friends
                                that would become a significant moment in the history of CarEager. While driving on the
                                Bikaner-Jaisalmer highway, their car suddenly broke down, leaving them stranded without
                                any nearby Skoda workshops to seek help from. Despite the setback, Avinay remained
                                determined to fix the car and salvage the trip. He contacted Skoda Helpline Number for
                                assistance, but unfortunately, there were no nearby workshops to help with the issue.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.7s">However, Avinay was resourceful
                                and decided to push the car to a truck repair shop a few kilometres away. After a few
                                hours of careful inspection, he was able to identify and rectify the issue with basic
                                tools, allowing the group to continue their journey. Although the situation could have
                                ruined the trip, Avinay's quick thinking and problem-solving skills ensured that the
                                group was able to overcome the challenge and continue their adventure. This incident was
                                a testament to the resilience and determination of CarEager's team members, and it
                                remains a significant event in the company's history.</p>
                        </div>
                        <div class="col-lg-5" style="padding-right:0px; padding-left:0px;display: flex;">
                            <img src="assets/images/story/TheRoadTrip.jpeg" alt="">
                        </div>
                    </div>
                </div>
                <div class="container clearfix wow fadeInUp" data-wow-duration="1s" data="0.5s"
                    style="padding-bottom:30px;text-align: -webkit-center;">
                    <div class="borderRight"></div>
                    <div class="circleStory"></div>
                    <div class="borderRight"></div>
                </div>
                <div class="container-fluid clearfix" style="margin-bottom:20px;">
                    <div class="row">
                        <div class="col-lg-5" style="padding-right:0px; padding-left:0px">
                            <img class="wow zoomIn" data-wow-duration="1s" data="0.9s" src="assets/images/story/TheLaunch.jpeg"
                                alt="">
                        </div>
                        <div class="col-lg-7 story">
                            <div class="heading-block topmargin" style="margin:0 0 30px !important">
                                <h3 style="margin-top: 0px!important" class="wow fadeInUp" data-wow-duration="1s"
                                    data="0.5s">The Launch</h3>
                            </div>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.6s">
                                After his eye-opening road trip to Rajasthan, Avinay was struck with the realization
                                that his long-held dream of creating a platform to revolutionize the automotive industry
                                had the potential to become a reality. Without hesitation, he resigned from his job upon
                                returning to Gurugram and shared his plans for CarEager with his family. His family
                                quickly recognized the potential and joined him on his mission.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.7s">
                                To validate their idea, the father-son duo visited 60-70 car workshops across Delhi NCR
                                as customers. However, they found that most workshops were trying to exploit customers
                                by creating unnecessary worries about imaginary problems.
                            </p>

                        </div>
                       
                    </div>
                </div>
                <div class="container clearfix wow fadeInUp" data-wow-duration="1s" data="0.5s"
                    style="padding-bottom:30px;text-align: -webkit-center;">
                    <div class="borderRight"></div>
                    <div class="circleStory"></div>
                    <div class="borderRight"></div>
                </div>
                <div class="container clearfix" style="margin-bottom:20px;padding: 0px 36px;">
                    <div class="row">
                        <div class="col-lg-12 foundation">
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.8s">
                                After Avinay's co-founders joined CarEager full-time, the team worked tirelessly to
                                launch India's most advanced car workshop in Gurugram in early 2019, laying the
                                foundation for the company's future success. Despite operating on a tight marketing
                                budget, CarEager quickly became one of the top-rated premium car service providers in
                                India, earning high ratings on Google, Justdial and Play Store.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.9s">Thanks to their dedication to
                                providing high-quality services and exceptional customer experiences, the company
                                continued to grow rapidly. This marked the beginning of an exciting new era for CarEager
                                and its vision to revolutionize the automotive industry. </p>
                        </div>
                    </div>
                </div>
                <div class="container clearfix wow fadeInUp" data-wow-duration="1s" data="0.5s"
                    style="padding-bottom:30px;text-align: -webkit-center;">
                    <div class="borderRight"></div>
                    <div class="circleStory"></div>
                    <div class="borderRight"></div>
                </div>
                <!-- <div class="container clearfix" style="margin-bottom:100px;padding: 0px 55px;">
                    <div class="heading-block topmargin center">
                        <h3 style="margin-top: 0px!important" class="wow fadeInUp" data-wow-duration="1s" data="0.5s">
                            It's A Revolution</h3>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 storybottom">
                            <p style="margin-bottom:0px" class="wow fadeInUp" data-wow-duration="1s" data="0.6s">
                                The idea is to provide world-class services & certified cars at the lowest prices
                            </p>
                            <p style="text-align:center" class="wow fadeInUp" data-wow-duration="1s" data="0.7s"><span
                                    class="span">so that anyone can afford his dream car and its maintenance</span></p>
                            <p style="text-align:center" class="wow fadeInUp" data-wow-duration="1s" data="0.8s"><span
                                    class="span">We at CarEager believe that this empowerment is not a minor disruption,
                                    but instead, a violent revolution. That’s why for us CarEager isn't a Company — It's
                                    a Revolution</span></p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.9s">
                                The CarEager team is possessed by a fiery ambition, with skill and experience to match.
                                And It’s just the beginning of the revolution
                            </p>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="container clearfix" style="margin-bottom:100px;padding: 0px 55px;">
                    <div class="heading-block topmargin center">
                        <h3 style="margin-top: 0px!important" class="wow fadeInUp" data-wow-duration="1s" data="0.5s">
                            Our journey has just begun </h3>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 foundation">
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.6s">
                                At CarEager, we are dedicated to providing the ultimate car services at fair prices, enabling everyone to afford world-class car services. We believe that this empowerment is not just a minor disruption but a violent revolution, and that's why CarEager is more than just a company - it's a revolution.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.7s">
                                Our team at CarEager is fueled by a fiery ambition, and we have the expertise and experience to make it happen. We are constantly pushing the boundaries and challenging the status quo to provide our customers with the best possible experience. 
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.8s">
                                As we continue to innovate, you'll see how CarEager seamlessly merges the physical and digital worlds of the auto industry. This is just the beginning of our revolution, and we can't wait to share it with you.
                            </p>
                        </div>
                    </div>
                </div> -->
                <div class="container-fluid clearfix" style="margin-bottom:20px;">
                    <div class="row">
                       
                        <div class="col-lg-7 story">
                            <div class="heading-block topmargin" style="margin:0 0 30px !important">
                                <h3 style="margin-top: 0px!important" class="wow fadeInUp" data-wow-duration="1s"
                                    data="0.5s">Our journey has just begun</h3>
                            </div>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.6s">
                                At CarEager, we are dedicated to providing the ultimate car services at fair prices, enabling everyone to afford world-class car services. We believe that this empowerment is not just a minor disruption but a violent revolution, and that's why CarEager is more than just a company - it's a revolution.
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.7s">
                                Our team at CarEager is fueled by a fiery ambition, and we have the expertise and experience to make it happen. We are constantly pushing the boundaries and challenging the status quo to provide our customers with the best possible experience. 
                            </p>
                            <p class="wow fadeInUp" data-wow-duration="1s" data="0.8s">
                                As we continue to innovate, you'll see how CarEager seamlessly merges the physical and digital worlds of the auto industry. This is just the beginning of our revolution, and we can't wait to share it with you.
                            </p>

                        </div>
                        <div class="col-lg-5" style="padding-right:0px; padding-left:0px">
                            <img class="wow zoomIn" data-wow-duration="1s" data="0.9s" src="assets/images/story/Ourjourneyhasjustbegun.jpeg"
                                alt="">
                        </div>
                       
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </section>
    </div>
</section>