import { FooterAComponent } from './../../@shared/footer-a/footer-a.component';
import { Toaster } from 'ngx-toast-notifications';
import { ModalService } from './../service/modalpopup/modal.service';
import { ApplicationService } from "./../../@backend/application.service";
import { Component, OnInit, Inject, PLATFORM_ID, ɵgetInjectableDef, ElementRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
//import { SessionStorage, LocalStorage } from 'ngx-store';
import { SharedService } from 'src/app/@service/shared.service';
import { CartServiceService } from '../../cart-service.service'
import { HttpParams } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { ThankuComponent } from 'src/app/@shared/thanku/thanku.component';
import { max } from 'moment';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { ChangeDetectorRef } from '@angular/core';
import { CanonicalTitleService } from 'src/app/@service/canonical-title.service';
declare var hljs: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  providers:[FooterAComponent]
})
export class HomeComponent implements OnInit {

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaResponse?: string;
  public captchaIsReady = false;
  // public readonly siteKey = "6LcvoUgUAAAAAJJbhcXvLn3KgG-pyULLusaU4mL1"
  public readonly siteKey = '6Ld-Y4EaAAAAAK_zu6GZSIaXQWQLLO5PAwrrRxtk';

  public badge = 'inline';
  public type = 'image';
  public theme = 'light';

  public recaptcha: any = null;
  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;

  appointmentForm: FormGroup;
  car: any;
  //@SessionStorage('car') car:string='';
 // @SessionStorage('cartData') cartData: Array<any>;
  hideWhatsApp: boolean = false;
  isName: boolean = false
  isContact: boolean = false
  isSubmit: boolean = false;
  isLoader: boolean = true

  constructor(private _carselection:SharedService,
    private router: Router,private SeoTitle:CanonicalTitleService,
    private cdr: ChangeDetectorRef,
    private formBuilder : FormBuilder,
    private metaTagService: Meta,
    private titleService: Title,
  private route: ActivatedRoute,
    private cartService: CartServiceService,
    @Inject(PLATFORM_ID) private platformId,
        private dialog  : MatDialog,
    private _applicationservice: ApplicationService,private modalService: ModalService,private toaster: Toaster,private footer:FooterAComponent) {
      
      this.titleService.setTitle("CarEager - The Ultimate Car Services");
      this.metaTagService.updateTag({
        name : 'Description',
        content : 'Book General Car Service, Car Repairs, Car Denting Painting, Car Detailing at affordable rates. Best Service Centers in Gurgaon, Delhi NCR, Noida'
      })
  
    }

  ngOnInit() {
    setTimeout(() => {
      this.isLoader = false
    }, 2500);
    this.SeoTitle.createLinkForCanonicalURL() 
    this.appointmentForm = this.formBuilder.group({
      recaptcha: ['', Validators.required],
      name : [''],
      contact_no : ['',],
      message : [''],
      company : ['CarEager Xpress Gurugram'],
      source : ['website'],
    })
    
   
    
    
   // this.metaTagService.removeTag('name="Honda Car Service & Repair Services | Auto Repair Services"');
   // this.metaTagService.removeTag('name="Insurance for Cars | Auto & Car Repair Insurance - CarEager"');
   // this.metaTagService.removeTag('name="Ford Car Service & Repair | Audi Car Careager Partner - CarEager"');
  
    this.isLoader = true
   this.car="common";
   this._carselection.changecarDetail({});
   //this.cartData = JSON.parse(localStorage.getItem("carts")) 

    if (window.innerWidth < 600){
      this.hideWhatsApp = true
    }

    let user = undefined

    if (isPlatformBrowser(this.platformId)) {
      user = JSON.parse(localStorage.getItem("user"))
    }


    if (user) {
      this.cartService.isCart = true
    }
    // window.scroll(0,0);
   
  }

  ngAfterViewInit() {
    //this.isLoader = false
    this.captchaIsLoaded = true;
    this.cdr.detectChanges();
    this.highlight();
   
  }




  // Captcha methods

  execute(): void {
    this.captchaElem.execute();
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.cdr.detectChanges();
  }

  handleReady(): void {
    this.captchaIsReady = true;
    this.cdr.detectChanges();
  }

  changeBadge(badge: 'bottomright' | 'bottomleft' | 'inline' = 'bottomright'): void {
    this.badge = badge;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert(`There is no response from grecaptcha script - try using 'getCurrentResponse' method or subscribe to 'success' event`);
    } else {
      alert(response);
    }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      alert('There is no current response - have you submitted captcha?');
    } else {
      alert(currentResponse);
    }
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }














  showToast(type,message,alert) {
    // const type = "success";
     this.toaster.open({
       text: message,
       caption: alert,
       type: type,
       position:'top-right',
       duration:6000
     });
   }
   open = (ModalId: string) => {
 
    
    this.modalService.open(ModalId);
  };
  closeModal(id: string) {
    this.modalService.close(id);
  }



  onSubmit = () => {
    this.isSubmit = true;
  
    this.isContact = false
    this.isName = false
    if (!this.appointmentForm.value.name) {
      this.isName = true
    } else if (!this.appointmentForm.value.contact_no) {
      this.isContact = true
    }
    if (this.appointmentForm.valid) {
      // return false;

      this._applicationservice.generateLead(this.appointmentForm.value).subscribe(
        (_res) => {
       
          if(_res.responseCode == 200){
            this.showToast('sucess','Thanku for Book an Appointment','Sucess')
          //  alert("thanku for Book an Appointment")
            this.router.navigate(['/successful']);
          }
        },
        (err) => {
         
        }
      );
    }    
  };


  requestCallback() {
     this.router.navigate(['/successful']);
    // setTimeout(()=>{
    //   this.router.navigate(['/']);
    // }, 3000)

    /*this.dialog.open(ThankuComponent, {
      width : '20%',
      height : '55%',
      panelClass : 'thank-class',
      disableClose : true,
      data : {
        url : '',
        name : 'Go To Home'
      }
    })*/


    /*let params = new HttpParams();
    params = params.append('home','123');
   */
   // this.router.navigate([], { relativeTo: this.route, queryParams: { 'a': 'b' }, queryParamsHandling: 'merge', skipLocationChange: true});


  }
}
