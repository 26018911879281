import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SharedService } from 'src/app/@service/shared.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CanonicalTitleService } from 'src/app/@service/canonical-title.service';


@Component({
  selector: 'app-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.css']
})
export class OfferDetailComponent implements OnInit {
  offerDetail:any={
    description0:"",
    offer_details:{},
    file_address:'',
    features_address: '',
    name: ''
  }

  constructor(public _shared:SharedService, private _router:Router,private SeoTitle:CanonicalTitleService) {
    this._shared.currentofferDetail.subscribe((_res:any)=>{
  
      const {description,offer_details,file_address, features_address, name} =_res;
      this.offerDetail.description=description;
      this.offerDetail.name = name
      this.offerDetail.features_address = features_address
      this.offerDetail.offer_details=offer_details;
      this.offerDetail.file_address=file_address;
    
      
     
    })
   }

   
   BookNow=()=>{
      this._router.navigate(['service'])
   }

  ngOnInit() {
    this.SeoTitle.createLinkForCanonicalURL() 
 //  window.scroll(0, 0)
  }
  offers: OwlOptions = {
    loop:false,
    margin:10,
    nav:true,
    navSpeed: 1300,
    autoplay:true,
    smartSpeed:1300,
    autoplaySpeed:1000,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    responsive:{
        0:{
            items:1
        },
        800:{
            items:1
        },
        1025:{
            items:1
        }
    }
  }

}
