import { LocalStorage, WebStorageModule } from "ngx-store";
import { SharedService } from "./../../@service/shared.service";
import { ApplicationService } from "../../@backend/application.service";
import { Component, OnInit } from "@angular/core";
//import { SessionStorage } from "ngx-store";
import {CartServiceService} from '../../cart-service.service';
import { Router } from "@angular/router";
// import $ from 'jquery';
import { DoCheck, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common'
declare var $ : any;
@Component({
  selector: "app-header-a",
  templateUrl: "./header-a.component.html",
  styleUrls: ["./header-a.component.css"],
})
export class HeaderAComponent implements OnInit, DoCheck {
 
menuHideshow:boolean;

  car: any
  showDyanmicContent: boolean = true;
  
  isCart: boolean;
  cartSize: any;

  headingStyle = {
    'display': 'none'
    }

  //@SessionStorage("carfrom") 
  carfrom: any = {};
  constructor(private data: SharedService,
    private cartService: CartServiceService,
    private _applicationService: ApplicationService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId) {}



  ngOnInit() {
  //  $(window).off('beforeunload');
  //  if (window.innerWidth > 500) {
  //   this.headingStyle.display = "block"
  //  } else {
  //    this.headingStyle.display = "none"
  //  }

   // mobile menu hide show
//    $(document).ready(function () {
//     $("nav").find("li").on("click", "a", function () {
//         $('.navbar-collapse.in').collapse('hide');
//     });
// });
   

  // console.log("header service calledd...", this.cartService.cartData)

   this._applicationService.getCartData()
   .subscribe(res => {
     if (res.responseCode == "200") {
      this.isCart = true
      this.cartService.cartTotal =  res.total
      this.cartSize = this.cartService.cartTotal
     } else {
       this.isCart = false
     }
    
   })


   
   // console.log("window inner width......", this.showDyanmicContent)

   let item = undefined
   if (isPlatformBrowser(PLATFORM_ID)) {
    item = JSON.parse(localStorage.getItem("carts"))
   }

    this.data.currentMessage.subscribe((_res: any) => {
      if (_res.responseCode !== 0 && _res.responseCode !== 400) {
        this.data.currentcarModel.subscribe((_res: any) => {
          this.showDyanmicContent = false;
          // this.cartData.length=0;
        });
      }
    });}


   
    ngDoCheck() {
      //console.log("Do chcek is calling....")
      this.cartSize =  this.cartService.cartTotal
      this.isCart = this.cartService.isCart
    }
    
    // home() {
    //   this.router.navigate(['/'])
    // }

    // story() {
    //   this.router.navigate(["/our-story"])
    // }

    // offers () {
    //   this.router.navigate(["/offers"])
    // }

    // gallery() {
    //   this.router.navigate(["/gallery"])
    // }

  navigateTo() {
    //let item = JSON.parse(localStorage.getItem("carts"))
    //this.cartData = item
    //console.log("Items json...", item)
    this.car = "";
    let cars = {
      type: "header",
      car: "path",
    };
    this.carfrom=cars;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem("carfrom" ,JSON.stringify(this.carfrom))
     }


    this.data.changecarDetail(cars);
    // this.data.changecarDetail({});
    this.router.navigate(["/service"])
    localStorage.setItem("setServiceUrl", "")
    this.data.setAllCarUrl("service")
    
  }
  // setcarUrl(url:any){
  //   this.data.setAllCarUrl(url)
  // }



  

  logout = () => {
    localStorage.removeItem("registration")
    localStorage.removeItem("user")
    localStorage.removeItem("userCar")
    localStorage.removeItem("token")
    this.cartService.cartTotal = 0
    this.cartService.cartData = []
    this.cartService.serviceArray = []
    this.isCart = false
    this.cartService.isCart = false
    this.router.navigate(['/'])
  }



}
