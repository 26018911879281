<!-- <nav class="add-bg desktop-menu">
  <div id="logo">
    <img src="assets/images/logo.webp" alt="" routerLink="/" style="cursor: pointer;" />
  </div>

  <label (click)="hideMenu()" for="drop" class="toggle mob-menu">
    <img src="assets/images/manu.webp" alt="" />
  </label>
  <input type="checkbox" id="drop" />
  <ul (click)="hideMenu()" class="menu">
    <li routerLinkActive="active"><a (click)="home()" style="cursor: pointer;">Home</a> </li>
    <li routerLinkActive><a style="cursor: pointer;" href="javascript:void(0);" (click)="navigateTo()">Services </a>
      <input type="checkbox" id="drop-1" />
    </li>
    <li><a href="#" routerLink="/our-story" (click)="story()">Our Story</a></li>
    <li><a href="#" routerLink="/gallery" (click)="gallery()">Gallery</a></li>
    <li><a style="cursor: pointer;" (click)="offers()">Offers</a></li>
    <li><a href="https://www.careager.com/blog">Blog</a></li>
    <li *ngIf="isCart"><a href="#" data-toggle="modal" data-target="#cart"><i
          class="fas fa-shopping-cart"></i>({{cartSize}})</a></li>
    <li *ngIf="!isCart"><a href="javascript:void(0)" data-toggle="modal" data-target="#check-price">Prices</a></li>
    <li *ngIf="isCart" class="dropdown-toggle" id="dropdownMenuButton" style=" color: white;" (click)="logout()">
      <button class="btn btn-danger logout-btn"> Log Out</button>
    </li>
  </ul>
</nav>
--->



<nav class="navbar navbar-inverse add-bg" role="navigation">
  <div class="container-fluid d-block">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1"> 
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>

      </button> 
      <div id="logo">
        <img src="assets/images/logo.webp" alt="" routerLink="/" style="cursor: pointer;" />
      </div>

    </div>
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
      <input type="checkbox" id="drop" />
      <ul data-toggle="collapse" data-target=".in" class="nav navbar-nav navbar-right d-block">
        <li><a routerLink="/service" style="cursor: pointer;">Services</a> </li>
        <li><a routerLink="/service/car-denting-painting" style="cursor: pointer;">Painting</a> </li>
        <li><a routerLink="/service/ceramic-coating" style="cursor: pointer;">Detailing</a> </li>
        <li><a routerLink="/service/insurance" style="cursor: pointer;">Insurance</a> </li>
        <li class="manu-border-club"><a routerLink="/service/careagerclub" style="cursor: pointer;">Club</a> </li>
        <!-- <li><a  style="cursor: pointer;" href="javascript:void(0);" (click)="navigateTo()">Services </a>
          <input type="checkbox" id="drop-1" />
        </li> -->
        <li><a  style="cursor: pointer;" routerLink="/our-story">Our Story</a></li>
        <li ><a  style="cursor: pointer;" routerLink="/gallery">Gallery</a></li>
        <!-- <li><a   style="cursor: pointer;" routerLink="/offers">Offers</a></li> -->
        <li><a  href="https://www.careager.com/blog">Blog</a></li>
        <li *ngIf="isCart"><a  data-toggle="modal" data-target="#cart"><i
              class="fas fa-shopping-cart"></i>({{cartSize}})</a></li>
        <li *ngIf="!isCart"><a  href="javascript:void(0)" data-toggle="modal" data-target="#check-price">Prices</a></li>
        <li *ngIf="isCart" class="dropdown-toggle" id="dropdownMenuButton" style=" color: white;" (click)="logout()">
          <button class="btn btn-danger logout-btn"> Log Out</button>
        </li>
      </ul>
    </div>
  </div>
</nav>