import * as CryptoJS from 'crypto-js';
import { Injectable } from "@angular/core";

@Injectable()
export class Security {
	
	encryptSecretKey = "CarIKDFglG7qAZyv9W2tLgUOAKHFBGikZchEager"

	encryptData(data) {
		try {
			return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
		} catch (e) {
			return ''
		
		}
	}

	decryptData(data) {
		try {
			const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey)
			if (bytes.toString()) {
				return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
			}
			return data
		} catch (e) {
			return ''
			
		}
	}
}
