<div class="container" style=" margin : 6rem auto;">
    <div  class="main-wrapper">
    
        <div class="awesome-logo"><img  class= "image-awesome" src="assets/images/5a142d709c.webp" alt=""></div>
       <div style="margin-top: 21px;">
        <span class="thanku">Awesome!</span>
       </div>
        <!-- <h2 class="awesome">YOUR MESSAGE HAS BEEN SENT SUCCESSFULLY</h2> -->
        <p class="welcome-msg-for-model">Thanks for your Enquiry, we will contact you soon.</p> 
       <div style="text-align: -webkit-center;margin-top: 30px;">
        <div class="go-to-button" (click)="backToHome()"><span class="back-to-home">Back to Home</span></div>
       </div>
    </div>
</div>


