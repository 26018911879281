<!-- this section used in Service page -->


<div class="home-service second-service">
  <div class="container-fluid service-container" style="margin-bottom: 90px">
    <div class="heading-block topmargin center">
      <h3 class="home-heading wow fadeInUp" data-wow-duration="1s" data="0.5s">Why CarEager ?</h3>
    </div>

    <div class="row qualities">
      <div class="col-sm-3 col-xs-6 text-center padding">
        <div class="content1 wow zoomIn" data-wow-duration="1s" data="0.6s">
          <div>
            <img src="assets/images/highestquality.svg" alt="" />
          </div>
          <span> Highest Quality</span>
        </div>
      </div>
      <div class="col-sm-3 col-xs-6 text-center padding">
        <div class="content1 wow zoomIn" data-wow-duration="1s" data="0.7s">
          <div>
            <img src="assets/images/lowestprices.svg" alt="" />
          </div>
          <span>Lowest Prices</span>
        </div>
      </div>
      <div class="col-sm-3 col-xs-6 text-center padding">
        <div class="content1 wow zoomIn" data-wow-duration="1s" data="0.8s">
          <div>
            <img src="assets/images/genuineparts.svg" alt="" />
          </div>
          <span>Genuine Parts</span>
        </div>
      </div>
      
      <div class="col-sm-3 col-xs-6 text-center padding">
        <div class="content1 wow zoomIn" data-wow-duration="1s" data="0.9s">
          <div>
            <img src="assets/images/convenience.svg" alt="" />
          </div>
          <span>Convenience</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- this section used in home page -->

<div class="home-service main-sevice">
    <div class="container-fluid service-container" style="margin-bottom: 90px">
      <div class="heading-block topmargin center">
        <h3 class="home-heading wow fadeInUp" data-wow-duration="1s" data="0.5s">Our Services</h3>
      </div>
      <div class="row qualities">
      <owl-carousel-o [options]="serviceOptions">
        <ng-template carouselSlide>
          <div class="col-sm-12 text-center padding"  >
            <div class="content1 wow zoomIn" data-wow-duration="1s" data="0.6s" routerLink="/service/car-denting-painting">
              <div>
                <img src="assets/images/highestquality.svg" alt="" />
              </div>
              <span> Collision Repair</span>
            </div>
          </div>
        </ng-template> 
        <ng-template carouselSlide>
          <div class="col-sm-12 text-center padding"
        
        >
          <div class="content1 wow zoomIn" data-wow-duration="1s" data="0.7s" routerLink="/service"
          >
            <div>
              <img src="assets/images/lowestprices.svg" alt="" />
            </div>
            <span>Service</span>
          </div>
        </div>
        </ng-template> 
        <ng-template carouselSlide>
          <div class="col-sm-12 text-center padding"  >
            <div class="content1 wow zoomIn" data-wow-duration="1s" data="0.8s" routerLink="/service/car-detailing">
              <div>
                <img src="assets/images/genuineparts.svg" alt="" />
              </div>
              <span>Detailing</span>
            </div>
          </div>
        </ng-template>  

        <ng-template carouselSlide>
          <div class="col-sm-12 text-center padding" >
            <div class="content1 wow zoomIn" data-wow-duration="1s" data="1.1s" routerLink="/service/insurance">
              <div>
                <img src="assets/images/genuineparts.svg" alt="" />
              </div>
              <span>Insurance</span>
            </div>
          </div>
        </ng-template>  
      </owl-carousel-o>
    </div>
    </div>
  </div>
  <div class="servic-quality">
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <div class="item">
          <img src="assets/images/9HCeramicCoating.jpg" alt="" />
          <div class="quality-captions">
            <h2 class="wow fadeInUp">9H Ceramic Coating</h2>
            <p class="pp wow fadeInUp">
              German Coating For Mirror-like Gloss, Weather 
              Protection, Hydrophobic Effect, and Chemical 
              Resistance - Using Carborundum, one of the 
              hardest materials in existence
            </p>
            <p class="pp2 wow fadeInUp">Complete coating starts at <b class="pp3" style="color: white;font-size: 18px;font-weight: 500;">₹11999</b></p>
            <a class="tab-button wow fadeInUp" href="/service">View Services</a>
          </div>
        </div>
      </ng-template>  
      <ng-template carouselSlide>
        <div class="item">
          <img src="assets/images/careagercustomz.jpg" alt="" />
          <div class="quality-captions">
            <h2 class="wow fadeInUp">CarEager Customz</h2>
            <p class="pp wow fadeInUp">
              We synthesize your dreams and expectations
              along with our experience and creative Vein.
            </p>
            <p class="pp2 wow fadeInUp">Customization starts at  <b class="pp3" style="color: white;font-size: 18px;font-weight: 500;">₹3499</b></p>
            <a class="tab-button wow fadeInUp" href="/service">View Services</a>
          </div>
        </div>
      </ng-template>  
      <ng-template carouselSlide>
        <div class="item">
          <img src="assets/images/companyownedworkshop.jpg" alt="" />
          <div class="quality-captions">
            <h2 class="wow fadeInUp">Company-owned workshops</h2>
            <p class="pp wow fadeInUp">
              CarEager setups state-of-the-art workshops across
              Tier-1 cities to ensure the highest quality at the
              lowest price.
            </p>
            <p class="pp2 wow fadeInUp">Our 30 years of experience plays an important role. </p>
            <a class="tab-button wow fadeInUp" href="/service">View Services</a>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="item">
          <img src="assets/images/Paintingquality.jpg" alt="" />
          <div class="quality-captions">
            <h2 class="wow fadeInUp">Painting Quality</h2>
            <p class="pp wow fadeInUp">
              Most Advanced Paint Booths and
              German-American Paints enables us to offer flat 
              2-Year Warranty on every paint job 
            </p>
            <p class="pp2 wow fadeInUp">Painting starts at <b class="pp3" style="color: white;font-size: 18px;font-weight: 500;"> ₹1194/Panel</b></p>
            <a class="tab-button wow fadeInUp" href="/service">View Services</a>
          </div>
        </div>
      </ng-template>  
      <ng-template carouselSlide>
        <div class="item">
          <img src="assets/images/servicequality.jpg" alt="" />
          <div class="quality-captions">
            <h2 class="wow fadeInUp">Service Quality</h2>
            <p class="pp wow fadeInUp">
              If anyone with Comparable equipment,
              infrastructure & experience offers better
              prices in India, we'll do it for free.
            </p>
            <p class="pp2 wow fadeInUp">Full service starts at <b class="pp3" style="color: white;font-size: 18px;font-weight: 500;">₹2999</b></p>
            <a class="tab-button wow fadeInUp" href="/service">View Services</a>
          </div>
        </div>
      </ng-template>   
    </owl-carousel-o>
  </div>
  
