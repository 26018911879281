import { ModalService } from './../../@component/service/modalpopup/modal.service';
import { Toaster } from 'ngx-toast-notifications';
// import { ModalService } from './../service/modalpopup/modal.service';
import { ApplicationService } from "./../../@backend/application.service";
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ThankuComponent } from '../thanku/thanku.component';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
declare var hljs: any;

@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.css']
})
export class BookAppointmentComponent implements OnInit {

  appointmentForm: FormGroup;
  isSubmit: boolean = false;
  hideWhatsApp: boolean = false;

  isLoader: boolean = true
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaResponse?: string;
  public captchaIsReady = false;
  public readonly siteKey = '6Ld-Y4EaAAAAAK_zu6GZSIaXQWQLLO5PAwrrRxtk';

  public badge = 'inline';
  public type = 'image';
  public theme = 'light';

  public recaptcha: any = null;
  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;

  constructor(private _applicationservice: ApplicationService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,private toaster: Toaster,
    private formBuilder : FormBuilder,
    private dialog : MatDialog) {}

  ngOnInit() {
    // if (!localStorage.getItem('foo')) {
    //   localStorage.setItem('foo', 'no reload')
    //   location.reload()
    // } else {
    //   localStorage.removeItem('foo')
    // }

    this.appointmentForm = this.formBuilder.group({
      recaptcha: ['', Validators.required],
      name : [''],
      contact_no : [''],
      message : [''],
      company : ['CarEager Xpress Gurugram'],
      source : ['website'],
    })
   
    if (window.innerWidth < 600){
      this.hideWhatsApp = true
    }
  }
  ngAfterViewInit() {
    //this.isLoader = false
    this.captchaIsLoaded = true;
    this.cdr.detectChanges();
    this.highlight();
    setTimeout(() => {
      this.isLoader = false
    }, 3000);
  }
  // Captcha methods

  execute(): void {
    this.captchaElem.execute();
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.cdr.detectChanges();
  }

  handleReady(): void {
    this.captchaIsReady = true;
    this.cdr.detectChanges();
  }

  changeBadge(badge: 'bottomright' | 'bottomleft' | 'inline' = 'bottomright'): void {
    this.badge = badge;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert(`There is no response from grecaptcha script - try using 'getCurrentResponse' method or subscribe to 'success' event`);
    } else {
      alert(response);
    }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      alert('There is no current response - have you submitted captcha?');
    } else {
      alert(currentResponse);
    }
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }





  showToast(type,message,alert) {
    // const type = "success";
     this.toaster.open({
       text: message,
       caption: alert,
       type: type,
       position:'top-right',
       duration:6000
     });
   }
  onSubmit = () => {
  
   
    // this.isSubmit = true;
   
    if (this.appointmentForm.invalid) {
      return false;
    }
    this._applicationservice.generateLead(this.appointmentForm.value).subscribe(
      (_res) => {
    
        if(_res.responseCode == 200){
         // this.showToast('sucess','Thanku for Book an Appointment','Sucess')
        //  alert("thanku for Book an Appointment")
        this.router.navigate(['/successful']).
        then(() => {
          window.location.reload();
        })
        }
      },
      (err) => {
       
      }
    );
  };

  requestCallback() {
 
    let getUrl = window.location.href.split("/")
        if (getUrl[getUrl.length-2] == 'service') {          
            
            this.router.navigate(['/successful']).
            then(() => {
              window.location.reload();
            })
          
        } else {
       
          // this.router.navigate([getUrl[getUrl.length - 1], 'RequesCallbackSuccess']);
          this.router.navigate(['/successful']).
          then(() => {
            window.location.reload();
          })
          }
    /*let params = new HttpParams();
    params = params.append('home','123');
   */
   // this.router.navigate([], { relativeTo: this.route, queryParams: { 'a': 'b' }, queryParamsHandling: 'merge', skipLocationChange: true});
  }
}
