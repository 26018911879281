import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/@service/shared.service';
//import { SessionStorage } from 'ngx-store';

@Component({
  selector: 'app-service-tabs',
  templateUrl: './service-tabs.component.html',
  styleUrls: ['./service-tabs.component.css']
})
export class ServiceTabsComponent implements OnInit {
  //@SessionStorage('car') car:string='';
  car: string = '';
  isService:boolean=true;
  url;

  constructor(public router:ActivatedRoute, private carselection: SharedService) {  
    this.url=this.router.snapshot.url[0].path;
    console.log(' this.url top', this.url)
    // this.car=="" || this.car==null ? this.isService=true : this.isService=false;
    // this.isService;
   }

  ngOnInit() {
    localStorage.setItem("setServiceUrl", "")
    this.carselection.setAllCarUrl("service")
    this.carselection.getcarUrl.subscribe((response: any) => {
      if(response && localStorage.getItem('setServiceUrl')){
          let urlUpadte =localStorage.getItem('setServiceUrl');
          this.url=this.router.snapshot.url[0].path+'/'+urlUpadte;
          console.log(' this.url top', this.url)
      } else{
          let urlUpadte =localStorage.getItem('setServiceUrl');
          this.url=this.router.snapshot.url[0].path+'/'+urlUpadte;
      }
    })

  
  }



  // navigateToUrl(service) {

  //   let url  = window.location.href
  //   let length = url.split("/").length
  //   let urlArray = url.split("/")
  //   console.log('urlArray', urlArray)
  //   for (let i=0;i<length;i++) {
  //     if (urlArray[i] == 'bmw') {
     
  //       if (service == 'service') {
  //         this.url = "/" + service + "/bmw";
  //         console.log('url 1',  this.url)
  //        // this._router.navigate([this.url])
  //       } else {
  //         this.url = "/service/bmw/"+service
  //         console.log('url 2',  this.url)
  //        // this._router.navigate([this.url])
  //       }
  //      break;
  //     } else if (urlArray[i] == 'mercedes-benz') {
  //       if (service == 'service') {
  //         this.url = "/" + service + "/mercedes-benz"
  //         this._router.navigate([this.url])
  //       } else {
  //         this.url = '/service/mercedes-benz/'+service
       
  //         this._router.navigate([this.url])
  //       }
  //       break;

  //     } else if (urlArray[i] == "audi") {
  //       if (service == 'service') {
  //         this.url = "/" + service + "/audi"
  //         this._router.navigate([this.url])
  //       } else {
  //         this.url = '/service/audi/'+service
        

  //         this._router.navigate([this.url])
  //       }
  //       break;

  //     } else {
  //       if (service == 'service') {
  //         this.url = "/service"
  //         this._router.navigate([this.url])
  //       } else {
  //         this.url = '/service/'+service
          
  //         this._router.navigate([this.url])
  //       }
  //     }
  //   }
  // }

  // navigateTo=(path:string)=>{
  //   //debugger
  
  //   if(path!=='service'){

  //     if(this._router.url==`/service/${this.car}`)  {
     
  //       const fullpath=`../${path}/${this.car}`;
  //     this._router.navigate([`${fullpath}`],{ relativeTo: this.route })
  //     }else{
  //       const fullpath=`./${path}/${this.car}`;
  //       this._router.navigate([`${fullpath}`],{ relativeTo: this.route })
  //     }
  //   }else{
  //     const fullpath=`./${this.car}`;
  //     this._router.navigate([`${fullpath}`],{ relativeTo: this.route })
  //   }
    
  // }


 

}
