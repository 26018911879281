
    
<div *ngIf="false">
    <div class="service-tabs" *ngIf="isService">
        <!-- <a routerLink="/{{this.url}}" class="tab-inner wow zoomIn" data-wow-duration="1s" data="0.5s">
            <img src="assets/images/service-service.webp" alt="" />
            <p>Service</p>
        </a>
        <a routerLink="/{{this.url}}/car-denting-painting" class="tab-inner wow zoomIn" data-wow-duration="1s" data="0.6s">
            <img src="assets/images/service-denting.webp" alt="" />
            <p>Dent-paint</p>
        </a>
        <a routerLink="/{{this.url}}/car-detailing" class="tab-inner wow zoomIn" data-wow-duration="1s" data="0.7s">
            <img src="assets/images/service-detailing.webp" alt="" />
            <p>Detailing</p>
        </a>
        <a routerLink="/{{this.url}}/insurance-claim" class="tab-inner wow zoomIn" data-wow-duration="1s" data="0.8s" >
            <img src="assets/images/service-customize.webp" alt="" />
            <p>Insurance Claim</p>
        </a>
        <a routerLink="/service/ceramic-coating" class="tab-inner wow zoomIn" data-wow-duration="1s" data="0.6s">
            <img src="assets/images/Menu-COATING.png" alt="" />
            <p>Ceramic Coating</p>
        </a>
        <a routerLink="/service/careagerclub" class="tab-inner wow zoomIn" data-wow-duration="1s" data="0.9s">
            <img src="assets/images/service-club.webp" alt="" />
            <p>Club</p>
        </a>
        <a routerLink="/service/warranty" class="tab-inner wow zoomIn" data-wow-duration="1s" data="1s" >
            <img src="assets/images/service-warranty.webp" alt="" />
            <p>Warranty</p>
        </a>
        <a routerLink="/service/insurance" class="tab-inner wow zoomIn" data-wow-duration="1s" data="1.1s" >
            <img src="assets/images/service-insurance.webp" alt="" />
            <p>Insurance</p>
        </a> -->
    
    
    
    
     <!-- mobile tab -->
        <div class="mob-tab-outer">
        <div class="mob-tab-service wow zoomIn"
        style="background-image: url(../../../assets/images/mob-tab-img.webp);"
         data-wow-duration="1s" data="0.5s" routerLink="/{{this.url}}" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <div class="mob-tab-inner">
                <div class="mob-tab-text">
                    <img src="assets/images/service-careager-mob.webp" alt="">
                    <p class="mob-tab-heading">Service</p>
            
                </div>
            </div>
        </div>
        <div class="mob-tab-service wow zoomIn" 
        style="background-image: url(../../../assets/images/dent-paint-mob.jpg);"
        data-wow-duration="1s" data="0.6s" routerLink="/{{this.url}}/car-denting-painting" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <div class="mob-tab-inner">
                <div class="mob-tab-text">
                    <img src="assets/images/collision-careager-mob.webp" alt="">
                    <p class="mob-tab-heading">Dent-paint</p>
                   
                </div>
            </div>
        </div>
        <!-- <div class="mob-tab-service wow zoomIn"
        style="background-image: url(../../../assets/images/detailing-mob.jpg);"
         data-wow-duration="1s" data="0.5s"  routerLink="/{{this.url}}/car-detailing" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <div class="mob-tab-inner">
                <div class="mob-tab-text">
                    <img src="assets/images/detailing-careager-mob.webp" alt="">
                    <p class="mob-tab-heading">Detailing</p>
                    <p>Detailing</p>
                    <p>Starting at just ₹2999</p>
                </div>
            </div>
        </div> -->
        <div class="mob-tab-service wow zoomIn"
        style="background-image: url(../../../assets/images/customz-mob.jpg);"
         data-wow-duration="1s" data="0.5s" routerLink="/{{this.url}}/insurance-claim" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <div class="mob-tab-inner">
                <div class="mob-tab-text">
                    <img src="assets/images/customiz-careager-mob.webp" alt="">
                    <p class="mob-tab-heading">Insurance Claim</p>
                 
                 
                </div>
            </div>
        </div>
        <div class="mob-tab-service wow zoomIn"
        style="background-image: url(../../../assets/images/mob-tab-img.webp);"
         data-wow-duration="1s" data="0.5s" [routerLink]="['./ceramic-coating']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <div class="mob-tab-inner">
                <div class="mob-tab-text">
                    <img src="assets/images/Menu-COATING.png" alt="">
                    <p class="mob-tab-heading">Ceramic Coating</p>
                    
                </div>
            </div>
        </div>
        <div class="mob-tab-service wow zoomIn"
        style="background-image: url(../../../assets/images/car-eager-club-mob.jpg);"
         data-wow-duration="1s" data="0.5s" [routerLink]="['./careagerclub']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <div class="mob-tab-inner">
                <div class="mob-tab-text">
                    <img src="assets/images/service-club.webp" alt="">
                    <p class="mob-tab-heading">Club</p>
                    
                </div>
            </div>
        </div>
        <!-- <div class="mob-tab-service wow zoomIn" 
        style="background-image: url(../../../assets/images/warranty-mob.jpg);"
        data-wow-duration="1s" data="0.5s" [routerLink]="['./warranty']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <div class="mob-tab-inner">
                <div class="mob-tab-text">
                    <img src="assets/images/service-warranty.webp" alt="">
                    <p class="mob-tab-heading">Warranty</p>
                    <p>Warranty</p>
                    <p>Starting at just ₹2999</p>
                </div>
            </div>
        </div> -->
        <div class="mob-tab-service wow zoomIn"
        style="background-image: url(../../../assets/images/insurance-mob.jpg);"
         data-wow-duration="1s" data="0.5s" [routerLink]="['./insurance']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <div class="mob-tab-inner">
                <div class="mob-tab-text">
                    <img src="assets/images/service-insurance.webp" alt="">
                    <p class="mob-tab-heading">Insurance</p>
                   
                </div>
            </div>
        </div>
        </div>
    </div>
</div>

<!-- <div class="service-tabs" *ngIf="!isService">
    <a  routerLink="/service" class="tab-inner wow zoomIn" data-wow-duration="1s" data="0.5s">
        <img src="assets/images/service-service.webp" alt="" />
        <p>Service</p>
    </a>
    <a routerLink="{{this.url}}/car-denting-painting" class="tab-inner wow zoomIn" data-wow-duration="1s" data="0.6s">
        <img src="assets/images/service-denting.webp" alt="" />
        <span>Dent-paint</span>
    </a>
    <a routerLink="{{this.url}}/car-detailing" class="tab-inner wow zoomIn" data-wow-duration="1s" data="0.7s">
        <img src="assets/images/service-detailing.webp" alt="" />
        <p>Detailing</p>
    </a>
    <a routerLink="{{this.url}}/insurance-claim" class="tab-inner wow zoomIn" data-wow-duration="1s" data="0.8s" >
        <img src="assets/images/service-customize.webp" alt="" />
        <p>Insurance Claim</p>
    </a>
    <a routerLink="/service/careagerclub" class="tab-inner wow zoomIn" data-wow-duration="1s" data="0.9s">
        <img src="assets/images/service-club.webp" alt="" />
        <p>Club</p>
    </a>
    <a routerLink="/service/warranty" class="tab-inner wow zoomIn" data-wow-duration="1s" data="1s" >
        <img src="assets/images/service-warranty.webp" alt="" />
        <p>Warranty</p>
    </a>
    <a routerLink="/service/insurance" class="tab-inner wow zoomIn" data-wow-duration="1s" data="1.1s" >
        <img src="assets/images/service-insurance.webp" alt="" />
        <p>Insurance</p>
    </a>
    </div> -->
