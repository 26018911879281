<div class="footer-line">
    <div class="footer-top">
      <p class="wow fadeInUp" data-wow-duration="1s" >
        <span>1.5</span> million+<br />
        CARS SERVICED/repaired
      </p>
      <p class="wow fadeInUp" data-wow-duration="1s" ><span>30+</span> years OF <br />industry expertise</p>
      <p class="wow fadeInUp" data-wow-duration="1s" >
        <span>4.8</span> AVERAGE RATING<br />
        ON GOOGLE &amp; PLAY STORE
      </p>
      <p class="wow fadeInUp" data-wow-duration="1s" >
        <span>20+</span> INSURANCE<br />
        COMPANY TIEUPS
      </p>
    </div>
  </div>
  <div *ngIf="_carType=='common'" id="common" class="footer-text home-container">
    <div style="color: darkgray; margin-left: 2rem; margin-right: 2rem">
      <div class="row">
        <div class="col-md-6">
          <h5 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >CarEager</h5>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            One Place for all Your Car Needs Thank you for visiting our website.
            We will be pleased to help you by providing all sorts of car
            solutions. CarEager is a one stop solution for everything that your
            car really needs from periodic car services, car repairs, body work,
            denting painting, engine and transmission repairs, windscreen
            replacement, interior and exterior detailing and much more. We are
            the multi-brand car workshop in Gurgaon that has state of art
            machinery and equipment, handled by our experienced and trained
            technicians. We cater to all kinds of premium brands like Audi,
            Mercedes, BMW, Porsche, Jaguar, Tesla, Rolls Royce, Lamborghini etc
            as well as the popular brands like Ford, Maruti Suzuki, Honda,
            Hyundai, Tata, Skoda, VW and much more.
          </p>
        </div>
        <div class="col-md-6">
          <h5 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >Why choose us?</h5>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            We're leaders in the car care industry not only because we care
            about our customers, but because we're a team. From the front office
            to the garage, we're transparent when it comes to pricing, engine
            diagnostic services, transmission and engine repair, brake pads, and
            more.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h5 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >
            Note: We only use OEM and parts recommended by car manufacturers.
          </h5>
          <h6 class="wow fadeInUp" data-wow-duration="1s" data="1s">Save Your Time</h6>
          <p class="wow fadeInUp" data-wow-duration="1s" data="1.1s">
            You don’t have to wait for long to get your car repaired or serviced
            as we have a dedicated team who will start working together
            simultaneously. We have the facility of free pick n drop service as
            we know that our customer’s time is precious and if a customer is
            willing to visit our workshop, he will experience a whole new world
            at carEager.
          </p>
        </div>
        <div class="col-md-6">
          <h5 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >Save Money</h5>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            Our pricing and packages are always below the standard rates offered
            by other service centres and many of the services we offer are for
            free!
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h6 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >Location Convenience</h6>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            We can service your car anywhere in and around Gurgaon location. Our
            mechanics will come directly to your address to sort out the
            problems that you are facing and get that rectified at that point,
            if there is some serious issue, then we will get your car towed to
            aur workshop
          </p>
        </div>

        <div class="col-md-6">
          <h6 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >Certified Mechanicals</h6>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            Expert technicians will be on stand-by to give you quality service
            based on years of auto repair experience from major car brands like
            Audi, Mercedes Benz, BMW, Land Rover etc
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h6 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >Courtesy Car Facility</h6>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            At CarEager, we know that our customers have places that they need
            to go or urgent work that they have to do. While we work on your car
            be sure your life continues without any interruption. Just book one
            of our courtesy cars for that duration.
          </p>
        </div>

        <div class="col-md-6">
          <h5 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >Our Services</h5>
          <p class="wow fadeInUp" data-wow-duration="1s" >Below are some of the many car solutions that we offer:</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h6 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >Periodic Car services</h6>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            Your car needs maintenance and check up on a periodic basis to make
            sure that your car is in an immaculate condition. For that purpose,
            get the car service done from a reliable car garage or else see your
            car cry in pain. What does periodic car service includes
          </p>
        </div>

        <div class="col-md-6">
          <p class="wow fadeInUp" data-wow-duration="1s" >
            We are not like those car workshops or mechanics who refer to local
            garages to make your car serviced. We have fantastic infrastructure,
            with the latest machinery and equipment, guarded by mechanics. Our
            car workshops are equipped with the latest car scanners and
            electronic tools to diagnose the errors.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h5 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >Footer</h5>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            Engine oil flushing Engine oil replacement Coolant top-up Air filter
            replacement Oil filter replacement Brakes cleaning Heater plug
            checking Washer Fluid
          </p>
        </div>

        <div class="col-md-6">
          <h5 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >Footer</h5>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            Battery Water top-up Computerised Scanning 25 Point general
            inspection during Quality check. Wheel balancing and alignment Tyre
            rotation check Throttle body cleaning Air conditioning filter
            replacement. Wash and vacuum package
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h5 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >Car Denting Painting</h5>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            The way your car looks, shows your personality. One will never want
            to drive a car that is damaged with dents or scratches. So to make
            sure that your car is in an immaculate condition on the outside,
            CarEager is happy to help you. We have effective mechanisms as well
            as efficient mechanics, those who have an experience of 10+ years in
            the body shop, to measure and remove all sorts of dents and damages
            on your car.
          </p>
        </div>

        <div class="col-md-6">
          <h5 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >What all we provide</h5>
          <h6 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >
            Dent and Scratches Removal.
          </h6>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            Dents are removed using our specialized Dent Pullers then followed
            by the application of best in class and industry-standard
            body-fillers to level the car surface and give a smooth feel. Then,
            the impacted area is spray-painted in an advanced temperature and
            pressure controlled paint booth with base and clear coat followed by
            rubbing/polishing to bring back the damaged panels to their original
            shape, color and shine.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h6 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >Full Body Restoration</h6>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            Primarily, the complete car is inspected for dents and scratches,
            old paint is stripped-off and the dents are electronically removed.
            Finally, the car body is accurately leveled using a body-filler to
            make sure there is no deviation. Paint process begins with choosing
            the colour required by the client. Then we match the colour using a
            computerised machine that precisely does the mixing job of paint
            ensuring accuracy. We also provide complimentary interior Deep
            Cleaning of the car to restore the interiors of the car to its
            cleanest possible form.
          </p>
        </div>
        <div class="col-md-6">
          <h6 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >
            Panel or Part Replacement
          </h6>
          <p class="wow fadeInUp" data-wow-duration="1s" >For the parts or panels that are damaged that cannot be repaired, it
          has to be completely replaced. We source original parts from OEM/OES
          suppliers only as we hate duplicacy. Before painting we test the part
          or panel to meet our quality standards. The parts are then painted and
          fitted by our expert technicians ensuring proper fitment. We are
          completely transparent during the process of part procurement and
          replacement to ensure your piece of mind
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h5 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >Car Detailing</h5>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            We have mastered the art of Ceramic Coating, and we guarantee our
            customers the best service in the whole of Delhi NCR. Our CarEagerTM
            9H Ceramic Coating formula is specially imported from Japan and the
            USA. As a result, it forms a chemical bonding and becomes a safety
            wall with the factory paint giving it a fantastic shining and paint
            protection from debris or climate. Once coated with our 9H Ceramic
            Coating, you don’t need to worry about anything. We have also framed
            packages for the CarEagerTM 9H Ceramic Coating: One year Warranty
            Three year warranty Five Year Warranty Choose any package of your
            choice and leave it on us.
          </p>
        </div>
        <div class="col-md-6">
          <h5 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >Insurance Claims</h5>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            We have tie-ups with all leading Car Insurance companies to process
            insurance claims for your cars. We provide complete peace-of-mind
            and convenience right from the documentation and claim intimation
            process to survey, approvals and required documentation post-repair
            and delivery of vehicle. We have tie-ups with Kotak Mahindra, HDFC
            ERGO, Bajaj Allianz, Digit, IFFCO TOKIO, The New India Insurance,
            Shriram General Insurance, TATA AIG Insurance, National Insurance,
            RELIANCE General Insurance, LIBERTY General Insurance, United India,
            Royal Sundaram, Bharti AXA, ICICI Lombard and much more. So free
            relaxed in any taxing situations when you are with CarEager.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h5 class="text-uppercase font-weight-bold wow fadeInUp" data-wow-duration="1s" >
            Our Workshop is located in Sector 35 Gurgaon and we are open for any
            location in Delhi NCR like Gurugram, Delhi, Dwarka, Ghaziabad,
            Faridabad, Manesar, Noida
          </h5>
          <p class="wow fadeInUp" data-wow-duration="1s" >
            We have the facility of free pick n drop service as we know that our
            customer’s time is precious and if a customer is willing to visit
            our workshop, he will experience a whole new world at carEager.
            Experience the only car workshop where you will love spending time
            while we are working on your car.: Welcome drink Air Conditioned
            resting lounge Walk around in our workshop without any hindrance.
            You are free to watch your car getting repaired.
          </p>
        </div>
      </div>
    </div>
  </div>
<!-- BMW Start -->
<div id="bmw" *ngIf="_carType=='bmw-service'" class="footer-text home-container">
  <div style="color: darkgray; margin-left: 2rem; margin-right: 2rem">
    <div class="row">
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">Save up to 40% on BMW service in Gurgaon with Complete Quality Assurance.</h5>
        <p>
          Welcome to the Premium car service workshop in Gurgaon. CarEager is the best workshop for your BMW and service without letting you leave your home or work. Our recovery driver will pick up your car at your chosen time and location, service or repair it & deliver it back to you with 12 months parts and labor warranty on any work carried out. 
          Service & repair any BMW with complete peace of mind. 
          We Collect - We Service - We Deliver.
          
        </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">BMW </h5>
        <p>
          At CarEager, our number one priority is customer safety, convenience and satisfaction. We Provide 12 Months/10,000 KM Warranty with every service for BMW whether you choose to buy genuine or aftermarket parts from us, both come with a hassle-free warranty policy. The labor is also covered under the warranty.

          Every BMW service comes with a full vehicle health check. Our approved garages have the latest equipment, allowing our qualified technicians and mechanics to diagnose any problems quickly and efficiently. 
          
          BMW service and BMW repair can be very expensive with the main agencies that's why we are here to make the service more affordable as well as making it hassle-free for you. Any BMW service Gurgaon, Delhi NCR, comes with a free collection and delivery as well as parts and labour warranty. 
          
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">
          The Services Offered for BMW
        </h5>
      
        <p>
          We offer a wide variety of BMW services and repairs in the best authorised workshops and trained BMW mechanics.

        </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">BMW General Service
      </h5>
        <p>
          Every car needs periodic maintenance for great health, so is your BMW. We have fixed packages for regular car services that includes 25 point car diagnostics, 
          engine oil change, filters replacement, wash n clean and much more.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h6 class="text-uppercase font-weight-bold">Car AC Service 
      </h6>
        <p>
          We have customized BMW AC repair packages including AC gas top up, Cooling coil, Condenser, AC Compressor, Blower Cleaning with disinfection.

        </p>
      </div>

      <div class="col-md-6">
        h6 class="text-uppercase font-weight-bold"  Certified Mechanicals
        <p>
          Expert technicians will be on stand-by to give you quality service
          based on years of auto repair experience from major car brands like
          Audi, Mercedes Benz, BMW, Land Rover etc
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h6 class="text-uppercase font-weight-bold">BMW Repairs
      </h6>
        <p>
          Having issues with Brakes, Suspension, Clutch or Engine? We repair and use only OEMs at a price, value and quality as compared to any other authorized car service station.

        </p>
      </div>

      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">BMW Denting & Painting
      </h5>
        <p>Your BMW hates scratches and dents. Get your car denting painting done with US and German based paints. We assure you 100% colour match as we have one of a kind repair system that makes sure that the accuracy is perfect.
      </p>
      </div>
    </div>

<!--  -->
<div class="row">
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">Cashless Insurance Claims for BMW

  </h6>
    <p>
      Met with an accident? Don’t worry. CarEager is there to fix your BMW as it was before. We have tie-ups with major insurance companies which will help in cashless and zero dep car insurance claims.

    </p>
  </div>

  <div class="col-md-6">
    <h5 class="text-uppercase font-weight-bold">BMW Rubbing & Polishing

  </h5>
   <p>Give your car a sparkling look with our car detailing package which includes wash and vacuum, interior beautification and sanitisation, premium wax coating, anti-rust paint and Ceramic Shield (3 and 5 year warranty)
  </p>
  </div>
</div>
<!--  -->
<div class="row">
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">BBMW Dry Cleaning
  </h6>
    <p>Refresh your car interiors by car dry cleaning and polishing of all interior carpets, roof, boot, doors and dashboard.
  </p>
  </div>

  <div class="col-md-6">
    <h5 class="text-uppercase font-weight-bold">BMW Complete Roadside Assistance
  </h5>
   <p>We provide Roadside Assistance services like car towing, jumpstart, battery replacement if you are stuck in the middle of your journey.</p>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">Models we Serve:

  </h6>
    <p>BMW X1<br>
      BMW 3 Series<br>
      BMW 3 Series GT<br>
      BMW X3<br>
      BMW 5 Series<br>
      BMW 6 Series GT<br>
      BMW X5<br>
      BMW 6 Series Gran Coupe’<br>
      BMW X6<br>
      BMW 7 Series<br>
      BMW M5<br>
      </p>
  </div>


</div>
  
  </div>
</div>

<!-- Jaguar Start -->

<div  id="Jaguar" *ngIf="_carType=='jaguar-service'" class="footer-text home-container">
  <div style="color: darkgray; margin-left: 2rem; margin-right: 2rem">
    <div class="row">
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">Save up to 40% on Jaguar service in Gurgaon with Complete Quality Assurance.</h5>
        <p>
          Welcome to the Premium car service workshop in Gurgaon. CarEager is the best workshop for your Jaguar and service without letting you leave your home or work. Our recovery driver will pick up your car at your chosen time and location, service or repair it & deliver it back to you with 12 months parts and labor warranty on any work carried out. 
          Service & repair any Jaguar with complete peace of mind. 
          We Collect - We Service - We Deliver.
          
          At CarEager, our number one priority is customer safety, convenience and satisfaction. We Provide 12 Months/10,000 KM Warranty with every service for Jaguar whether you choose to buy genuine or aftermarket parts from us, both come with a hassle-free warranty policy. The labor is also covered under the warranty.
          
          Every Jaguar service comes with a full vehicle health check. Our approved garages have the latest equipment, allowing our qualified technicians and mechanics to diagnose any problems quickly and efficiently. 
          
          Jaguar service and Jaguar repair can be very expensive with the main agencies that's why we are here to make the service more affordable as well as making it hassle-free for you. Any Jaguar service Gurgaon, Delhi NCR, comes with a free collection and delivery as well as parts and labour warranty. 
          
        </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">The Services Offered for Jaguar
      </h5>
        <p>
          We offer a wide variety of Jaguar services and repairs in the best authorised workshops and trained Jaguar mechanics.

        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">
          Jaguar General Service

        </h5>
      
        <p>
          Every car needs periodic maintenance for great health, so is your Jaguar. We have fixed packages for regular car services that includes 25 point car diagnostics, engine oil change, filters replacement, wash n clean and much more.


        </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">Car AC Service 

      </h5>
        <p>
          We have customized Jaguar AC repair packages including AC gas top up, Cooling coil, Condenser, AC Compressor, Blower Cleaning with disinfection.

        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h6 class="text-uppercase font-weight-bold">Jaguar Repairs

      </h6>
        <p>
          Having issues with Brakes, Suspension, Clutch or Engine? We repair and use only OEMs at a price, value and quality as compared to any other authorized car service station.

        </p>
      </div>

      <div class="col-md-6">
          Jaguar Denting & Painting

        <p>
          Your Jaguar hates scratches and dents. Get your car denting painting done with US and German based paints. We assure you 100% colour match as we have one of a kind repair system that makes sure that the accuracy is perfect.

        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h6 class="text-uppercase font-weight-bold">Cashless Insurance Claims for Jaguar
      </h6>
        <p>
          Met with an accident? Don’t worry. CarEager is there to fix your Jaguar as it was before. We have tie-ups with major insurance companies which will help in cashless and zero dep car insurance claims.

        </p>
      </div>

      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">Jaguar Rubbing & Polishing

      </h5>
        <p>
          Give your car a sparkling look with our car detailing package which includes wash and vacuum, interior beautification and sanitisation, premium wax coating, anti-rust paint and Ceramic Shield (3 and 5 year warranty)

      </p>
      </div>
    </div>

<!--  -->
<div class="row">
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">Jaguar Dry Cleaning


  </h6>
    <p>
      Refresh your car interiors by car dry cleaning and polishing of all interior carpets, roof, boot, doors and dashboard.

    </p>
  </div>

  <div class="col-md-6">
    <h5 class="text-uppercase font-weight-bold">Jaguar Complete Roadside Assistance


  </h5>
   <p>
      We provide Roadside Assistance services like car towing, jumpstart, battery replacement if you are stuck in the middle of your journey.
      </p>
  </div>
</div>

<!--  -->
<div class="row">
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">Models we Serve:


  </h6>
    <p>Jaguar XE<br>
      Jaguar XF<br>
      Jaguar F-Pace<br>
      Jaguar XJ<br>
      Jaguar F-Type<br>
      
      
      </p>
  </div>


</div>
  
  </div>
</div>

<!-- Porsche Start-->
<div *ngIf="_carType=='porsche-service'" id="Porsche" class="footer-text home-container">
  <div style="color: darkgray; margin-left: 2rem; margin-right: 2rem">
    <div class="row">
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">Save up to 40% on Porsche service in Gurgaon with Complete Quality Assurance.</h5>
        <p>
          Welcome to the Premium car service workshop in Gurgaon. CarEager is the best workshop for your Porsche and service without letting you leave your home or work. Our recovery driver will pick up your car at your chosen time and location, service or repair it & deliver it back to you with 12 months parts and labor warranty on any work carried out. 
          Service & repair any Porsche with complete peace of mind. 
          We Collect - We Service - We Deliver.
          
          At CarEager, our number one priority is customer safety, convenience and satisfaction. We Provide 12 Months/10,000 KM Warranty with every service for Porsche whether you choose to buy genuine or aftermarket parts from us, both come with a hassle-free warranty policy. The labor is also covered under the warranty.
          
          Every Porsche service comes with a full vehicle health check. Our approved garages have the latest equipment, allowing our qualified technicians and mechanics to diagnose any problems quickly and efficiently. 
          
          Porsche service and Porsche repair can be very expensive with the main agencies that's why we are here to make the service more affordable as well as making it hassle-free for you. Any Porsche service Gurgaon, Delhi NCR, comes with a free collection and delivery as well as parts and labour warranty. 
          
        </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">The Services Offered for Porsche

      </h5>
        <p>
          We offer a wide variety of Porsche services and repairs in the best authorised workshops and trained Porsche mechanics.


        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">
          Porsche General Service

        </h5>
      
        <p>
          Every car needs periodic maintenance for great health, so is your Porsche. We have fixed packages for regular car services that includes 25 point car diagnostics, engine oil change, filters replacement, wash n clean and much more.
      </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">Car AC Service 

      </h5>
        <p>
          We have customized Porsche AC repair packages including AC gas top up, Cooling coil, Condenser, AC Compressor, Blower Cleaning with disinfection.


        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h6 class="text-uppercase font-weight-bold">Porsche Repairs

      </h6>
        <p>
          Having issues with Brakes, Suspension, Clutch or Engine? We repair and use only OEMs at a price, value and quality as compared to any other authorized car service station.

        </p>
      </div>

      <div class="col-md-6">
          Jaguar Denting & Painting

        <p>
          Your Porsche hates scratches and dents. Get your car denting painting done with US and German based paints. 
          We assure you 100% colour match as we have one of a kind repair system that makes sure that the accuracy is perfect
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h6 class="text-uppercase font-weight-bold">Cashless Insurance Claims for Porsche


      </h6>
        <p>
          Met with an accident? Don’t worry. CarEager is there to fix your Porsche as it was before. We have tie-ups with major insurance companies which will help in cashless and zero dep car insurance claims.
        </p>
      </div>

    
    </div>



<!--  -->
<div class="row">
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">Models we Serve:


  </h6>
    <p>JPorsche Cayenne<br>
      Porsche Panamera<br>
      Porsche Macan<br>
      Porsche Boxster<br>
      Porsche Cayman<br>
      Porsche 911<br>
         </p>
  </div>


</div>
  
  </div>
</div>

<!-- Mercedes Benz Start-->
<div *ngIf="_carType=='mercedes-benz-service'" id="Mercedes" class="footer-text home-container">
  <div style="color: darkgray; margin-left: 2rem; margin-right: 2rem">
    <div class="row">
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">Save up to 40% on Mercedes Benz service in Gurgaon with Complete Quality Assurance.</h5>
        <p>
          Welcome to the Premium car service workshop in Gurgaon. CarEager is the best workshop for your Mercedes Benz and service without letting you leave your home or work. Our recovery driver will pick up your car at your chosen time and location, service or repair it & deliver it back to you with 12 months parts and labor warranty on any work carried out. 
          Service & repair any Mercedes Benz with complete peace of mind. 
          We Collect - We Service - We Deliver.
          
          At CarEager, our number one priority is customer safety, convenience and satisfaction. We Provide 12 Months/10,000 KM Warranty with every service for Mercedes Benz whether you choose to buy genuine or aftermarket parts from us, both come with a hassle-free warranty policy. The labor is also covered under the warranty.
          
          Every Mercedes Benz service comes with a full vehicle health check. Our approved garages have the latest equipment, allowing our qualified technicians and mechanics to diagnose any problems quickly and efficiently. 
          
          Mercedes Benz service and Mercedes Benz repair can be very expensive with the main agencies that's why we are here to make the service more affordable as well as making it hassle-free for you. Any Mercedes Benz service Gurgaon, Delhi NCR, comes with a free collection and delivery as well as parts and labour warranty.
          
        </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">The Services Offered for Mercedes Benz


      </h5>
        <p>
          We offer a wide variety of Mercedes Benz services and repairs in the best authorised workshops and trained Mercedes Benz mechanics.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">
        Mercedes Benz General Service


        </h5>
      
        <p>
          Every car needs periodic maintenance for great health, so is your Mercedes Benz. 
          We have fixed packages for regular car services that includes 25 point car diagnostics, engine oil change, filters replacement, wash n clean and much more.
      </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">Car AC Service 

      </h5>
        <p>
          We have customized Mercedes Benz AC repair packages including AC gas top up, Cooling coil, Condenser, AC Compressor, Blower Cleaning with disinfection.

        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h6 class="text-uppercase font-weight-bold"> Mercedes Benz Repairs

      </h6>
        <p>
          Having issues with Brakes, Suspension, Clutch or Engine? We repair and use only OEMs at a price, value and quality as compared to any other authorized car service station.



        </p>
      </div>

      <div class="col-md-6">
          Mercedes Benz  Denting & Painting

        <p>
          Your Mercedes Benz hates scratches and dents. Get your car denting painting done with US and German based paints. We assure you 100% colour match as we have one of a kind repair system that makes sure that the accuracy is perfect.

        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h6 class="text-uppercase font-weight-bold">Cashless Insurance Claims for Mercedes Benz


      </h6>
        <p>
          Met with an accident? Don’t worry. CarEager is there to fix your Mercedes Benz as it was before.
           We have tie-ups with major insurance companies which will help in cashless and zero dep car insurance claims.
      </p>
      </div>

    
    </div>



<!--  -->
<div class="row">
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">Mercedes Benz Rubbing & Polishing

  </h6>
    <p>Give your car a sparkling look with our car detailing package which includes wash and vacuum, interior beautification and sanitisation, premium wax coating, anti-rust paint and Ceramic Shield (3 and 5 year warranty)
  </p>
  </div>

  <div class="col-md-6">
    <h5 class="text-uppercase font-weight-bold">Mercedes Benz Dry Cleaning

  </h5>
   <p>Refresh your car interiors by car dry cleaning and polishing of all interior carpets, roof, boot, doors and dashboard.</p>
  </div>
</div>
<!--  -->

<div class="row">

  <div class="col-md-6">
      <h5 class="text-uppercase font-weight-bold">Mercedes Benz Complete Roadside Assistance 
    </h5>
     <p>We provide Roadside Assistance services like car towing, jumpstart, battery replacement if you are stuck in the middle of your journey.</p>
    </div>
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">Models we Serve:


  </h6>
    <p>Mercedes Benz A Class<br>
      Mercedes Benz B Class<br>
      Mercedes Benz CLA<br>
      Mercedes Benz GLA <br>
      Mercedes Benz C Class<br>
      Mercedes Benz GLC<br>
      Mercedes Benz E Class<br>
      Mercedes Benz GLE<br>
      Mercedes Benz V Class<br>
      Mercedes Benz E Class All Terrain<br>
      Mercedes Benz CLS<br>
      Mercedes Benz GLS<br>
      Mercedes Benz S Class<br>
      Mercedes Benz Maybach S Class<br>
      
         </p>
  </div>


</div>
  
  </div>
</div>

<!-- Audi Start-->
<div *ngIf="_carType=='audi-service'" id="Audi" class="footer-text home-container">
  <div style="color: darkgray; margin-left: 2rem; margin-right: 2rem">
    <div class="row">
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">Save up to 40% on Audi service in Gurgaon with Complete Quality Assurance.</h5>
        <p>
          Welcome to the Premium car service workshop in Gurgaon. CarEager is the best workshop for your Audi and service without letting you leave your home or work. Our recovery driver will pick up your car at your chosen time and location, service or repair it & deliver it back to you with 12 months parts and labor warranty on any work carried out. 
          Service & repair any Audi with complete peace of mind. 
          We Collect - We Service - We Deliver.
          
          At CarEager, our number one priority is customer safety, convenience and satisfaction. We Provide 12 Months/10,000 KM Warranty with every service for Audi whether you choose to buy genuine or aftermarket parts from us, both come with a hassle-free warranty policy. The labor is also covered under the warranty.
          
          Every Audi service comes with a full vehicle health check. Our approved garages have the latest equipment, allowing our qualified technicians and mechanics to diagnose any problems quickly and efficiently. 
          
          Audi service and Audi repair can be very expensive with the main agencies that's why we are here to make the service more affordable as well as making it hassle-free for you. Any Audi service Gurgaon, Delhi NCR, comes with a free collection and delivery as well as parts and labour warranty. 
          
        </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">The Services Offered for Audi
      </h5>
        <p>
          We offer a wide variety of Audi services and repairs in the best authorised workshops and trained Audi mechanics.

        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">
          Audi General Service

        </h5>
      
        <p>
          Every car needs periodic maintenance for great health, so is your Audi.
           We have fixed packages for regular car services that includes 25 point car diagnostics, engine oil change, filters replacement, wash n clean and much more.
      </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">Car AC Service 


      </h5>
        <p>
          We have customized Audi AC repair packages including AC gas top up, Cooling coil, Condenser, AC Compressor, Blower Cleaning with disinfection.

        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h6 class="text-uppercase font-weight-bold"> Mercedes Benz Repairs

      </h6>
        <p>
          Having issues with Brakes, Suspension, Clutch or Engine? We repair and use only OEMs at a price, value and quality as compared to any other authorized car service station.



        </p>
      </div>

      <div class="col-md-6">
          Audi Repairs

        <p>
          Having issues with Brakes, Suspension, Clutch or Engine? We repair and use only OEMs at a price, value and quality as compared to any other authorized car service station.

        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h6 class="text-uppercase font-weight-bold">Audi Denting & Painting


      </h6>
        <p>
          Your Audi hates scratches and dents. Get your car denting painting done with US and German based paints. 
          We assure you 100% colour match as we have one of a kind repair system that makes sure that the accuracy is perfect.

      </p>
      </div>

    
    </div>



<!--  -->
<div class="row">
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">Cashless Insurance Claims for Audi


  </h6>
    <p>
      Met with an accident? Don’t worry. CarEager is there to fix your Audi as it was before. We have tie-ups with major insurance companies which will help in cashless and zero dep car insurance claims.

  </p>
  </div>

  <div class="col-md-6">
    <h5 class="text-uppercase font-weight-bold">Audi Rubbing & Polishing


  </h5>
   <p>Give your car a sparkling look with our car detailing package which includes wash and vacuum, interior beautification and sanitisation, premium wax coating, anti-rust paint and Ceramic Shield (3 and 5 year warranty)
  </p>
  </div>
</div>
<!--  -->
<div class="row">
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">Audi Rubbing & Polishing
  </h6>
    <p>Give your car a sparkling look with our car detailing package which includes wash and vacuum, 
        interior beautification and sanitisation, premium wax coating, anti-rust paint and Ceramic Shield (3 and 5 year warranty)</p>
  </div>

  <div class="col-md-6">
    <h5 class="text-uppercase font-weight-bold">Audi Dry Cleaning
  </h5>
   <p>Refresh your car interiors by car dry cleaning and polishing of all interior carpets, roof, boot, doors and dashboard</p>
  </div>
</div>
<!--  -->
<div class="row">

  <div class="col-md-6">
      <h5 class="text-uppercase font-weight-bold">Audi Complete Roadside Assistance

    </h5>
     <p>We provide Roadside Assistance services like car towing, jumpstart, battery replacement if you are stuck in the middle of your journey.
  </p>
    </div>
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">Models we Serve:


  </h6>
    <p>

      Audi A3<br>
      Audi Q3<br>
      Audi A4<br>
      Audi A3 Cabriolet<br>
      Audi Q5<br>
      Audi A6<br>
      Audi Sportback<br>
      Audi Q7<br>
      Audi A8<br>
      Audi RS7 Sportback<br>
      Audi R8<br>
      
      
         </p>
  </div>


</div>
  
  </div>
</div>
<!-- LandRover Start -->
<div *ngIf="_carType=='land-rover-service'" id="LandRover" class="footer-text home-container">
  <div style="color: darkgray; margin-left: 2rem; margin-right: 2rem">
    <div class="row">
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">Save up to 40% on Land Rover service in Gurgaon with Complete Quality Assurance.</h5>
        <p>
          Welcome to the Premium car service workshop in Gurgaon. CarEager is the best workshop for your Land Rover and service without letting you leave your home or work. Our recovery driver will pick up your car at your chosen time and location, service or repair it & deliver it back to you with 12 months parts and labor warranty on any work carried out. 
          Service & repair any Land Rover with complete peace of mind. 
          We Collect - We Service - We Deliver.
          
          At CarEager, our number one priority is customer safety, convenience and satisfaction. We Provide 12 Months/10,000 KM Warranty with every service for Land Rover whether you choose to buy genuine or aftermarket parts from us, both come with a hassle-free warranty policy. The labor is also covered under the warranty.
          
          Every Land Rover service comes with a full vehicle health check. Our approved garages have the latest equipment, allowing our qualified technicians and mechanics to diagnose any problems quickly and efficiently. 
          
          Land Rover service and Land Rover repair can be very expensive with the main agencies that's why we are here to make the service more affordable as well as making it hassle-free for you. Any Land Rover service Gurgaon, Delhi NCR, comes with a free collection and delivery as well as parts and labour warranty. 
          
        </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">The Services Offered for Land Rover
      </h5>
        <p>
          We offer a wide variety of Land Rover services and repairs in the best authorised workshops and trained Land Rover mechanics.


        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">
          Audi General Service

        </h5>
      
        <p>
          Every car needs periodic maintenance for great health, so is your Land Rover. We have fixed packages for regular car services that includes 25 point car diagnostics, engine oil change, filters replacement, wash n clean and much more.
      </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">Car AC Service 


      </h5>
        <p>
          We have customized Land Rover AC repair packages including AC gas top up, Cooling coil, Condenser, AC Compressor, Blower Cleaning with disinfection.


        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h6 class="text-uppercase font-weight-bold"> Land Rover Repairs

      </h6>
        <p>
          Having issues with Brakes, Suspension, Clutch or Engine? We repair and use only OEMs at a price, value and quality as compared to any other authorized car service station.
        </p>
      </div>

      <div class="col-md-6">
        <h6>Land Rover Denting & Painting</h6>

        <p>
          Your Land Rover hates scratches and dents. Get your car denting painting done with US and German based paints.
           We assure you 100% colour match as we have one of a kind repair system that makes sure that the accuracy is perfect.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h6 class="text-uppercase font-weight-bold">Cashless Insurance Claims for Land Rover


      </h6>
        <p>
          Met with an accident? Don’t worry. CarEager is there to fix your Land Rover as it was before. We have tie-ups with major insurance companies which will help in cashless and zero dep car insurance claims.


      </p>
      </div>

    
    </div>

<!--  -->
<div class="row">
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">Land Rover Rubbing & Polishing

  </h6>
    <p>Give your car a sparkling look with our car detailing package which includes wash and vacuum, interior beautification and sanitisation, 
        premium wax coating, anti-rust paint and Ceramic Shield (3 and 5 year warranty)</p>
  </div>

  <div class="col-md-6">
    <h5 class="text-uppercase font-weight-bold">Land Rover Dry Cleaning

  </h5>
   <p>Refresh your car interiors by car dry cleaning and polishing of all interior carpets, roof, boot, doors and dashboard.</p>
  </div>
</div>
<!--  -->


<!--  -->
<div class="row">

  <div class="col-md-6">
      <h5 class="text-uppercase font-weight-bold">Land Rover Complete Roadside Assistance
    </h5>
     <p>We provide Roadside Assistance services like car towing, jumpstart, battery replacement if you are stuck in the middle of your journey.


  </p>
    </div>
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">Models we Serve:


  </h6>
    <p>
      Land Rover Discovery Sport<br>
      Land Rover Range Rover Evoque<br>
      Land Rover Range Rover Sport<br>
      Land Rover Discovery<br>
      Land Rover Range Rover<br>
      
         </p>
  </div>


</div>
  
  </div>
</div>

<!-- Volvo Start-->
<div *ngIf="_carType=='volvo-service'"  id="Volvo" class="footer-text home-container">
  <div style="color: darkgray; margin-left: 2rem; margin-right: 2rem">
    <div class="row">
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">Save up to 40% on Volvo service in Gurgaon with Complete Quality Assurance.</h5>
        <p>
          Welcome to the Premium car service workshop in Gurgaon. CarEager is the best workshop for your Volvo and service without letting you leave your home or work. Our recovery driver will pick up your car at your chosen time and location, service or repair it & deliver it back to you with 12 months parts and labor warranty on any work carried out. 
          Service & repair any Volvo with complete peace of mind. 
          We Collect - We Service - We Deliver.
          
          At CarEager, our number one priority is customer safety, convenience and satisfaction. We Provide 12 Months/10,000 KM Warranty with every service for Volvo whether you choose to buy genuine or aftermarket parts from us, both come with a hassle-free warranty policy. The labor is also covered under the warranty.
          
          Every Volvo service comes with a full vehicle health check. Our approved garages have the latest equipment, allowing our qualified technicians and mechanics to diagnose any problems quickly and efficiently. 
          
          Volvo service and Volvo repair can be very expensive with the main agencies that's why we are here to make the service more affordable as well as making it hassle-free for you. Any Volvo service Gurgaon, Delhi NCR, comes with a free collection and delivery as well as parts and labour warranty. 
          
        </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">The Services Offered for Volvo

      </h5>
        <p>
          We offer a wide variety of Volvo services and repairs in the best authorised workshops and trained Volvo mechanics.

        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">
          Volvo General Service

        </h5>
      
        <p>
          Every car needs periodic maintenance for great health, so is your Volvo. We have fixed packages for regular car services that includes 25 point car diagnostics, engine oil change, filters replacement, wash n clean and much more.

      </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-uppercase font-weight-bold">Car AC Service 


      </h5>
        <p>
          We have customized Volvo AC repair packages including AC gas top up, Cooling coil, Condenser, AC Compressor, Blower Cleaning with disinfection.

        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h6 class="text-uppercase font-weight-bold"> Volvo Repairs

      </h6>
        <p>
          Having issues with Brakes, Suspension, Clutch or Engine? We repair and use only OEMs at a price, value and quality as compared to any other authorized car service station.
      </p>
      </div>

      <div class="col-md-6">
        <h6>Volvo Denting & Painting</h6>

        <p>
          Your Volvo hates scratches and dents. Get your car denting painting done with US and German based paints. We assure you 100% colour match as we have one of a kind repair system that makes sure that the accuracy is perfect.

        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h6 class="text-uppercase font-weight-bold">Cashless Insurance Claims for Volvo


      </h6>
        <p>
          Met with an accident? Don’t worry. CarEager is there to fix your Volvo as it was before.
           We have tie-ups with major insurance companies which will help in cashless and zero dep car insurance claims.
      </p>
      </div>

    
    </div>

<!--  -->
<div class="row">
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">Volvo Rubbing & Polishing

  </h6>
    <p>Give your car a sparkling look with our car detailing package which includes wash and vacuum, interior beautification and sanitisation, premium wax coating, anti-rust paint and Ceramic Shield (3 and 5 year warranty)
  </p>
  </div>

  <div class="col-md-6">
    <h5 class="text-uppercase font-weight-bold">Volvo Dry Cleaning

  </h5>
   <p>Refresh your car interiors by car dry cleaning and polishing of all interior carpets, roof, boot, doors and dashboard.</p>
  </div>
</div>
<!--  -->
<div class="row">

  <div class="col-md-6">
      <h5 class="text-uppercase font-weight-bold">Volvo Complete Roadside Assistance
    </h5>
     <p>We provide Roadside Assistance services like car towing, jumpstart, battery replacement if you are stuck in the middle of your journey.

  </p>
    </div>
  <div class="col-md-6">
    <h6 class="text-uppercase font-weight-bold">Models we Serve:


  </h6>
    <p>
      
Volvo XC40<br>
Volvo XC60<br>
Volvo V40 Cross Country<br>
Volvo S60<br>
Volvo S60 Polestar<br>
Volvo S90<br>
Volvo V90 Cross Country<br>
Volvo XC90<br>

      
         </p>
  </div>


</div>
  
  </div>
</div>
