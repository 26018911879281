import { ChangeDetectorRef } from '@angular/core';

import { SharedService } from 'src/app/@service/shared.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { SessionStorage } from 'ngx-store';
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'app-footer-a',
  templateUrl: './footer-a.component.html',
  styleUrls: ['./footer-a.component.css']
})
export class FooterAComponent implements OnInit {
  //@SessionStorage('car') car:string='';
  //@SessionStorage('carfrom') carfrom:any;
  carfrom: any;
  car: string = ''
  _carType:string="common";
  constructor(private _router:Router,private _carselection:SharedService,private cdr:ChangeDetectorRef,private url:ActivatedRoute ) { }

  ngOnInit() {
   this._carselection.currentcarDetail.subscribe((_res:any)=>{
     
       this._carType=this.car
  
     
   })
  }

  setcarUrl(url:any){
    localStorage.setItem('setServiceUrl', url);
    this._carselection.setAllCarUrl(url)
  }





navigateTo=(path:string)=>{
  this.car=path;
  this._carType=path;
  let cars={
    type:"footer",
    car:path
  }
 window.scroll(0, 0)
  this.carfrom=cars;
  
  if (isPlatformBrowser(PLATFORM_ID)) {
    localStorage.setItem("carfrom", JSON.stringify(cars))
  }

  this._carselection.changecarDetail(cars);

  let fullpath=`service/${path}`
  this._router.navigate([`${fullpath}`]);
}
}
