<div *ngIf="isLoader" class="cg-loader">
  <div><img style="width: 24rem;" src="../../../assets/images/careager-loader.gif"></div>
</div>

<section class="main-home">
  <div class="home-banner">
    <div class="video-overlay">

    </div>
    <!-- <video preload='auto' id="myVideo" controls="controls" controls preload ="auto" autoplay="autoplay" loop muted playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="assets/images/videos/careager_new.mp4" type="video/mp4" />
    </video> -->
    <video preload='auto' id="myVideo" autoplay loop muted playsinline>
      <source src="assets/images/videos/careager_new.mp4" type="video/mp4">
  </video>
    <div class="caption-banner">
      <h1 class="wow fadeInUp" data-wow-duration="1s" data="0.5s">The <span style="color: #f00;">Ultimate</span> Car Services</h1>
      <a href="javascript:void(0);" (click)="open('youtubevedio')" class="youtube  wow fadeInUp" data-wow-duration="1s"
        data="1s"><img src="assets/images/playicon.webp" alt="" />&nbsp;&nbsp; &nbsp;Watch The Intro
      </a>
    </div>
    <div class="form-banner">
      <h2 class="form-heading wow fadeInUp" data-wow-duration="1s" data="0.5s"
        style="margin-top: 0; font-weight: bold;"><span style="color: #f00;font-weight: bold;font-size: 32px;font-family: OpenSans-bold;">Get ₹1000 OFF</span> <br> <span>On First Car Service</span></h2>
      <form class="form-main" id="appointment" [formGroup]="appointmentForm"
        (ngSubmit)="onSubmit();appointmentForm.reset()">

        <div class="form-div">
          <input required formControlName="name" name="name" type="text" class="banner-input" id="name"
            placeholder="Name *" />

          <div
            *ngIf="appointmentForm.get('name').touched && appointmentForm.get('name').invalid && appointmentForm.get('name').errors?.required"
            style="color: #a94442;margin-bottom: 7px; padding-left: 10px;">
            Name is required.
          </div>
        </div>
        <div class="form-div">
          <input required pattern="\+?\d[\d -]{8,12}\d" maxlength="10" formControlName="contact_no" name="contact_no"
            type="text" class="banner-input" id="contact_no" placeholder="Contact Number *" />
          <div *ngIf="appointmentForm.get('contact_no').touched && appointmentForm.get('contact_no').invalid"
            style="color: #a94442;margin-bottom: 7px; padding-left: 10px;">
            Contact No. is required.
          </div>
        </div>
        <div class="form-div">
          <textarea formControlName="message" class="banner-input" name="" id="" rows="5"
            placeholder="Message (Optional)"></textarea>

        </div>

        <div class="form-div">
          <form [formGroup]="appointmentForm">
            <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (load)="handleLoad()"
              (success)="handleSuccess($event)" [useGlobalDomain]="false" [theme]="theme" [type]="type"
              formControlName="recaptcha">
            </ngx-recaptcha2>
          </form>

        </div>

        <a href="#vinaytesting" style="display: inline-block;
        width: 100%;
        text-align: center;">
          <button style="    margin-top: 2%; background-color: red" class="btn btn-submit" (click)="onSubmit()"
            [disabled]="appointmentForm.invalid">Get Offer</button>
        </a>
      </form>
    </div>
  </div>
  <div class="home-rating home-container">
    <div class="rating-image">
      <img class="wow zoomIn" data-wow-duration="1s" data="0.5s" src="assets/images/rating.png" alt="" />
    </div>
    <div class="rating-stars">
      <!-- <p class="para-heading wow fadeInUp" data-wow-duration="1s" data="0.5s">CarEager is devoted to just one idea -</p> -->
      <p class="para-text wow fadeInUp" data-wow-duration="1s">
        “With over 32 year-long history, we are committed to providing 
        the ultimate <span style="color: #f00;">car services at fair prices</span>. We believe that happy & loyal 
        customers are more important than short-term profits”
      </p>
      <p class="para-name wow fadeInUp" data-wow-duration="1s" data="0.8s">- Avinay Sharma, CEO, CarEager</p>
      <div class="rating wow fadeInUp" data-wow-duration="1s" data="0.9s" style="margin-top: 45px;">
        <i class="glyphicon glyphicon-star"></i>
        <i class="glyphicon glyphicon-star"></i>
        <i class="glyphicon glyphicon-star"></i>
        <i class="glyphicon glyphicon-star"></i>
        <i class="glyphicon glyphicon-star half"></i>
        <i class="rating-point">4.9/5</i>
      </div>
      <div class="rting-platform">
        <a target="_blank" href=" https://g.page/CarEager/review?gm" class="wow fadeInUp" data-wow-duration="1s"
          data="1s"><img class="google" src="assets/images/rating-1.webp" alt="" /></a>
        <a target="_blank"
          href="https://www.justdial.com/Delhi/CarEager-Gurgaon-Sector-35/011PXX11-XX11-170928171818-N4W5_BZDET"
          class="wow fadeInUp" data-wow-duration="1s" data="1s"><img class="justdial" src="assets/images/rating-3.webp"
            alt="" /></a>
        <a target="_blank" href="https://goo.gl/fU5Upb  " class="wow fadeInUp" data-wow-duration="1s" data="1s"><img
            class="playstore" src="assets/images/rating-2.webp" alt="" /></a>
        <a target="_blank" href="https://api.whatsapp.com/send?phone=+918710006161" style="display: block; padding-left: 22%; padding-top: 45px;"
          class="wow fadeInUp hidden-md hidden-sm hidden-lg" data-wow-duration="1s" data="1s"><img
            style="height: 48px;width: 159px;" class="playstore whats-app-button"
            src="assets/images/whatsapp-button.webp" alt="" /></a>

      </div>
    </div>
  </div>


  <app-service-slider></app-service-slider>
  <div class="home-workshop">
    <img class="workshop-bg hidden-xs" src="assets/images/home-workshop.webp" alt="" />
    <img class="workshop-bg visible-xs" src="assets/images/home-workshop-mob.webp" alt="" />
    <div class="workshop-captions">
      <div class="workshop-cap-text">
        <p class="wow fadeInUp" data-wow-duration="1s" data="0.5s"><b>CarEager <span>Workshops</span> </b></p>
        <p class="wow fadeInUp" data-wow-duration="1s" style="color: #aaa;">
          Our large company-owned, state-of-the-art workshops are equipped with
          some of the world’s most advanced tools & equipment, approved by
          brands like Audi, BMW, Mercedes-Benz, Tesla, and Porsche.
        </p>
      </div>
      <br />
      <br />
      <div class="workshop-cap-text">
        <p class="wow fadeInUp" data-wow-duration="1s"><b>CarEager <span>App</span> </b></p>
        <p class="wow fadeInUp" data-wow-duration="1s" data="0.8s" style="color: #aaa;">
          Explore cars, services, chat with advisors, request estimates, pay
          online, download invoices, all in the comfort of your home. It pays
          you back when you use the App. Earn CarEager Cash & redeem during
          bookings.
        </p>
      </div>
    </div>
  </div>
  <div class="container">


    <div class="row brand desktop">
      <h1>Brands we specialize in </h1>
      <div class="brand-logo">
        <img draggable="false" src="assets/images/brand/brand-logo-1.png">
        <img draggable="false" src="assets/images/brand/brand-logo-2.png">
        <img draggable="false" src="assets/images/brand/brand-logo-3.png">
        <img draggable="false" src="assets/images/brand/brand-logo-4.png">
        <img draggable="false" src="assets/images/brand/brand-logo-5.png">
      </div>
      <div class="brand-logo">
        <img draggable="false" src="assets/images/brand/Mini-logo.png">
        <img draggable="false" src="assets/images/brand/brand-logo-6.png">
        <img draggable="false" src="assets/images/brand/brand-logo-7.png">
        <img draggable="false" src="assets/images/brand/brand-logo-8.png">
        <img draggable="false" src="assets/images/brand/brand-logo-9.png">

      </div>
      <div class="brand-logo">
        <img draggable="false" src="assets/images/brand/brand-logo-10.png">
        <img draggable="false" src="assets/images/brand/brand-logo-11.png">
        <img draggable="false" src="assets/images/brand/brand-logo-12.png">
        <img draggable="false" src="assets/images/brand/brand-logo-13.png">
        <img draggable="false" src="assets/images/brand/brand-logo-14.png">
      </div>
    </div>


    <div class="row brand mobile">
      <h1>Brands we specialize in </h1>
      <div class="brand-logo">
        <img draggable="false" src="assets/images/brand/brand-logo-1.png">
        <img draggable="false" src="assets/images/brand/brand-logo-2.png">
        <img draggable="false" src="assets/images/brand/brand-logo-3.png">
     
      </div>
      <div class="brand-logo">
        <img draggable="false" src="assets/images/brand/brand-logo-4.png">
        <img draggable="false" src="assets/images/brand/brand-logo-5.png">
        <img draggable="false" src="assets/images/brand/Mini-logo.png">
      </div>
      <div class="brand-logo">
        <img draggable="false" src="assets/images/brand/brand-logo-6.png">
        <img draggable="false" src="assets/images/brand/brand-logo-7.png">
        <img draggable="false" src="assets/images/brand/brand-logo-8.png">      
      </div>
      <div class="brand-logo">
        <img draggable="false" src="assets/images/brand/brand-logo-9.png">
        <img draggable="false" src="assets/images/brand/brand-logo-10.png">
        <img draggable="false" src="assets/images/brand/brand-logo-11.png">
      </div>
      <div class="brand-logo">     
        <img draggable="false" src="assets/images/brand/brand-logo-12.png">
        <img draggable="false" src="assets/images/brand/brand-logo-13.png">
        <img draggable="false" src="assets/images/brand/brand-logo-14.png">
      </div>
    </div>
  </div>
  <app-feedback></app-feedback>
  <app-faq></app-faq>
  <app-you-tube></app-you-tube>
  <app-download-app></app-download-app>
  <!-- <app-footer-text></app-footer-text> -->
</section>


<jw-modal id="youtubevedio">
  <div class="price-modal moreItems">
    <div class="modal-dialog modal-custom">
      <div class="modal-content modal-color">
        <!-- <div class="modal-header">
          <button type="button" class="close" (click)="closeModal('youtubevedio');">
            <img src="assets/images/Close.webp" alt="" />
          </button>
         
        </div> -->
        <div class="modal-body body-color">
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/EXDI-MCOrKA?controls=0&rel=0"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>

          <!-- <video poster="images/videos/deskwork.jpg" preload="auto" loop autoplay muted>
          <source src="https://www.youtube.com/embed/EXDI-MCOrKA" type='video/mp4' />
      </video> -->

        </div>
      </div>
    </div>
  </div>
</jw-modal>
<div class="container home-content">
  <div class="row">
    <div class="col-md-12" data-wow-duration="1s">
      <h3>What is CarEager?</h3>
      <div class="content" style="text-align : justify">

        <p>CarEager is a one-stop solution for all your car-related problems such as periodic services, car detailing
          services, denting and painting services, insurance claims, car customization and so on. With the
          state-of-the-art facility situated in Gurgaon that hosts some of the world’s most advanced tools and
          equipment, making us capable of catering needs to luxury car brands such as Audi, BMW, Mercedes-Benz, Porsche,
          Lamborghini and Bugatti. We also cater to popular brands such as Maruti Suzuki, Hyundai, Volkswagen, Tata
          Motors, Mahindra, Honda and so on. At CarEager, you can also get your car customized, under the legal bounds.
        </p>

        <div class="col-md-6 modal-serve">
          <h3 class="header">Why Choose CarEager for Your Car Repair & Services?</h3>
          <ul>
            <li>Free pick up and drop facility at your comfort and convenience</li>
            <li>100% genuine OEM/OES spare parts used</li>
            <li>Courtesy car facility: Drop your car, we give you one of ours so that you don’t stop anytime in your
              life and our experts take care of your car.</li>
            <li>Dedicated customer lounge for the ones who love to visit us.</li>
            <li>Complete transparency: Want to take a look at what’s going on with your car? You have full freedom to
              roam through our service stations, at a safe distance.</li>
            <li>Multiple payment options: Cash payment, Debit and Credit card payment, UPI Payment, Wallets such as
              PayTm. Looking at the need of the hour, we have the online payment facility too.</li>
            <li>CarEager has a 40,000 sq ft, state-of-the-art car workshop situated in Gurgaon that is equipped with
              world-class equipment operated by trained professionals. If anyone with comparable infrastructure and
              experience offers Car services at better pricing, we’ll do it for free! And Also Provide Free Pick Up and
              Drop Services</li>

          </ul>
        </div>
        <div class=" col-md-6 modal-serve">
          <h3 class="header">Services we offer</h3>
          <ul>
            <li>Periodic car services</li>
            <li>Denting and Painting Services</li>
            <li>Collision Repair</li>
            <li>Wheel Balancing</li>
            <li>Tyre Alignment and Rotation</li>
            <li>AC services</li>
            <li>Brake service and replacement</li>
            <li>9H Ceramic Coating</li>
            <li>Cashless Insurance Claims </li>
            <li>Full body restoration</li>
            <li>Second-hand car purchases</li>
          </ul>
        </div>
       
      </div>
    </div>
    <div class="col-md-12">
    <h3>At what locations does CarEager cater to the car service needs?</h3>
    <p>Our Car workshop is located in Sector 35, Gurgaon and we cater to car repair requirements in Gurgaon as well as across Delhi-NCR at locations such as Delhi, Faridabad, Dwarka, Ghaziabad, Faridabad, Manesar and Noida. All you have to do is book our service and we’ll get your car picked up and dropped at your desired location. You can also visit our workshop</p>
  </div>
  </div>
</div>