import { ChangeDetectorRef } from '@angular/core';
import { SharedService } from 'src/app/@service/shared.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-text',
  templateUrl: './footer-text.component.html',
  styleUrls: ['./footer-text.component.css']
})
export class FooterTextComponent implements OnInit {
  @Input() _carType: string='';
  constructor(private _shared:SharedService,public ref:ChangeDetectorRef) { 
    
  }

  ngOnInit() {
   
    
    this._shared.currentcarDetail.subscribe((_res:any)=>{    
  //  console.log('foter',_res);
      
    })
  }

}
