<div *ngIf="isLoader" class="cg-loader">
    <div><img style="width: 24rem;" src="../../../assets/images/careager-loader.gif"></div>
</div>



<section class="offers-main">
    <div class="container-fluid">
        <div class="row">
            <div class="banner-offer">
                <owl-carousel-o [options]="offers">
                    <ng-template carouselSlide *ngFor="let img of newOfferImage">
                        <div class="items">
                            <img [src]="img" alt="">
                        </div>
                    </ng-template>

                </owl-carousel-o>
            </div>
        </div>
    </div>
    <div class="container-fluid offer-container">
        <div class="row">
            <div class="offer-type">
                <ul class="nav nav-tabs offers-tab">
                    <li class="active"><a data-toggle="tab" href="#home">Services</a></li>
                    <li><a id="test" data-toggle="tab" href="#menu1">Denting-painting</a></li>
                    <li><a data-toggle="tab" href="#menu2">Detailing</a></li>
                    <li><a data-toggle="tab" href="#menu3">Customization</a></li>

                </ul>

                <div class="tab-content">
                    <div id="home" class="tab-pane first in active">
                        <div class="col-sm-3 col-xs-6" (click)="goToDetail(item)"
                            *ngFor="let item of  offerArray.services" [routerLink]="['/offer-detail']">
                            <div class="offer-outer">
                                <img class="offer-image" src="{{item.file_address}}" alt="">

                            </div>
                        </div>

                    </div>
                    <div id="menu1" class="tab-pane fade">
                        <div class="col-sm-3 col-xs-6" (click)="goToDetail(item)"
                            *ngFor="let item of  offerArray.dentpaint" [routerLink]="['/offer-detail']">
                            <div class="offer-outer">
                                <img class="offer-image" src="{{item.file_address}}" alt="">

                            </div>
                        </div>
                    </div>
                    <div id="menu2" class="tab-pane fade">
                        <div class="col-sm-3 col-xs-6" (click)="goToDetail(item)"
                            *ngFor="let item of  offerArray.detailing" [routerLink]="['/offer-detail']">
                            <div class="offer-outer">
                                <img class="offer-image" src="{{item.file_address}}" alt="">

                            </div>
                        </div>
                    </div>
                    <div id="menu3" class="tab-pane fade">
                        <div class="col-sm-3 col-xs-6" (click)="goToDetail(item)"
                            *ngFor="let item of  offerArray.customization" [routerLink]="['/offer-detail']">
                            <div class="offer-outer">
                                <img class="offer-image" src="{{item.file_address}}" alt="">

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <app-download-app></app-download-app>

</section>