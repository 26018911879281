import { Security } from './../../@backend/security';
import { SharedService } from "./../../@service/shared.service";
import { ModalService } from "./../../@component/service/modalpopup/modal.service";
import { ApplicationService } from "src/app/@backend/application.service";
import { Component, DoCheck, OnInit, Inject, PLATFORM_ID } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorage,SessionStorage } from "ngx-store";
import { ToastConfig, Toaster, ToastType } from "ngx-toast-notifications";
import {CartServiceService} from '../../cart-service.service';
import { isPlatformBrowser } from '@angular/common'
import { HttpParams } from '@angular/common/http';
@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.css"],
})
export class PopupComponent implements OnInit, DoCheck {
  carData: any={};//secod modal data   , userid 
 // @SessionStorage('cartData') cartData: Array<any>;
 // @LocalStorage('selectedcar') selectedcar: any={};// conly selected car data
 // @LocalStorage('token') token: any;
  otpSent: boolean = false
  descriptionArray: any;
  popupForm: any;
  isOtp: boolean = false
  isSubmit: boolean = false;
  type = "";
  totalCost:any=0;
  userCars = []
  cartData = []
  selectedCar: any;
  carName = "verna"
  carList: any = [];
  car: any = {
    name: "",
    id: "",
    contact_no: "",
    registration: "",
    email: "",
    otp: "",
  };
  otpresponse: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public _security:Security,
    public _SharedService: SharedService,
    private toaster: Toaster,
    private _applicationservice: ApplicationService,
    private modalService: ModalService,
    @Inject(PLATFORM_ID) private platformId,
    public _router: Router,
    private cartService: CartServiceService
  ) {
    //this.showToast();
  }
  getcartTotal=()=>{
    if (this.cartData) {
      return this.cartData.reduce((sum, item) => sum + item.cost, 0);

    }
  }
  numberValidator(event) {
   
  }

  showToast(type,message,alert) {
   // const type = "success";
    this.toaster.open({
      text: message,
      caption: alert,
      type: type,
      position:'top-right',
      duration:6000
    });
  }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.selectedCar = JSON.parse(localStorage.getItem("userCar"))
    }
    //this.cartService.cartData = JSON.parse(localStorage.getItem("cartData"))
   // console.log("CartServiceData...", this.cartService.cartData)
   // this.cartData = this.cartService.cartData;
   
    this._applicationservice.getCartData()
    .subscribe(cartdata => {
      this.cartData = cartdata.cartData.services
      this.cartService.cartData = this.cartData
    })

    
    //this. setTab();
  }

  ngDoCheck () {
    this.cartData = this.cartService.cartData
  }


  sendOtp(contact_info: any) {
   
  }


  senOtp(car: any) {
    
    
    //this.showToast();
    let obj = {
      contact_no: car.contact_no,
      email: car.email,
    };
   

    this._applicationservice.sendOtp(obj).subscribe(
      (_res) => {
      
        if (_res.responseCode == 200) {
          this.otpSent = true
          this.otpresponse = _res;
          this.isOtp = true
          //this._SharedService.changeCarModel(this.car);
          //document.getElementById("closeprice").click();
         
          //document.getElementById("otpModal").click();
        }
      },
      (err) => {
        
      }
    );
  }

  resendOtp(car: any) {
    //this.showToast();
    let obj = {
      contact_no: car.contact_no,
      email: car.email,
    };
  

    this._applicationservice.sendOtp(obj).subscribe(
      (_res) => {
      
        if (_res.responseCode == 200) {
          this.otpresponse = _res;
          this._SharedService.changeCarModel(this.car);
        }
      },
      (err) => {
       
      }
    );
  }

  onselectCar = (data) => {

    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem("userCar", JSON.stringify(data))
    }
   
    this.selectedCar = data;
    this.carName = data.variant
    this.car.name = data.variant;
    this.car.id = data.id;
    this.carList.length = 0;
  };

  proceedToCar () {
    if (!this.car.registration) {
      document.getElementById("addCar").click()
      document.getElementById("selectCarClose").click()
    } else {
     
      document.getElementById("selectCarClose").click()

      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem("registration", this.car.registration)
      }


      this._applicationservice.registrationNumber = this.car.registration
      this._SharedService.changeCarModel(this.car);
      this._router.routeReuseStrategy.shouldReuseRoute = () => false;
      this._router.onSameUrlNavigation = 'reload';
      this._router.navigate(['/service']);
    }
 
  }


  
  checkPrice () {
    
      document.getElementById("addcarClose").click()

      if (isPlatformBrowser(PLATFORM_ID)) {
        localStorage.setItem("registration", this.car.registration)
      }


      this._applicationservice.registrationNumber = this.car.registration
      this._SharedService.changeCarModel(this.car);
      this._router.routeReuseStrategy.shouldReuseRoute = () => false;
      this._router.onSameUrlNavigation = 'reload';
      this._router.navigate(['/service']);
  }


  searchModel(event) {
   

    if (event.target.value.length >= 3) {
      this._applicationservice.searchModel(event.target.value).subscribe(
        (res: any) => {
          if (res.responseCode == 200) {
            this.carList = res.responseData;
            
          }
        },
        (err) => {
         
        }
      );
    }
  }


  
 

  carSelected = (car) => {
   
    if (!this.car.name) {
      
      this.carName = car.title
      this.car.name = car.title;
      this.car.id = car.variant;
      this.car.registration = car.registration_no
      this._applicationservice.registrationNumber = car.registration_no
      this.selectedCar = car

      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem("registration", car.registration_no)
        localStorage.setItem("userCar", JSON.stringify(car))
        }
  

    } else {
      if (this.car.name == car.title) {
        this.selectedCar = {}
        this.car.name = "",
        this.car.registration = ""
        this.car.id = ""
      } else {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem("registration", car.registration_no)
          localStorage.setItem("userCar", JSON.stringify(car))
          }
        this._applicationservice.registrationNumber = car.registration_no
        this.carName = car.title
        this.car.name = car.title;
        this.car.id = car.variant;
        this.car.registration = car.registration_no
        this.selectedCar = car

      }
    }
    
  }

  cleanformData = () => {
    this.car = {
      name: "",
      id: "",
      contact_no: "",
      email: "",
      otp: "",
    };
  };

  otpverify = (carModel: any) => {
    
    
   
    let obj = {
      // id: this.otpresponse.responseData.user,
      contact_no: this.otpresponse.responseData.contact_no,
      otp: carModel.otp,
    };

    this._applicationservice.otpVerification(obj).subscribe(
      (res: any) => {
     

        // Token error
        //this.token=res.responseData.token;
        this.carData={};
        this.carData = res;
      

        if (isPlatformBrowser(this.platformId)) {
        
          localStorage.setItem("user", JSON.stringify(res))
          localStorage.setItem("token", res.responseData.token)
          localStorage.setItem("carData", JSON.stringify(this.carData))
          }
        

        //this._SharedService.changeMessages(res);
        // window.location.reload();
        if (res.responseCode == 200) {
         // console.log("enter into getBookingcars")
          document.getElementById("selectCar").click()
          
          this._applicationservice.getBookingCars(obj.contact_no)
          .subscribe(res => {
          
            let unique = {}
            let cars = []
            for (let i=0; i<res.cars.length; i++) {
              unique[res.cars[i].title] = res.cars[i]
            }
            this.userCars = Object.values(unique)
           
            
          })
          //this.cleanformData();
         // document.getElementById("otpclose").click();
         // this._router.navigate(['/service']);
        }else{
          this.showToast('danger','Invalid otp!','Error')
        }
      },
      (err) => {
       // this.carData = err;
        //this._SharedService.changeMessages(err);
        this.showToast('danger','Invalid otp!','Error')
        // window.location.reload();
       
      }
    );


  
    let params = new HttpParams();
    params = params.append('newOrdNum','123');
    this.router.navigate([], { relativeTo: this.route, queryParams: params });
  };

  resenOtp = (carModel: any) => {
  
    let obj = {
      id: carModel.id,
      // "id":"5fbf482e4b13d07bde4e67f8"
    };
    this._applicationservice.resentOtp(obj).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
        
        } else {
        }
      },
      (err) => {
       
      }
    );
    /** Banao  */
  };

  removeCartData = (item) => {

    //let element = this.cartData[index]
    
    //this.cartData.splice(index, 1)
    //this.cartService.cartData = this.carData
    
    this._applicationservice.removeCartData(item._id)
    .subscribe(res => {
     
      this.cartData = res.cart.services
      this.cartService.cartData = this.cartData
      this.cartService.cartTotal = res.cart.total
      this.checkItemInCart(res.removedElement)
    })
    //this.cartData = this.cartService.cartData

    
    //localStorage.setItem("carts", JSON.stringify(this.cartData))
    //localStorage.setItem("cartData", JSON.stringify(this.cartService.cartData))
    //localStorage.setItem('cartData', this.carData)
  }

  checkItemInCart = (removedElement) => {
    
      this.cartService.serviceArray.forEach((ser: any) => {
        ser.services.forEach((elements) => {
          if (elements && removedElement) {
            if (elements.id == removedElement.id) {
           
              elements.isCart = false;
            }
          }
        });
      });


    this.cartService.serviceArray =  this.cartService.serviceArray
    //console.log(this.servicesArray);
  };

}
