import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { LocalStorage, SessionStorage } from 'ngx-store';

@Component({
  selector: 'app-thanku',
  templateUrl: './thanku.component.html',
  styleUrls: ['./thanku.component.css']
})
export class ThankuComponent implements OnInit {
  //@LocalStorage("carData") carData: any;
  //@LocalStorage("cartData") cartData: any;
  //@LocalStorage("selectedcar") selectedcar: any;
  //@LocalStorage("deviceid") deviceid: any;
  //@LocalStorage("devicetype") devicetype: any;

  constructor(private router: Router) { }

  ngOnInit() {
   // this.cartData = [];
   // this.selectedcar = {};
   // this.carData = {};
   this.loadScript();
  }
  backToHome(){
    this.router.navigate(['']);
  }
  loadScript() {
    let node = document.createElement('script'); // creates the script tag
    console.log('node', node)
    node.src = "../../../assets//js/thankyou.js"; // sets the source (insert url in between quotes)
    node.type = 'text/javascript'; // set the script type
    node.async = true; // makes script run asynchronously
    node.charset = 'utf-8';
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(node); 
  }
}
