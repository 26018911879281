import { Component, OnInit } from '@angular/core';
import { OwlOptions } from "ngx-owl-carousel-o";
@Component({
  selector: 'app-bmwcommon-slider',
  templateUrl: './bmwcommon-slider.component.html',
  styleUrls: ['./bmwcommon-slider.component.css']
})
export class BmwCommonSliderComponent implements OnInit {

  customOptions: OwlOptions = {
    center: true,
    items:2,
    loop:true,
    autoplay:true,
    // autoplayTimeout:2000,
    autoplayHoverPause:false,
    margin:10,
    responsive:{
        0:{
            items:1,
        },
        1025:{
            items:2
        }
    }
  }

  serviceOptions: OwlOptions = {
    items:3,
    loop:true,
    // autoplay:true,
    // autoplayTimeout:2000,
    nav:true,
    navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    autoplayHoverPause:false,
    margin:10,
    responsive:{
        0:{
            items:1,
        },
        1025:{
            items:3
        }
    }
  }
  feedback: OwlOptions = {
    loop:true,
    margin:10,
    nav:true,
    autoplay:true,
    navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    responsive:{
        0:{
            items:1
        },
        800:{
            items:2
        },
        1025:{
            items:3
        }
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
