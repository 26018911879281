<div class="home-faq home-container">
    <div class="heading-block topmargin center">
      <h3 class="home-heading wow fadeInUp" data-wow-duration="1s" >Frequently Asked Questions</h3>
    </div>
    <div class="faq-main">
      <div class="panel-group" id="accordion">
        <div class="panel">
          <div class="panel-heading">
            <h4 class="panel-title">
              <a data-toggle="collapse" data-parent="#accordion" href="#collapse1" class="accordion wow fadeInUp" data-wow-duration="1s" ><span>Why should you choose CarEager over other available options?</span></a>
            </h4>
          </div>
          <div id="collapse1" class="panel-collapse collapse">
            <div class="panel-body">CarEager has a state-of-the-art workshop situated in Gurgaon that is equipped with world-class equipment operated by trained professionals. 
              If anyone with comparable infrastructure and experience offers services at better pricing, we’ll do it for free!</div>
          </div>
        </div>
        <div class="panel">
          <div class="panel-heading">
            <h4 class="panel-title">
              <a data-toggle="collapse" data-parent="#accordion" href="#collapse2" class="accordion wow fadeInUp" data-wow-duration="1s" ><span>I won’t be able to visit the workshop. Can CarEager help me here?</span></a>
            </h4>
          </div>
          <div id="collapse2" class="panel-collapse collapse">
            <div class="panel-body">You don’t need to worry about the hassle to drop and pick your car from the workshop. 
              Our dedicated team will pick up your car from the desired location, get it serviced while keeping you updated about the advancements and drop it off back to you. </div>
          </div>
        </div>
        
      </div>
      </div>
      <div class="faq-main">
        <div class="panel-group" id="accordion1">
          <div class="panel">
            <div class="panel-heading">
              <h4 class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse3" class="accordion wow fadeInUp" data-wow-duration="1s" ><span>What kind of spare parts does CarEager use in services?</span></a>
              </h4>
            </div>
            <div id="collapse3" class="panel-collapse collapse">
              <div class="panel-body">At CarEager, we ensure that your car gets the best and only the best. So in our endeavour to make your car ownership experience a pleasurable one, we ensure that all the spare parts used in our services are 100% genuine OEM/OES spare parts.</div>
            </div>
          </div>
          <!-- <div class="panel">
            <div class="panel-heading">
              <h4 class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion1" href="#collapse4" class="accordion wow fadeInUp" data-wow-duration="1s" ><span>I will be able to drop off my car at the workshop but in the meanwhile, I have someplace to visit too. Can CarEager provide any assistance in this case?</span></a>
              </h4>
            </div>
            <div id="collapse4" class="panel-collapse collapse">
              <div class="panel-body">Want to take a look at where your car is getting serviced? Visit our workshop and take a look. 
                Got someplace to go? We’ll provide you with a Courtesy car which you can use for your commute while our experts take care of your car.</div>
            </div>
          </div> -->
          <!-- <div class="panel">
            <div class="panel-heading">
              <h4 class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion1" href="#collapse5" class="accordion wow fadeInUp" data-wow-duration="1s" ><span>Why should I buy or sell a car on CarEager?</span></a>
              </h4>
            </div>
            <div id="collapse5" class="panel-collapse collapse">
              <div class="panel-body">While there are plenty of second-hand car dealers, CarEager makes sure that your car ownership experience, be it the second-hand one, is as good as possible. Every car you buy with us goes through a thorough inspection. 
                You also get warranty, certification and free services with cars purchased from us.</div>
            </div>
          </div> -->
          <div class="panel">
            <div class="panel-heading">
              <h4 class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion1" href="#collapse6" class="accordion wow fadeInUp" data-wow-duration="1s" ><span>I want to take a look at how the services are carried on my car. Is it possible?</span></a>
              </h4>
            </div>
            <div id="collapse6" class="panel-collapse collapse">
              <div class="panel-body">CarEager believes in complete transparency. This is why from the beginning till the end, all our services are totally transparent and you have the full right to witness the magic being carried out. Do note that you will be allowed to take a look and interact with our experts from a safe 
                distance inside the workshop in order to ensure the safety of you as well as our staff.</div>
            </div>
          </div>
        </div>
        </div>
  </div>
