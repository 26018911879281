import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { BmwCommonSliderComponent } from '../@component/service/bmwservice/bmwcommon-slider/bmwcommon-slider.component';
import { RequestCallBackComponent } from '../@component/service/request-call-back/request-call-back.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { FaqComponent } from './faq/faq.component';
import { FeedbackComponent } from './feedback/feedback.component';
import {YouTubeComponent} from './you-tube/you-tube.component'
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { CallActionComponent } from './call-action/call-action.component';

@NgModule({
  declarations: [
    // ServiceSliderComponent,
    // FeedbackComponent,
    FaqComponent,
    DownloadAppComponent,
    RequestCallBackComponent,
    YouTubeComponent,
    CallActionComponent,
    
  ],
  imports: [
    NgxCaptchaModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgxYoutubePlayerModule.forRoot()
  ],
  exports: [
    // ServiceSliderComponent,
    // FeedbackComponent,
    FaqComponent,
    DownloadAppComponent,
    RequestCallBackComponent,
    YouTubeComponent,
    CallActionComponent
  ],
  entryComponents: [],
  bootstrap: [],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
