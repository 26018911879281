<div class="home-download">
    <div class="container-fluid service-container">
      <div class="heading-block center">
        <h3 class="home-heading wow fadeInUp" data-wow-duration="1s" >Download the  App now!</h3>
      </div>
      <div class="row">
        <div class="col-sm-6 app-image" style="text-align: right">
          <img class="wow zoomIn" data-wow-duration="1s" src="assets/images/app.webp" alt="" />
        </div>
        <form class="form-main col-sm-6"  id="appointment" #appointment="ngForm" (ngSubmit)="onSubmit(appointment);appointment.reset()">

          <div class="appImage-content">
            <p style="color: white" class="wow fadeInUp" data-wow-duration="1s"  >Get the download link via SMS</p>
            <!-- <div class="input-box wow fadeInUp" data-wow-duration="1s" data="0.8s">
              <div class="details">
                <span>+91</span>
                <input required [ngModel]="appointmentForm?.contact_no" name="contact_no" #contact_no="ngModel" 
                type="text"
                class="banner-input"
                id="contact_no"
                placeholder="Your Contact Number?"
              />
              </div>
              <button class="get-link">Get Link</button>
            </div> -->
            <div class="stores">
              <div class="googlePlay">
                <a class="wow fadeInUp" data-wow-duration="1s"
                  href="https://play.google.com/store/apps/details?id=com.careager.xpress"
                  target="_blank"
                  ><img src="assets/images/google-store.webp" alt=""
                /></a>
              </div>
              <!-- <div class="appleStore">
                <a
                  href="https://apps.apple.com/us/app/careager-xpress/id1445538212?ls=1"
                  target="_blank"
                  ><img src="assets/images/apple-store.webp" alt=""
                /></a>
              </div> -->
            </div>
            <h4 class="wow fadeInUp" data-wow-duration="1s" >Call Us Now!</h4>
            <h3 class="wow fadeInUp" data-wow-duration="1s">1800-843-4300</h3>
          </div>
        </form>
      </div>
    </div>
  </div>
