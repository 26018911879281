import { ModalModule } from './@component/service/modalpopup/modal.module';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { WebStorageModule, WebStorageService } from 'ngx-store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './@component/home/home.component';
import { ServiceComponent } from './@component/service/service.component';
import { HeaderAComponent } from './@shared/header-a/header-a.component';
import { FooterAComponent } from './@shared/footer-a/footer-a.component';
import { FeedbackComponent } from './@shared/feedback/feedback.component';
import { FaqComponent } from './@shared/faq/faq.component';
import { DownloadAppComponent } from './@shared/download-app/download-app.component';
import { FooterTextComponent } from './@shared/footer-text/footer-text.component';
import { ServiceTabsComponent } from './@shared/service-tabs/service-tabs.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, /* other http imports */ } from "@angular/common/http";
import { PopupComponent } from './@shared/popup/popup.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule, MatFormFieldModule } from '@angular/material';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastNotificationsModule } from "ngx-toast-notifications";
import { OurStoryComponent } from './@component/our-story/our-story.component';
import { OffersComponent } from './@component/offers/offers.component';
import { BookAppointmentComponent } from './@shared/book-appointment/book-appointment.component';
import { OfferDetailComponent } from './@component/offer-detail/offer-detail.component';
import { GalleryComponent } from './@component/gallery/gallery.component';
import { BmwserviceComponent } from './@component/service/bmwservice/bmwservice.component';
import { JaguarserviceComponent } from './@component/service/jaguarservice/jaguarservice.component';
import { PorscheserviceComponent } from './@component/service/porscheservice/porscheservice.component';
import { MercedesbenzserviceComponent } from './@component/service/mercedesbenzservice/mercedesbenzservice.component';
import { AudiserviceComponent } from './@component/service/audiservice/audiservice.component';
import { LandroverserviceComponent } from './@component/service/landroverservice/landroverservice.component';
import { VolvoserviceComponent } from './@component/service/volvoservice/volvoservice.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material'
import { Security } from './@backend/security';
import { ThankuComponent } from './@shared/thanku/thanku.component';
import { NotificationComponent } from './@component/notification/notification.component';
import { RequestSuccessComponent } from './@shared/book-appointment/request-success/request-success.component';
import { CarsContentComponent } from './@shared/cars-content/cars-content.component';
import { SuccessComponent } from './@shared/success/success.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { GtagModule } from 'angular-gtag';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgwWowModule } from 'ngx-wow';
import { SharedModule } from './@shared/shared.module';
import { ServiceSliderComponent } from './@component/service-slider/service-slider.component';
import { PrivacypolicyComponent } from './@component/privacypolicy/privacypolicy.component';
import { NotfoundComponent } from './@component/notfound/notfound.component';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServiceComponent,
    HeaderAComponent,
    FooterAComponent,
    
    ServiceSliderComponent,
    FeedbackComponent,
    // FaqComponent,
    // DownloadAppComponent,
    FooterTextComponent,
    ServiceTabsComponent,
    PopupComponent,

    // BmwserviceComponent,
    // JaguarserviceComponent, PorscheserviceComponent, MercedesbenzserviceComponent, AudiserviceComponent, LandroverserviceComponent, VolvoserviceComponent,
    OurStoryComponent,
    OffersComponent,
    BookAppointmentComponent,
    OfferDetailComponent,
    GalleryComponent,
    ThankuComponent,
    NotificationComponent,
    RequestSuccessComponent,
    CarsContentComponent,
    SuccessComponent,
    PrivacypolicyComponent,
    NotfoundComponent
  ],
  imports: [
    SharedModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    // DeviceDetectorModule.forRoot(),
    MatExpansionModule, ModalModule,

    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    MatDialogModule,
    MatExpansionModule,
    AppRoutingModule,
    GtagModule.forRoot({ trackingId: 'AW-623905788', trackPageviews: true }),
    FormsModule,
    HttpClientModule,
    CarouselModule,
    BrowserAnimationsModule,
    ToastNotificationsModule,
    MatFormFieldModule,
    MatInputModule,
    NgwWowModule,
    NgxYoutubePlayerModule.forRoot()
  ],
  entryComponents: [NotificationComponent, BookAppointmentComponent],
  providers: [
    Security,
    DeviceDetectorService,
    { provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
