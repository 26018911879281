import { ModalpopupComponent } from './modalpopup.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
    imports: [CommonModule],
    declarations: [ModalpopupComponent],
    exports: [ModalpopupComponent]
})
export class ModalModule { }