<!-- Book an Appointment -->
<div class="main-service">
<div class="get-in-touch" style="margin-top: 0px;">
    <div class="form-banner">
      <a target="_blank" href="https://wa.me/919560030702" style="text-align: center;
      display: block;
      padding-top: 0%;
      margin-bottom: 6%;" class="wow fadeInUp" *ngIf='hideWhatsApp' data-wow-duration="1s" data="1s"><img style="height: 62px;width: 186px;" class="playstore whats-app-button" src="assets/images/whatsapp-button.webp" alt="" /></a>

        <p class="form-heading wow fadeInUp" data-wow-duration="1s" data="0.5s">Your First Car Service is Free!</p>
        <form class="form-main wow zoomIn" data-wow-duration="1s" data="0.6s"  id="appointment" [formGroup]="appointmentForm" (ngSubmit)="onSubmit();appointmentForm.reset()">
          <div class="form-div">
            <input required="true" formControlName="name" name="name"
              type="text"
              class="banner-input banner-input-mobile"
              id="name"
              placeholder="What is Your Name?"
              style="background-color: #3b3a3a;"
            />
            <!-- <div *ngIf="isSubmit && name.errors" class="alert alert-danger">
              <div *ngIf="name.errors.required">Name is required.</div>
             
            </div> -->
          </div>
       
        
          <div class="form-div">
            <input required="true" pattern="\+?\d[\d -]{8,12}\d" maxlength="10" formControlName="contact_no" name="contact_no"
              type="text"
              class="banner-input banner-input-mobile"
              id="contact_no"
              placeholder="Your Contact Number?"
              style="background-color: #3b3a3a;"
            />
            <!-- <div *ngIf="isSubmit && contact_no.errors" class="alert alert-danger">
              <div *ngIf="contact_no.errors.required">Contact No. is required.</div>
             
            </div> -->
          </div>
          <div class="form-div">
            <textarea formControlName="message"
              class="banner-input input-box-mobile"
              name=""
              id=""
              rows="5"
              placeholder="Your Message (Optional)"
              style="background-color: #3b3a3a;"
            ></textarea>
          </div>
          <div class="form-div">
            <form [formGroup]="appointmentForm">
              <ngx-recaptcha2 #captchaElem
                [siteKey]="siteKey"
                (reset)="handleReset()"
                (load)="handleLoad()"
                (success)="handleSuccess($event)"
                [useGlobalDomain]="false"
                
                [theme]="theme"
                [type]="type"
                formControlName="recaptcha">
              </ngx-recaptcha2>
            </form>
          </div>
          <button style="    margin-top: 2%;" mat-dialog-close class="btn btn-submit" (click)="onSubmit()"
           [disabled]='appointmentForm.invalid'>Apply Now</button>
        </form>
      </div>
</div>
</div>