import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-you-tube',
  templateUrl: './you-tube.component.html',
  styleUrls: ['./you-tube.component.css']
})
export class YouTubeComponent implements OnInit {
  @Input('videos') videos = [];
  id1 = 'RWFvIqJ8isU';
  id2 = 'vF9T9IbdvW4';
  id3 = 'EXDI-MCOrKA';
  id4 = 'fC5uU7UpEQo';
  playerVars = {
    cc_lang_pref: 'en'
  };
  private ytEvent;
  version = '...';
  private player;
  initialCount: number = 1;
  constructor() { }

  onStateChange(event) {
    this.ytEvent = event.data;
  }
  savePlayer(player) {
    this.player = player;
  }

  playVideo() {
    this.player.playVideo();
  }

  pauseVideo() {
    this.player.pauseVideo();
  }

  ngOnInit(): void {
  }

}
