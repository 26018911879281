import { Component, HostListener, OnDestroy, Inject, PLATFORM_ID } from "@angular/core";
//import { LocalStorage, SessionStorage } from "ngx-store";
import { DeviceDetectorService } from "ngx-device-detector";
import { UUID } from "angular2-uuid";
import { isPlatformBrowser } from '@angular/common'
import { Meta, Title } from '@angular/platform-browser'


import { Router, NavigationEnd } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { Subscription } from "rxjs";
import { OnInit } from "@angular/core";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
 
})
export class AppComponent implements OnInit, OnDestroy{
  title = "CarEager";
  //@LocalStorage("carData") carData: any;
 // @LocalStorage("cartData") cartData: any;
  //@LocalStorage("selectedcar") selectedcar: any;
  //@LocalStorage("deviceid") deviceid: any;
  //@LocalStorage("devicetype") devicetype: any;
  deviceid: any;
  
  devicetype: any;

  private wowSubscription: Subscription;
  constructor(private dds: DeviceDetectorService,private router: Router,private wowService: NgwWowService,
    @Inject(PLATFORM_ID) private platformId,
    private meta: Meta) {
      this.router.events.lift(event => event instanceof NavigationEnd).subscribe(event => {
        // Reload WoW animations when done navigating to page,
        // but you are free to call it whenever/wherever you like
        this.wowService.init(); 
      });
    }
  ngOnInit() {
    this.wowSubscription = this.wowService.itemRevealed$.subscribe(
      (item:HTMLElement) => {
        // do whatever you want with revealed element
      });
 
    const uuid = UUID.UUID();
    
    if (isPlatformBrowser(this.platformId)) {
     // this.meta.addTag({name: "title", content: "My name is vinay"})
      if (!this.deviceid) {
        this.deviceid = uuid.trim();
        localStorage.setItem('deviceid', this.deviceid)
      }
      if (!this.devicetype) {
        this.devicetype = this.dds.getDeviceInfo().os.trim();
        localStorage.setItem('devicetype', this.devicetype)
      }
    }
    
  }
  // ngAfterViewInit(){
  //   this.wowService.init();
  // }
  ngOnDestroy() {
    // unsubscribe (if necessary) to WOW observable to prevent memory leaks
    this.wowSubscription.unsubscribe();
  }

}
