<section class="offers-main">
    <div class="container-fluid">
        <div class="row">
            <div class="banner-offer">
                <owl-carousel-o [options]="offers">
                    <ng-template carouselSlide>
                        <div class="items">
                            <img [src]="offerDetail.features_address" alt="">
                        </div>
                    </ng-template>   
                  </owl-carousel-o>
            </div>
        </div>
    </div>
    <div class="container-fluid offer-container">
        <div class="row">
            <div class="offer-type">
                <ul class="nav nav-tabs offers-tab">
                    <li class="active"><a data-toggle="tab" href="#home">Offer Details</a></li>
                    <li><a data-toggle="tab" href="#menu1">Terms & Conditions</a></li>
                  </ul>
                
                  <div class="tab-content">
                    <div id="home" class="tab-pane fade in active">
                        <div class="col-sm-5 ">
                            <div class="detail-img">
                                <img class="offer-image" src="{{offerDetail.file_address}}" alt="">
                            </div>
                        </div>
                        <div class="col-sm-7">
                            <div class="detail-points">
                                <div> <p> {{offerDetail.name}} </p> </div>
                                <div *ngIf="offerDetail.description"><p *ngFor="let item of offerDetail.description.split('-')"><i class="fa fa-check"  aria-hidden="true"></i>  {{item}}</p></div>
                                <!-- <p><i class="fa fa-check" aria-hidden="true"></i>  Watch all the shows as and when you want! No Limits!</p>
                                <p><i class="fa fa-check" aria-hidden="true"></i>  Watch all episodes of every show!</p>
                                <p><i class="fa fa-check" aria-hidden="true"></i>  Watch all the shows as and when you want! No Limits!</p>
                                <p><i class="fa fa-check" aria-hidden="true"></i>  Watch all episodes of every show!</p>
                                <p><i class="fa fa-check" aria-hidden="true"></i>  Watch all the shows as and when you want! No Limits!</p> -->
                            </div>
                            <a routerLink="/service" class="book-offer" >Book Now</a>
                        </div>
                    </div>
                    <div id="menu1" class="tab-pane fade">
                        <div class="detail-points">
                            <div *ngIf="offerDetail.terms"><p *ngFor="let item of offerDetail.terms.split(',')"><i class="fa fa-check" aria-hidden="true"></i> {{item}}</p></div>
                            <!-- <p><i class="fa fa-check" aria-hidden="true"></i>  Watch all the shows as and when you want! No Limits!</p>
                            <p><i class="fa fa-check" aria-hidden="true"></i>  Watch all episodes of every show!</p>
                            <p><i class="fa fa-check" aria-hidden="true"></i>  Watch all the shows as and when you want! No Limits!</p>
                            <p><i class="fa fa-check" aria-hidden="true"></i>  Watch all episodes of every show!</p>
                            <p><i class="fa fa-check" aria-hidden="true"></i>  Watch all the shows as and when you want! No Limits!</p> -->
                        </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
    <!-- <app-book-appointment></app-book-appointment> -->
    <app-download-app></app-download-app>
  <app-footer-text></app-footer-text>
</section>

