<div class="container">
  <div class="row youtube">
    <h1>
      <img style="    width: 45px;
      vertical-align: bottom;
      margin-right: 10px;" src="assets/images/youtube-icon.png" alt="" srcset="">
      YouTube</h1>
    <!-- <ng-container *ngIf="videos && videos.length>0">
      <div class="col-sm-4" *ngFor="let item of videos">
        <youtube-player [videoId]="item" (ready)="savePlayer($event)" (change)="onStateChange($event)"
          [playerVars]="playerVars">
        </youtube-player>
      </div>
    </ng-container>
    <ng-container *ngIf="!videos || videos.length===0"> -->

      <div class="col-sm-4">
        <youtube-player videoId="RWFvIqJ8isU" (ready)="savePlayer($event)" (change)="onStateChange($event)"
          [playerVars]="playerVars">
        </youtube-player>
      </div>
      <div class="col-sm-4">
        <youtube-player videoId="Wh7MrLmof54" (ready)="savePlayer($event)" (change)="onStateChange($event)"
          [playerVars]="playerVars">
        </youtube-player>
      </div>
      <div class="col-sm-4">
        <youtube-player videoId="u_yi4Gg6S6s" (ready)="savePlayer($event)" (change)="onStateChange($event)"
          [playerVars]="playerVars">
        </youtube-player>
      </div>
      <div class="col-sm-4">
        <youtube-player videoId="kNT4PIcQeD8" (ready)="savePlayer($event)" (change)="onStateChange($event)"
          [playerVars]="playerVars">
        </youtube-player>
      </div>
      <div class="col-sm-4">
        <youtube-player videoId="65_8Fxc2d1E" (ready)="savePlayer($event)" (change)="onStateChange($event)"
          [playerVars]="playerVars">
        </youtube-player>
      </div>
      <div class="col-sm-4">
        <youtube-player videoId="vHnSZjXlNhQ" (ready)="savePlayer($event)" (change)="onStateChange($event)"
          [playerVars]="playerVars">
        </youtube-player>
      </div>
    <!-- </ng-container> -->
  
  
  </div>
</div>