<div class="home-testimonial home-container">
    <div class="heading-block topmargin center">
      <h3 class="home-heading wow fadeInUp" data-wow-duration="1s" >
        What our customers say about us?
      </h3>
    </div>
    <div class="testimonial-carausal">
      <owl-carousel-o [options]="feedback">
        <ng-template carouselSlide>
          <div class="item">
            <div class="oc-item">
              <div class="testimonial">
                <div class="testi-content">
                  <div class="text-center mb-4">
                    <img class="wow zoomIn" data-wow-duration="1s"  src="assets/images/quotes-icon.webp" style="width: 38px; margin: auto"/>
                  </div>
                  <h4 class="testi-title mb-2 wow fadeInUp" data-wow-duration="1s" >best staff that I have ever come across</h4>
                  <p class="testi-comment wow fadeInUp" data-wow-duration="1s" >
                    I would really recommend everyone to come here and get everything done to your car from them. They have the best staff that I have ever come across till now. They value your time and take care of your beauty just the way you would want them to.
                  </p>
                  <div class="spost clearfix">
                    <div class="entry-image">
                      <a target="_blank" href="https://goo.gl/maps/7SsNVhWnJ2BZm8rW9"><img class="wow zoomIn" data-wow-duration="1s" 
                          src="assets/images/google-icon.webp"
                          alt="Google Review"
                      /></a>
                    </div>
                    <div class="entry-c">
                      <div class="entry-title">
                        <h4 class="wow fadeInUp" data-wow-duration="1s" >Rahul Dev 
                        </h4>
                      </div>
                      <ul class="entry-meta m-0">
                        <li class="wow fadeInUp" data-wow-duration="1s" >via Google Reviews</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>  
        <ng-template carouselSlide>
          <div class="item">
            <div class="oc-item">
              <div class="testimonial">
                <div class="testi-content">
                  <div class="text-center mb-4">
                    <img class="wow zoomIn" data-wow-duration="1s" 
                      src="assets/images/quotes-icon.webp"
                      style="width: 38px; margin: auto"
                    />
                  </div>
                  <h4 class="testi-title mb-2 wow fadeInUp" data-wow-duration="1s" >the best service and repair in Gurgaon for my both cars</h4>
                  <p class="testi-comment wow fadeInUp" data-wow-duration="1s" >
                    Firstever i got the best service and repair in Gurgaon
                     for my both cars. they have professional machenics and 
                     engineers who explain you the needed guidence for important
                      repaire and value added services if u require. Customer Relationship Manager,
                       Vanshita helped me meet and explain my issues to thier engineers
                     a My exp was amazing with them and i strongly recommend them.  
                  </p>
                  <div class="spost clearfix">
                    <div class="entry-image">
                      <a target="_blank" href="https://goo.gl/maps/CnF6RzK1XUwbLvSJ9"
                        ><img class="wow zoomIn" data-wow-duration="1s" 
                          src="assets/images/google-icon.webp"
                          alt="Google Review"
                      /></a>
                    </div>
                    <div class="entry-c">
                      <div class="entry-title">
                        <h4 class="wow fadeInUp" data-wow-duration="1s" >Bijai Dwivedi
                        </h4>
                      </div>
                      <ul class="entry-meta m-0">
                        <li class="wow fadeInUp" data-wow-duration="1s" >via Google Reviews</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template> 
        <ng-template carouselSlide>
          <div class="item">
            <div class="oc-item">
              <div class="testimonial">
                <div class="testi-content">
                  <div class="text-center mb-4">
                    <img class="wow zoomIn" data-wow-duration="1s" 
                      src="assets/images/quotes-icon.webp"
                      style="width: 38px; margin: auto"
                    />
                  </div>
                  <h4 class="testi-title mb-2 wow fadeInUp" data-wow-duration="1s" >timely delivered my car with complete satisfaction</h4>
                  <p class="testi-comment wow fadeInUp" data-wow-duration="1s" >
                    I hv visited for the first time to CarEager for my Honda
                     City car service n repair works. I was never expected that
                      they would done better work than dealer does that also in a
                       such a attractive price tag. Team working @
                        CarEager are professionally trained n well behaved and very fast n timely delivered my
                     car with complete satisfaction. I would like to recommend to visit at least once.  
                  </p>
                  <div class="spost clearfix">
                    <div class="entry-image">
                      <a target="_blank" href="https://goo.gl/maps/8i5b6QuKCZ4mHb8t8"
                        ><img class="wow zoomIn" data-wow-duration="1s" 
                          src="assets/images/google-icon.webp"
                          alt="Google Review"
                      /></a>
                    </div>
                    <div class="entry-c">
                      <div class="entry-title">
                        <h4 class="wow fadeInUp" data-wow-duration="1s" >Anjan Meher
                        </h4>
                      </div>
                      <ul class="entry-meta m-0">
                        <li class="wow fadeInUp" data-wow-duration="1s" >via Google Reviews</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template> 
        <ng-template carouselSlide>
          <div class="item">
            <div class="oc-item">
              <div class="testimonial">
                <div class="testi-content">
                  <div class="text-center mb-4">
                    <img class="wow zoomIn" data-wow-duration="1s" 
                      src="assets/images/quotes-icon.webp"
                      style="width: 38px; margin: auto"
                    />
                  </div>
                  <h4 class="testi-title mb-2 wow fadeInUp" data-wow-duration="1s" >They explained the scope of job very wisely along with estimation</h4>
                  <p class="testi-comment wow fadeInUp" data-wow-duration="1s" >
                    I was very disaapointed by my last service station as
                     they have charged me too much and my AC was not working
                      properly. Somebody suggested me CarEager Xpress for my 
                      car. CarEager xpress is very professional and affordable.
                       They explained the scope of job very wisely along with estimation.
                        My AC is working properly now. 
                    I recommend CarEager Xpress Gurugram to everyone who loves their car.
                  </p>
                  <div class="spost clearfix">
                    <div class="entry-image">
                      <a target="_blank" href="https://goo.gl/maps/aoLr7dx2Mtra449X8"
                        ><img class="wow zoomIn" data-wow-duration="1s" 
                          src="assets/images/google-icon.webp"
                          alt="Google Review"
                      /></a>
                    </div>
                    <div class="entry-c">
                      <div class="entry-title">
                        <h4 class="wow fadeInUp" data-wow-duration="1s" >Prakash Jha
                        </h4>
                      </div>
                      <ul class="entry-meta m-0">
                        <li class="wow fadeInUp" data-wow-duration="1s" >via Google Reviews</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template> 
        <ng-template carouselSlide>
          <div class="item">
            <div class="oc-item">
              <div class="testimonial">
                <div class="testi-content">
                  <div class="text-center mb-4">
                    <img class="wow zoomIn" data-wow-duration="1s" 
                      src="assets/images/quotes-icon.webp"
                      style="width: 38px; margin: auto"
                    />
                  </div>
                  <h4 class="testi-title mb-2 wow fadeInUp" data-wow-duration="1s" >the center has all modern equipments that any elite car requires</h4>
                  <p class="testi-comment wow fadeInUp" data-wow-duration="1s" >
                    Fabulous place for getting your car fully checked.
                     Also very friendly service provider who helped
                      me to cope up with all the issues related to my car.
                       The center is also very hip and happening. Infact the
                        center has all modern equipments that any elite car requires.
                     I am very happy as it satiated all the needs and requirements of my car. 
                  </p>
                  <div class="spost clearfix">
                    <div class="entry-image">
                      <a target="_blank" href="http://bit.ly/3gTlRGh"
                        ><img class="wow zoomIn" data-wow-duration="1s" 
                          src="assets/images/ps.webp"
                          alt="Play Store"
                      /></a>
                    </div>
                    <div class="entry-c">
                      <div class="entry-title">
                        <h4 class="wow zoomIn" data-wow-duration="1s" >Nebanita Chatterjee
                        </h4>
                      </div>
                      <ul class="entry-meta m-0">
                        <li class="wow fadeInUp" data-wow-duration="1s" >via Play Store</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template> 
        <ng-template carouselSlide>
          <div class="item">
            <div class="oc-item">
              <div class="testimonial">
                <div class="testi-content">
                  <div class="text-center mb-4">
                    <img class="wow zoomIn" data-wow-duration="1s" 
                      src="assets/images/quotes-icon.webp"
                      style="width: 38px; margin: auto"
                    />
                  </div>
                  <h4 class="testi-title mb-2 wow fadeInUp" data-wow-duration="1s" >they are here to change the game</h4>
                  <p class="testi-comment wow fadeInUp" data-wow-duration="1s" >
                    Very good place for car lovers.. I saw the ad on instagram and facts were too good to be true... but this dealership really exceeds the expectations... they are here to change the game... car dealers really cheat customers.. but now customers have an option.. 
                  </p>
                  <div class="spost clearfix">
                    <div class="entry-image">
                      <a target="_blank" href="http://bit.ly/3gTlRGh"
                        ><img class="wow zoomIn" data-wow-duration="1s" 
                          src="assets/images/ps.webp"
                          alt="Play Store"
                      /></a>
                    </div>
                    <div class="entry-c">
                      <div class="entry-title">
                        <h4 class="wow zoomIn" data-wow-duration="1s" >Sakib Khan
                        </h4>
                      </div>
                      <ul class="entry-meta m-0">
                        <li class="wow fadeInUp" data-wow-duration="1s" >via Play Store</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template> 

      <ng-template carouselSlide>
        <div class="item">
          <div class="oc-item">
            <div class="testimonial">
              <div class="testi-content">
                <div class="text-center mb-4">
                  <img
                    src="assets/images/quotes-icon.webp"
                    style="width: 38px; margin: auto"
                  />
                </div>
                <h4 class="testi-title mb-2">detailing and collision services available at reasonable prices</h4>
                <p class="testi-comment">
                  Most advanced premium car ecosystem in Gurgaon with all the advanced tools and equipments..All the major detailing and collision services available at reasonable prices by the Gurugram's best Washerman and painters ,denters.  
                </p>
                <div class="spost clearfix">
                  <div class="entry-image">
                    <a target="_blank" href="https://jsdl.in/JDS?postid=1584335846198435"
                      ><img
                      src="assets/images/jd.webp"
                        alt="JustDial"
                    /></a>
                  </div>
                  <div class="entry-c">
                    <div class="entry-title">
                      <h4>Anonymous</h4>
                    </div>
                    <ul class="entry-meta m-0">
                      <li>Via JustDial</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>   
    </owl-carousel-o>
  </div>
</div>
