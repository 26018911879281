<div class="form-banner">
    <h2 class="form-heading wow fadeInUp" data-wow-duration="1s" data="0.5s" style="margin-top: 0; font-weight: bold;" [innerHTML]="currentTitle"></h2>
    <form class="form-main"  id="appointment" [formGroup]="appointmentForm"
     (ngSubmit)="onSubmit();appointmentForm.reset()">
     
      <div class="form-div">
        <input required formControlName="name" name="name"
          type="text"
          class="banner-input"
          id="name"
          placeholder="Name *"
        />                
        <div *ngIf="appointmentForm.get('name').touched && appointmentForm.get('name').invalid && appointmentForm.get('name').errors?.required" style="color: #a94442;margin-bottom: 7px; padding-left: 10px;">
          Name is required.
        </div>
      </div>      
      <div class="form-div">
        <input
        required pattern="\+?\d[\d -]{8,12}\d" maxlength="10" formControlName="contact_no" name="contact_no" 
          type="text"
          class="banner-input"
          id="contact_no"
          placeholder="Contact Number *"
        />
         <div *ngIf="appointmentForm.get('contact_no').touched && appointmentForm.get('contact_no').invalid" style="color: #a94442;margin-bottom: 7px; padding-left: 10px;">
          Contact No. is required.
        </div>
      </div>
      <div class="form-div">
        <textarea formControlName="message"
          class="banner-input"
          name=""
          id=""
          rows="5"
          placeholder="Message (Optional)"
        ></textarea>

      </div>
      <!-- <div class="form-div">
        <form [formGroup]="appointmentForm">
          <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (load)="handleLoad()"
            (success)="handleSuccess($event)" [useGlobalDomain]="false" [theme]="theme" [type]="type"
            formControlName="recaptcha">
          </ngx-recaptcha2>
        </form>

      </div> -->
      <!-- <div class="form-div">
        <form [formGroup]="appointmentForm">  
          <ngx-recaptcha2 #captchaElem
            [siteKey]="siteKey"
            (reset)="handleReset()"
            (load)="handleLoad()"
            (success)="handleSuccess($event)"
            [useGlobalDomain]="false"                    
            [theme]="theme"
            [type]="type"
            formControlName="recaptcha">
          </ngx-recaptcha2>
        </form>

      </div> -->

      <div class="d-flex">
        <button  style="margin-top: 2%; background-color: red;" class="btn btn-submit mx-auto cursor-pointer" (click)="onSubmit()" [disabled]="appointmentForm.invalid">Get Offer</button>
      </div>
    </form>
  </div>
  